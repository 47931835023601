export interface LegalEngineResponseItem {
  readonly inCompliance?: boolean;
  readonly hasCashPriceCheckFailed?: boolean;
  readonly cashPriceWithinLegalLimit?: boolean;
  readonly totalCostWithinLegalLimit: boolean;
  readonly termOrCashPriceAdjusted?: boolean;
  readonly storeNum: string;
  readonly weeklyRent: number;
  readonly weeklyTerm: number;
  readonly cashPrice?: number;
  readonly totalCost?: number;
  readonly adjustedTerm?: number;
  readonly totalCostLimit?: number;
  readonly cashPriceLimit?: number;
}

export const isIllegalItem = (item: LegalEngineResponseItem) =>
  !item.totalCostWithinLegalLimit && item.adjustedTerm !== item.weeklyTerm;

export const hasIllegalResponseItem = (
  legalEngineResponse: LegalEngineResponseItem[]
): boolean => legalEngineResponse.some(isIllegalItem);

export const toIllegalResponseList = (
  legalEngineResponse: LegalEngineResponseItem[]
): LegalEngineResponseItem[] => legalEngineResponse.filter(isIllegalItem);
