export enum ClassificationType {
  SET_INITIAL_DATA = 'setInitialData',
  SET_STARTS_ELECT = 'setstartselect',
  SET_DEPARTMENT = 'setDepartment',
  SET_DEPARTMENTS = 'setDepartments',
  SET_SINGLE_DEPARTMENT = 'setSingleDepartment',
  CLEAR_DEPARTMENTS = 'clearDepartments',
  SET_SUBDEPARTMENTS_FROM_DEPARTMENT = 'setSubDepartmentsFromDepartment',
  SET_SINGLE_SUBDEPARTMENT = 'setSingleSubDepartment',
  CLEAR_SUBDEPARTMENTS = 'clearSubDepartments',
  SET_SUBDEPARTMENTS = 'setSubDepartments',
  SET_SINGLE_BRACKET = 'setSingleBracket',
  SET_BRACKETS = 'setBrackets',
  SET_BRACKET_END = 'setbracketend',
  CLEAR_BRACKETS = 'clearBrackets',
  SET_TYPES_AS_PARENT = 'setTypesAsParent',
  SET_SINGLE_TYPE = 'setSingleType',
  CLEAR_TYPES = 'clearTypes',
  SET_TYPES = 'setTypes',
  SET_SINGLE_SUBTYPE = 'setSingleSubType',
  CLEAR_SUBTYPES = 'clearSubTypes',
  SET_SUBTYPES = 'setSubTypes'
}
