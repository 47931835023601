import { LegalEngineRequest } from '../domain/Legal/LegalEngineRequest';
import { LegalEngineResponseItem } from '../domain/Legal/LegalEngineResponseItem';
import { client, mapError } from './client';

export const checkLegalLimits = (
  request: LegalEngineRequest
): Promise<LegalEngineResponseItem[]> =>
  client('legal-engine/validate/purchase-order', {
    method: 'POST',
    body: request
  }).catch(mapError);
