import React, { RefObject, useCallback, useEffect, useState } from 'react';
import AutoNumericPlugin from 'autonumeric';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';

import { GenericObject } from '../../../utils/GenericObject';

import styles from './AutoNumeric.module.scss';

export interface AutonumericOptions {
  minimumValue?: number;
  maximumValue?: number;
  decimalPlaces?: number;
  currencySymbol?: string;
  currencySymbolPlacement?: string;
  restrictZeroFirstChar?: boolean;
}

export enum InputSize {
  medium = 'medium',
  small = 'small'
}

interface Props {
  name: string;
  value?: string;
  defaultValue?: string;
  imperativeMode?: boolean;
  imperativeValue?: string;
  placeholder?: string;
  options?: AutonumericOptions;
  disabled?: boolean;
  isLowVisible?: boolean;
  inputSize?: InputSize;
  ariaLabelledby?: string;
  hasError?: boolean;
  onChange?: (value: string) => void;
  changeOnBlureOnly?: boolean;
}

export const AutoNumeric = (props: Props) => {
  const {
    name,
    value,
    defaultValue,
    imperativeMode,
    imperativeValue,
    placeholder,
    options = {},
    disabled,
    isLowVisible,
    inputSize = InputSize.medium,
    ariaLabelledby,
    hasError,
    onChange,
    changeOnBlureOnly = false
  } = props;

  const [autoNumericOptions] = useState<AutonumericOptions>(
    cloneDeep({
      onInvalidPaste: 'ignore',
      modifyValueOnWheel: false,
      ...options
    })
  );
  const [autoNumericRef] = useState<RefObject<HTMLInputElement>>(
    React.createRef<HTMLInputElement>()
  );
  const [autoNumericInstance, setAutoNumericInstance] = useState<
    GenericObject | undefined
  >();

  const handleChange = useCallback(() => {
    if (!autoNumericInstance) {
      return;
    }

    if (onChange && !changeOnBlureOnly) {
      onChange(autoNumericInstance.get());
    }
  }, [autoNumericInstance, onChange, changeOnBlureOnly]);

  const handleBlur = () => {
    if (!autoNumericInstance) {
      return;
    }

    if (onChange && changeOnBlureOnly) {
      onChange(autoNumericInstance.get());
    }
  };

  const handleKeyDown = useCallback(
    (event: GenericObject) => {
      if (!autoNumericInstance) {
        return;
      }

      // workaround to fix autonumeric cmd+x issue
      // https://github.com/autoNumeric/autoNumeric/issues/576
      if (event.key === 'x' || event.ctrlKey) {
        autoNumericInstance.set('');

        if (onChange) {
          onChange('');
        }
      }
    },
    [autoNumericInstance, onChange]
  );

  useEffect(() => {
    const instance = new AutoNumericPlugin(
      autoNumericRef.current,
      autoNumericOptions
    );

    setAutoNumericInstance(instance);

    return () => {
      instance.remove();
    };
  }, [autoNumericRef, autoNumericOptions]);

  useEffect(() => {
    if (
      imperativeMode &&
      autoNumericInstance &&
      autoNumericInstance.get() !== imperativeValue
    ) {
      autoNumericInstance.set(imperativeValue);

      handleChange();
    }
  }, [imperativeMode, imperativeValue, autoNumericInstance, handleChange]);

  const rawValue = autoNumericInstance && autoNumericInstance.rawValue;

  useEffect(() => {
    if (autoNumericInstance && autoNumericOptions.restrictZeroFirstChar) {
      autoNumericInstance.settings.minimumValue = '0';
      if (rawValue === '0' || rawValue === '') {
        autoNumericInstance.set('');
        autoNumericInstance.settings.minimumValue = '1';
      }
    }
  }, [autoNumericInstance, autoNumericOptions.restrictZeroFirstChar, rawValue]);

  return (
    <input
      type="text"
      defaultValue={value || defaultValue}
      name={name}
      ref={autoNumericRef}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      className={clsx({
        [styles.inputControlItem]: true,
        [styles.error]: hasError,
        [styles.disabled]: disabled,
        [styles.small]: inputSize === InputSize.small,
        [styles.opacity02]: isLowVisible
      })}
      pattern="[0-9]*"
      inputMode="decimal"
      autoComplete="off"
      aria-labelledby={ariaLabelledby}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
