import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import {
  getSubmittedPosAction,
  getSubmittedPosErrorAction,
  getSubmittedPosSuccessAction,
  SubmittedPosAction
} from './submitted-pos-actions';
import { PoStatus } from '../../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';
import { submittedPOCancelMessage } from '../../../components/screens/submitted-purchase-orders/submitted-pos-list/SubmittedPOsList';
import * as purchaseOrderClient from '../../../api/purchase-orders';
import { Store } from '../../../domain/User/Store';

export const getSubmittedPurchaseOrders = (
  dispatch: Dispatch<SubmittedPosAction>
) => async (poStoreNums?: Store[], token?: CancelToken): Promise<void> => {
  try {
    dispatch(getSubmittedPosAction());

    poStoreNums = poStoreNums || [];
    const poStoreNumsStrings = poStoreNums.map(store => store.storeNum);

    const purchaseOrders: PurchaseOrder[] = await purchaseOrderClient.getPurchaseOrdersByStatus(
      PoStatus.SUBMITTED,
      poStoreNumsStrings,
      token
    );

    dispatch(getSubmittedPosSuccessAction(purchaseOrders));
  } catch (e) {
    if (e.message !== submittedPOCancelMessage) {
      dispatch(getSubmittedPosErrorAction());
    }
  }
};
