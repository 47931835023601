import React from 'react';
import clsx from 'clsx';

import { PoSubmitSuccessMessage } from './PoSubmitSuccessMessage';

import styles from './PoSubmitSuccess.module.scss';

export const poSubmitSuccessTestId = 'PoSubmitSuccessComponent';

export const PoSubmitSuccess = ({ poId }: PoSubmitSuccessMessage) => (
  <div data-testid={poSubmitSuccessTestId}>
    <p className={clsx(styles.headline, styles.text)}>
      PO #{poId} has been submitted successfully.
    </p>
    <p className={styles.text}>Go to SIMS to receive it.</p>
  </div>
);
