import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import { FormCard } from './purchase-order-form/form-card/FormCard';
import { FormCardTitle } from './purchase-order-form/form-card/FormCardTitle';
import { FormCardContent } from './purchase-order-form/form-card/FormCardContent';

import styles from './purchase-order-form/PurchaseOrderForm.module.scss';
import formStyles from './purchase-order-form/PurchaseOrderForm.module.scss';

export const PurchaseOrderFormSkeleton = () => {
  return (
    <div
      data-testid="purchaseOrderFormSkeletonComponent"
      className={styles.purchaseOrderPage}
    >
      <div className={styles.formContainer}>
        <div className={styles.formPurchaseOrder}>
          <div className={styles.formPurchaseOrderContent}>
            <div className={styles.card}>
              <div className={styles.cardTitleRounded}>
                <span className={styles.headerContainer}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={24}
                    width={230}
                  />
                </span>
              </div>
              <div className={styles.formCardsWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} xl={3}>
                    <FormCard>
                      <FormCardTitle>
                        <Hidden mdDown>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={24}
                          />
                        </Hidden>
                      </FormCardTitle>
                      <FormCardContent>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <Box mb="0.5rem">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb="0.5rem">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={120}
                          />
                          <Skeleton
                            animation="wave"
                            variant="text"
                            height={14}
                          />
                        </div>
                      </FormCardContent>
                    </FormCard>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <FormCard>
                      <FormCardTitle>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={24}
                        />
                      </FormCardTitle>
                      <FormCardContent>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                      </FormCardContent>
                    </FormCard>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <FormCard>
                      <FormCardTitle>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          height={24}
                          width={230}
                        />
                      </FormCardTitle>
                      <FormCardContent>
                        <Box className={formStyles.formBlock}>
                          <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '8px' }}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={30}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                                width={70}
                              />
                            </div>
                            <div style={{ flexGrow: 1, marginRight: '8px' }}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={30}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </div>
                            <div>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={30}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                                width={40}
                              />
                            </div>
                          </div>
                        </Box>
                        <Box className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </Box>
                        <Box className={formStyles.formBlock}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </Box>
                      </FormCardContent>
                    </FormCard>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <FormCard>
                      <FormCardTitle>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          height={24}
                          width={230}
                        />
                      </FormCardTitle>
                      <FormCardContent>
                        <Box mb="0.5rem">
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <div className={formStyles.formBlock}>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={50}
                            height={14}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={40}
                          />
                        </div>
                        <Box mb="0.5rem">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb="0.5rem">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb="0.5rem">
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Skeleton
                                animation="wave"
                                variant="text"
                                width={50}
                                height={14}
                              />
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                height={40}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </FormCardContent>
                    </FormCard>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className={styles.formPurchaseOrderFooter}>
            <div className={clsx([styles.actionsContainer])}>
              <div className={styles.intermediateActions}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={42}
                  width={230}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
