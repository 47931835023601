import React from 'react';

import styles from './PurchaseOrderEmptyCard.module.scss';

export const emptyCardTestId = 'purchaseOrderEmptyCardComponent';

export const PurchaseOrderEmptyCard = (props: {
  cardEmptyContent: string;
  initialMessage: string;
}) => {
  const { cardEmptyContent, initialMessage } = props;
  return (
    <div data-testid={emptyCardTestId} className={styles.cardOrderEmptyWrapper}>
      <div className={styles.cardOrderEmpty}>
        <hr />
        <div className={styles.cardOrderContent}>
          <h3>{cardEmptyContent}</h3>
        </div>
      </div>
      <p className={styles.initialMessage}>{initialMessage}</p>
    </div>
  );
};
