import React, { useMemo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ArrayField, useFormContext } from 'react-hook-form';

import {
  FormAllocation,
  getNumberOfAllocationsWithTheSameState
} from '../../allocations/allocations-list/FormAllocation';
import { AutoPricing } from '../AutoPricing';
import { WeeklyRent } from '../WeeklyRent';
import { WeeklyTerm } from '../WeeklyTerm';
import { TotalRtoPrice } from '../TotalRtoPrice';
import { BiWeeklyRent } from '../BiWeeklyRent';
import { BiWeeklyTerm } from '../BiWeeklyTerm';
import { SemiMonthlyRent } from '../SemiMonthlyRent';
import { SemiMonthlyTerm } from '../SemiMonthlyTerm';
import { MonthlyRent } from '../MonthlyRent';
import { MonthlyTerm } from '../MonthlyTerm';
import { statesOfUS } from '../../../../../../config/statesOfUS';
import { State } from '../../../../../../domain/User/State';
import { ExpansionPanel } from '../../../../../common/ExpansionPanel';
import { FormPricing } from './FormPricing';
import { PurchaseOrderFormValues } from '../../PurchaseOrderFormValues';

import formStyles from '../../PurchaseOrderForm.module.scss';

export const pricingItemTestId = 'pricingItemComponent';

const checkNewStateAdded = (
  allocations: FormAllocation[],
  state: string
): boolean => {
  let isChanged = false;
  allocations.forEach((a: FormAllocation) => {
    if (a.state === state) {
      isChanged = true;
    } else {
      isChanged = false;
    }
  });
  return isChanged;
};

export interface CommonProps {
  index: number;
  field: Partial<ArrayField<FormPricing>>;
  purchaseOrder: PurchaseOrderFormValues | null;
  state: string;
}

export interface PricingProps extends CommonProps {
  allocations: FormAllocation[];
  expanded: string | boolean;
  openStorePanel: (
    isChanged: boolean,
    index: number,
    isStateAdded: boolean
  ) => void;
  handleChange: (
    panel: string
  ) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => void;
}

export const PricingItem = (props: PricingProps) => {
  const {
    index,
    field,
    purchaseOrder,
    allocations,
    expanded,
    handleChange,
    openStorePanel
  } = props;
  const [isChanged, setIsChanged] = useState(false);

  const { register } = useFormContext();

  const foundState: State =
    statesOfUS.find((s: State) => s.abbreviation === field.state) ||
    ({} as State);
  const isStateAdded =
    !!(field.state && allocations.length) &&
    checkNewStateAdded(allocations, field.state);

  const allocationsNumber: number =
    field.state && allocations.length
      ? getNumberOfAllocationsWithTheSameState(allocations, field.state)
      : 0;

  const title =
    allocationsNumber > 0
      ? `${foundState?.name} (${allocationsNumber})`
      : `${foundState?.name}`;

  useEffect(() => {
    setIsChanged(true);
  }, [foundState]);

  useEffect(() => {
    openStorePanel(isChanged, index, isStateAdded);
  }, [openStorePanel, isChanged, index, isStateAdded]);

  return useMemo(
    () => (
      <ExpansionPanel
        data-testid={pricingItemTestId}
        index={index}
        title={title}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <Box mb="0.5rem">
          <AutoPricing
            index={index}
            field={field}
            allocations={allocations}
            state={foundState?.abbreviation}
          />
        </Box>
        <Box mb="0.5rem">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className={formStyles.iconField}>
                <WeeklyRent
                  index={index}
                  field={field}
                  purchaseOrder={purchaseOrder}
                  state={foundState?.abbreviation}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <WeeklyTerm
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
          </Grid>
        </Box>
        <div className={formStyles.formBlock}>
          <TotalRtoPrice
            index={index}
            field={field}
            purchaseOrder={purchaseOrder}
            state={foundState?.abbreviation}
          />
        </div>
        <Box mb="0.5rem">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <BiWeeklyRent
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>

            <Grid item xs={6}>
              <BiWeeklyTerm
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb="0.5rem">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SemiMonthlyRent
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
            <Grid item xs={6}>
              <SemiMonthlyTerm
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb="0.5rem">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MonthlyRent
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
            <Grid item xs={6}>
              <MonthlyTerm
                index={index}
                field={field}
                purchaseOrder={purchaseOrder}
                state={foundState?.abbreviation}
              />
            </Grid>
          </Grid>
        </Box>

        <input
          type="hidden"
          name={`pricing[${index}].state`}
          ref={register({ required: true })}
          defaultValue={field.state}
        />
      </ExpansionPanel>
    ),
    [
      index,
      title,
      expanded,
      handleChange,
      field,
      allocations,
      foundState,
      purchaseOrder,
      register
    ]
  );
};
