import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Drawer, useMediaQuery } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Button } from '../../common/Button';
import { Text } from './Text';
import { HeaderText } from './texts/HeaderText';
import { StaticText } from './texts/StaticContent';
import { MenuListText } from './texts/MenuListText';

import styles from './HelpText.module.scss';

export const helpTextTestId = 'helpTextComponent';
export const drawerOpenTestId = 'drawerOpenComponent';

enum Position {
  Left = 'left',
  Right = 'right'
}

interface Props {
  helpTitle: string;
  helpOpen?: boolean;
  handleHelpDrawerOpen?: any;
  handleHelpDrawerClose?: any;
}

const useStyles = makeStyles((theme: Theme) => {
  const { typography, breakpoints } = theme;

  return createStyles({
    paper: {
      ...(window.isRenderedByContainer && {
        marginLeft: typography.pxToRem(50)
      }),
      paddingTop: typography.pxToRem(90),
      boxShadow:
        '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
      zIndex: 25,
      [breakpoints.up(breakpoints.values.lg)]: { width: '35%' }
    }
  });
});

export const HelpText = (props: Props) => {
  const {
    helpTitle,
    helpOpen,
    handleHelpDrawerOpen,
    handleHelpDrawerClose
  } = props;

  const [currentTitle, setCurrentTitle] = useState(helpTitle);
  const isLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const headerElement = useRef<HTMLElement>(null);

  const classes = useStyles();

  const handleClick = (action: string) => () => {
    setCurrentTitle(action);

    if (headerElement && headerElement.current) {
      headerElement.current.scrollIntoView({
        block: 'center',
        inline: 'start'
      });
    }
  };

  useEffect(() => {
    setCurrentTitle(helpTitle || currentTitle);
    // eslint-disable-next-line
  }, [helpTitle]);

  return useMemo(
    () => (
      <div data-testid={helpTextTestId} className={styles.wrapper}>
        <Button
          color="inherit"
          square="40"
          type="button"
          formNoValidate
          onClick={handleHelpDrawerOpen}
        >
          <HelpOutlineIcon />
        </Button>
        <Drawer
          open={helpOpen}
          variant="persistent"
          anchor={isLgUp ? Position.Right : Position.Left}
          classes={{
            paper: classes.paper
          }}
          ModalProps={{
            BackdropComponent: Backdrop,
            onBackdropClick: handleHelpDrawerClose,
            onEscapeKeyDown: handleHelpDrawerClose
          }}
        >
          <div className={styles.drawerContent}>
            <HeaderText
              title={currentTitle}
              isLgUp={isLgUp}
              handleDrawerClose={handleHelpDrawerClose}
              ref={headerElement}
            />
            <Text title={currentTitle} />
            <StaticText />
            <MenuListText title={currentTitle} handleClick={handleClick} />
          </div>
        </Drawer>
      </div>
    ),
    [
      classes.paper,
      currentTitle,
      handleHelpDrawerClose,
      handleHelpDrawerOpen,
      helpOpen,
      isLgUp
    ]
  );
};
