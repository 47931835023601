import React from 'react';

import { helpComponentTestId } from '../Text';

import helpStyles from '../HelpText.module.scss';

export const PricingText = () => {
  return (
    <div data-testid={helpComponentTestId}>
      <p>
        Pricing gives term and rate options. Follow pricing guidelines
        established by franchise ownership.
        <br />
        When you create an agreement in SIMS, the term will be recalculated so
        that you have the most accurate pricing on your agreement.
      </p>
      <ol className={helpStyles.list}>
        <li>
          Enter the desired Weekly Rate and Term.
          <br />
          Total RTO Price and other pricing options will be automatically
          calculated.
          <span className={helpStyles.listTitle}>Tips:</span>
          <ul>
            <li>
              You can change the Weekly Rate and Term to find a payment
              arrangement that works for your customer.
            </li>
            <li>
              You will need to add Weekly Rate and Weekly Term to each of the
              states listed as accordeons if you have added multiple stores form
              different states in the &quot;Allocations&quot; column.
            </li>
            <li>
              The term maybe recalculated when you create an agreement in SIMS
              as per legal limits.
            </li>
          </ul>
        </li>
        <li>
          To save your Manual Purchase Order and finish it later, click Save as
          Draft.Your Manual Purchase Order will appear in Draft POs where it can
          be further edited.A Purchase Order Number will be assigned.You may
          change pricing as often as you like while in Draft state.
        </li>
        <li>
          When all required information for your Manual Purchase Order has been
          input, Submit PO will be enabled.
        </li>
        <li>
          Once complete, click Submit PO to submit your PO to SIMS.Your Manual
          Purchase Order details will appear in Submitted POs.Once your PO is in
          SIMS and has been received, an agreement can be made.Once you Submit
          your PO, it cannot be changed.
        </li>
      </ol>
    </div>
  );
};
