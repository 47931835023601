import { omit } from 'lodash';

import { client } from './client';
import { Store } from '../domain/User/Store';
import { State } from '../domain/User/State';
import { ProductPermissions } from '../domain/User/ProductPermissions';
import { statesOfUS } from '../config/statesOfUS';
import { CancelToken } from 'axios';

const addStateToStore = (store: any): Store => {
  const state: State = {
    abbreviation: store.storeState,
    name:
      statesOfUS.find((state: State) => state.abbreviation === store.storeState)
        ?.name || ''
  };

  return omit(
    {
      ...store,
      state
    },
    ['storeState']
  ) as Store;
};

export const getUserPermissions = (
  productType = 'RPDMANPO'
): Promise<ProductPermissions[]> =>
  client(`users/current/permissions/${productType}`, { method: 'GET' }, true);

export const getStoresLimit = 125;
export const getStores = (
  term = '',
  cancelToken: CancelToken,
  limit = getStoresLimit
) => {
  const params: any = {};

  if (term) {
    params.q = `storeNum[:includes]=${term}`;
  }

  params.limit = limit;

  return client(
    'users/current/stores',
    {
      method: 'GET',
      params,
      cancelToken
    },
    true
  ).then((stores: Store[]) => {
    return stores.map(addStateToStore);
  });
};
