import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch
} from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { isEqual, some } from 'lodash';

import { useUserPermissions } from '../../../../../../context/user/PermissionsProvider';
import { PoFormField } from '../../po-form-config';
import { PurchaseOrderFormValues } from '../../PurchaseOrderFormValues';
import {
  FixedParams,
  RateAndTermResponse
} from '../../../../../../api/purchase-orders';

interface ActionProps {
  readonly type: string;
  readonly payload?: any;
}

export interface StateProps {
  readonly snapshot: any[];
  readonly isChanged: boolean;
  readonly allFieldsPresent: boolean;
  readonly fixedParams?: FixedParams;
  // string is meant to be a value from statesOfUS abbreviation
  readonly autoPricings: Record<string, RateAndTermResponse>;
}

export enum ActionType {
  SET_SNAPSHOT = 'setSnapshot',
  SET_IS_CHANGED = 'setIsChanged',
  SET_ALL_FIELDS_PRESENT = 'setAllFieldsPresent',
  SET_FIXED_PARAMS = 'setAllFields',
  SET_AUTO_PRICING = 'setAutoPricing'
}

export const initialState: StateProps = {
  snapshot: [],
  isChanged: false,
  allFieldsPresent: true,
  autoPricings: {}
};

export const reducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case ActionType.SET_SNAPSHOT: {
      return { ...state, snapshot: action.payload };
    }
    case ActionType.SET_IS_CHANGED: {
      return { ...state, isChanged: action.payload };
    }
    case ActionType.SET_ALL_FIELDS_PRESENT: {
      return { ...state, allFieldsPresent: action.payload };
    }
    case ActionType.SET_FIXED_PARAMS: {
      return { ...state, fixedParams: action.payload };
    }
    case ActionType.SET_AUTO_PRICING: {
      return {
        ...state,
        autoPricings: {
          ...state.autoPricings,
          [action.payload.state]: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export const StateContext = createContext(initialState);
export const DispatchContext = createContext<Dispatch<ActionProps>>(() => {});

interface Props {
  readonly children: ReactNode;
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly uniqueStates: string[];
}

export const AutoPricingProvider = (props: Props) => {
  const { children, uniqueStates, purchaseOrder } = props;
  const { hasAutoPricing } = useUserPermissions();
  const {
    formState: { isDirty }
  } = useFormContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const department = useWatch({
    name: PoFormField.Department,
    defaultValue: purchaseOrder?.department
  });
  const subDepartment = useWatch({
    name: PoFormField.SubDepartment,
    defaultValue: purchaseOrder?.subDepartment
  });
  const bracket = useWatch({
    name: PoFormField.Bracket,
    defaultValue: purchaseOrder?.bracket
  });
  const itemType = useWatch({
    name: PoFormField.ItemType,
    defaultValue: purchaseOrder?.itemType
  });
  const itemSubType = useWatch({
    name: PoFormField.ItemSubType,
    defaultValue: purchaseOrder?.itemSubType
  });
  const costPerItem = useWatch({ name: PoFormField.CostPerItem });
  const pricing = useWatch({ name: 'pricing' });

  useEffect(() => {
    if (!hasAutoPricing) return;

    const classifications =
      department && subDepartment && bracket && itemType && itemSubType;
    if (classifications && costPerItem && uniqueStates.length) {
      dispatch({ type: ActionType.SET_ALL_FIELDS_PRESENT, payload: true });
      const newSnapshot = [
        department,
        subDepartment,
        bracket,
        itemType,
        itemSubType,
        costPerItem,
        ...uniqueStates
      ];

      // TODO: see if this can be done without a snapshot
      if (!isEqual(state.snapshot, newSnapshot)) {
        dispatch({
          type: ActionType.SET_FIXED_PARAMS,
          payload: {
            department,
            subDepartment,
            bracket,
            itemType,
            itemSubType,
            costPerItem
          }
        });
        dispatch({ type: ActionType.SET_SNAPSHOT, payload: newSnapshot });

        const weeklyRentPresent = pricing && some(pricing, 'weeklyRent');
        if (isDirty || !weeklyRentPresent) {
          dispatch({ type: ActionType.SET_IS_CHANGED, payload: true });
        }
      }
    } else {
      if (state.snapshot.length) {
        dispatch({ type: ActionType.SET_SNAPSHOT, payload: [] });
      }
      dispatch({ type: ActionType.SET_ALL_FIELDS_PRESENT, payload: false });
    }
  }, [
    hasAutoPricing,
    pricing,
    isDirty,
    state.snapshot,
    department,
    subDepartment,
    bracket,
    itemType,
    itemSubType,
    costPerItem,
    uniqueStates
  ]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useAutoPricingState = () => {
  return useContext(StateContext);
};

export const useAutoPricingDispatch = () => {
  return useContext(DispatchContext);
};
