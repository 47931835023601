import { PoSubTypesActionType } from './po-sub-types-actions';
import { GlobalContextAction } from '../globalReducer';
import { SelectFieldOption } from '../../../components/common/select-field/SelectField';

export const poSubTypesReducer = (
  state: SelectFieldOption[],
  action: GlobalContextAction
): SelectFieldOption[] => {
  if (action.type === PoSubTypesActionType.SET_PO_SUB_TYPES) {
    return action.payload.poSubTypes;
  }

  return state;
};
