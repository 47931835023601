import { SnackbarMessageType } from '../SnackbarMessageType';

export interface PoSubmitSuccessMessage {
  readonly type: SnackbarMessageType.PO_SUBMIT_SUCCESS;
  readonly poId: string;
}

export const createPoSubmitSuccessMessage = (
  poId: string
): PoSubmitSuccessMessage => ({
  type: SnackbarMessageType.PO_SUBMIT_SUCCESS,
  poId
});
