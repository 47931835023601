import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { PoFormField, PoFormLabel } from '../po-form-config';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';
import { FieldWithTooltip } from '../../../../common/field-with-tooltip/FieldWithTooltip';
import { isNonEmptyValue } from '../../../../../utils/isNonEmptyValue';

import styles from '../PurchaseOrderForm.module.scss';

interface Props {
  readonly disabled: boolean;
}

const maxLength = 50;

const poSubTypeForWhichFieldIsRequired = 'PCRD';

export const ReferenceNumber = (props: Props) => {
  const { disabled } = props;

  const { register, errors, trigger } = useFormContext<
    PurchaseOrderFormValues
  >();

  const poSubType: string | undefined = useWatch({
    name: PoFormField.PurchaseOrderSubType
  });

  const isRequired = poSubType === poSubTypeForWhichFieldIsRequired;

  useEffect(() => {
    trigger(PoFormField.ReferenceNumber);
  }, [poSubType, trigger]);

  return useMemo(
    () => (
      <FieldWithTooltip
        info="Please add the purchase reference number with at most 50 characters"
        id={PoFormField.ReferenceNumber}
        title={`${PoFormLabel.ReferenceNumber}:`}
        required={isRequired}
      >
        <input
          name={PoFormField.ReferenceNumber}
          type="text"
          ref={register({
            required: isRequired,
            maxLength,
            validate: value => isNonEmptyValue(value)
          })}
          placeholder="Enter the purchase reference number"
          disabled={disabled}
          className={errors.referenceNumber ? styles.invalid : ''}
          autoComplete="off"
          maxLength={maxLength}
        />
      </FieldWithTooltip>
    ),
    [disabled, errors.referenceNumber, isRequired, register]
  );
};
