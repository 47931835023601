import { DraftPosAction, DraftPosActionType } from './draft-pos-actions';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';

export interface DraftPosState {
  purchaseOrders?: PurchaseOrder[];
  isPending: boolean;
  hasError: boolean;
}

export const submittedPosInitialState: DraftPosState = {
  isPending: false,
  hasError: false
};

export const draftPosReducer = (
  state: DraftPosState,
  action: DraftPosAction
): DraftPosState => {
  switch (action.type) {
    case DraftPosActionType.GET_DRAFT_POS:
      return {
        ...state,
        isPending: true,
        hasError: false
      };

    case DraftPosActionType.GET_DRAFT_POS_SUCCESS:
      return {
        ...state,
        purchaseOrders: action.payload.purchaseOrders,
        isPending: false,
        hasError: false
      };

    case DraftPosActionType.GET_DRAFT_POS_ERROR:
      return {
        ...state,
        isPending: false,
        hasError: true
      };

    default:
      return state;
  }
};
