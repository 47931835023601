import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { ClassificationType } from '../../../../../domain/PurchaseOrder/ClassificationType';
import { ClassificationsComponentProps } from './context/types';
import { filterById } from '../../../../../utils/filterById';
import { populateSelectByChild } from '../../../../../utils/populateSelectByChild';
import {
  SelectWrapper,
  SelectedFieldOption,
  emptyField
} from '../select-wrapper/SelectWrapper';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import {
  useClassificationsState,
  useClassificationsDispatch
} from './context/Provider';
import {
  PoFormField,
  ClassificationsInputPlaceholder
} from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';

export const subdepartmentTestId = 'subdepartmentComponent';

// eslint-disable-next-line
export const SubDepartment = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { startWithDepartment, subDepartments } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(() => {
    const poSubDepartment = purchaseOrder && purchaseOrder.subDepartment;
    const selectedSubDepartment: {
      name: string;
      id: string;
      brackets: [];
    } | null =
      subDepartments &&
      poSubDepartment &&
      filterById(subDepartments, poSubDepartment)[0];

    if (subDepartments && subDepartments.length === 1) {
      setItemValue({
        value: subDepartments[0].id,
        label: subDepartments[0].name
      });

      dispatch({
        type: ClassificationType.SET_SINGLE_BRACKET,
        payload: subDepartments[0].brackets
      });
    } else {
      setItemValue({
        value: (selectedSubDepartment && selectedSubDepartment.id) || '',
        label:
          (selectedSubDepartment && selectedSubDepartment.name) ||
          ClassificationsInputPlaceholder
      });

      selectedSubDepartment && selectedSubDepartment.brackets
        ? dispatch({
            type: ClassificationType.SET_SUBDEPARTMENTS,
            payload: selectedSubDepartment.brackets
          })
        : dispatch({
            type: ClassificationType.CLEAR_BRACKETS,
            payload: []
          });
    }
    // eslint-disable-next-line
  }, [subDepartments, dispatch]);

  const handleChange = useCallback(
    // eslint-disable-next-line
    (selected: SelectedFieldOption) => {
      const { value, label, brackets } = selected;
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      if (purchaseOrder && purchaseOrder.subDepartment !== value) {
        purchaseOrder.bracket = '';
        if (startWithDepartment) {
          purchaseOrder.itemType = '';
          purchaseOrder.itemSubType = '';
        }
      }

      if (brackets) {
        dispatch({
          type: ClassificationType.SET_SUBDEPARTMENTS,
          payload: brackets
        });
        if (brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
        if (startWithDepartment) {
          const filterdTypes = brackets[0].types;
          const filterdSubTypes = filterdTypes[0].subTypes;
          if (filterdTypes.length === 1) {
            setValue(PoFormField.ItemType, filterdTypes[0].id, {
              shouldValidate: true,
              shouldDirty: true
            });
          } else {
            setValue(PoFormField.ItemType, '', {
              shouldValidate: true,
              shouldDirty: true
            });
          }

          if (filterdSubTypes.length === 1) {
            setValue(PoFormField.ItemSubType, filterdSubTypes[0].id, {
              shouldValidate: true,
              shouldDirty: true
            });
          } else {
            setValue(PoFormField.ItemSubType, '', {
              shouldValidate: true,
              shouldDirty: true
            });
          }
        }
      }
    },
    [dispatch, name, purchaseOrder, setValue, startWithDepartment]
  );

  const isViewMode =
    purchaseOrder && purchaseOrder.poStatus === PoStatus.SUBMITTED;

  return useMemo(
    () => (
      <div
        data-testid={subdepartmentTestId}
        className={clsx(formStyles.formBlock, {
          [formStyles.noFillField]: !(
            subDepartments && subDepartments.length > 0
          )
        })}
      >
        <label htmlFor={name}>
          <span className={formStyles.error}>*</span>
          {label}:
        </label>

        <SelectWrapper
          propValue={itemValue}
          placeholder={ClassificationsInputPlaceholder}
          name={name}
          handleChange={handleChange}
          options={populateSelectByChild(subDepartments, 'brackets')}
          disabled={
            isViewMode || (subDepartments && subDepartments.length === 0)
          }
          purchaseOrder={purchaseOrder}
        />
      </div>
    ),
    [
      isViewMode,
      purchaseOrder,
      subDepartments,
      handleChange,
      itemValue,
      label,
      name
    ]
  );
};
