import { Dispatch } from 'react';

import { getUserPermissions } from '../../api/users';
import {
  ApiAction,
  getErrorAction,
  getLoadingAction,
  getSuccessAction
} from '../api-actions';

export const getPermissionsThunk = (
  dispatch: Dispatch<ApiAction>
) => async () => {
  try {
    dispatch(getLoadingAction());

    dispatch(getSuccessAction(await getUserPermissions()));
  } catch (error) {
    dispatch(getErrorAction(error));
  }
};
