import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import navStyles from '../../layout/nav/Nav.module.scss';
import styles from './PurchaseOrdersNav.module.scss';

import { Button } from '../../common/Button';
import { Nav } from '../../layout/nav/Nav';
import { PurchaseOrdersRoute } from '../../app/routes/PurchaseOrdersRoute';
import { useUserPermissions } from '../../../context/user/PermissionsProvider';

export const purchaseOrdersNavTestId = 'purchaseOrdersNavComponent';
export const submittedPosLinkTestId = 'purchaseOrdersNavSubmittedComponent';
export const draftPosLinkTestId = 'purchaseOrdersNavDraftComponent';

export const submittedPosLinkText = `Submitted PO'S`;
export const draftPosLinkText = `Draft PO'S`;

export const PurchaseOrdersNav: FC = () => {
  const { hasError, loading } = useUserPermissions();

  return (
    <>
      <Nav data-testid={purchaseOrdersNavTestId}>
        <NavLink
          className={navStyles.navItem}
          data-testid={submittedPosLinkTestId}
          to={PurchaseOrdersRoute.Submitted}
          activeClassName={navStyles.active}
        >
          <Button color="inherit">{submittedPosLinkText}</Button>
        </NavLink>

        <NavLink
          className={navStyles.navItem}
          data-testid={draftPosLinkTestId}
          to={PurchaseOrdersRoute.Draft}
          activeClassName={navStyles.active}
        >
          <Button color="inherit">{draftPosLinkText}</Button>
        </NavLink>
      </Nav>
      {loading && null /* loading permissions*/}
      {hasError && (
        <div className={styles.permissionsError}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          The permissions API failed to load, therefore the usual product
          permissions are not available.
        </div>
      )}
    </>
  );
};
