import { SnackbarActionType } from './snackbar-actions';
import { SnackbarMessage } from '../../../components/layout/snackbar/messsages/SnackbarMessage';
import { GlobalContextAction } from '../globalReducer';

export interface SnackbarState {
  readonly isOpen: boolean;
  readonly message?: SnackbarMessage;
}

export const snackBarInitialState: SnackbarState = { isOpen: false };

export const snackbarReducer = (
  state: SnackbarState,
  action: GlobalContextAction
): SnackbarState => {
  switch (action.type) {
    case SnackbarActionType.OPEN_SNACKBAR:
      return {
        isOpen: true,
        message: action.payload.message
      };
    case SnackbarActionType.CLOSE_SNACKBAR:
      return snackBarInitialState;
    default:
      return state;
  }
};
