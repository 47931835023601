import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const GenericError = () => (
  <Box display="flex" flexDirection="column" alignItems="center" p="1rem">
    <Typography>An error occured.</Typography>
    <Typography>Please try again later.</Typography>
  </Box>
);
