import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { ClassificationType } from '../../../../../domain/PurchaseOrder/ClassificationType';
import { ClassificationsComponentProps } from './context/types';
import { filterById } from '../../../../../utils/filterById';
import { populateSelectByChild } from '../../../../../utils/populateSelectByChild';
import {
  SelectWrapper,
  SelectedFieldOption,
  emptyField
} from '../select-wrapper/SelectWrapper';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import {
  useClassificationsState,
  useClassificationsDispatch
} from './context/Provider';
import {
  PoFormField,
  ClassificationsInputPlaceholder
} from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';

export const bracketComponentTestId = 'bracketComponent';

// eslint-disable-next-line
export const Bracket = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { brackets, startWithDepartment } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(
    // eslint-disable-next-line
    () => {
      const poSubDepartment = purchaseOrder && purchaseOrder.subDepartment;
      const poBracket = purchaseOrder && purchaseOrder.bracket;
      const selectedBraket: {
        name: string;
        id: string;
        types: [];
      } | null =
        poSubDepartment && poBracket && filterById(brackets, poBracket)[0];

      if (brackets.length === 1) {
        setItemValue({ value: brackets[0].id, label: brackets[0].name });

        if (startWithDepartment) {
          dispatch({
            type: ClassificationType.SET_SINGLE_TYPE,
            payload: brackets[0].types
          });
        }
      } else {
        setItemValue({
          value: (selectedBraket && selectedBraket.id) || '',
          label:
            (selectedBraket && selectedBraket.name) ||
            ClassificationsInputPlaceholder
        });

        if (startWithDepartment) {
          dispatch({
            type: ClassificationType.CLEAR_TYPES,
            payload: []
          });

          if (selectedBraket) {
            dispatch({
              type: ClassificationType.SET_BRACKETS,
              payload: selectedBraket.types
            });
          }
        }
      }
    },
    // eslint-disable-next-line
    [brackets, dispatch, startWithDepartment]
  );

  const handleChange = useCallback(
    ({ value, label, types }: SelectedFieldOption) => {
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      !startWithDepartment &&
        dispatch({
          type: ClassificationType.SET_BRACKET_END
        });

      if (startWithDepartment && types) {
        let filteredSubTypes = types[0].subTypes;

        if (purchaseOrder && purchaseOrder.bracket !== value) {
          purchaseOrder.itemType = '';
          purchaseOrder.itemSubType = '';
        }

        dispatch({
          type: ClassificationType.SET_BRACKETS,
          payload: types
        });

        if (types.length === 1) {
          setValue(PoFormField.ItemType, types[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          filteredSubTypes = [];
          setValue(PoFormField.ItemType, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }

        if (filteredSubTypes && filteredSubTypes.length === 1) {
          setValue(PoFormField.ItemSubType, filteredSubTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          filteredSubTypes = [];
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      }
    },
    [dispatch, name, purchaseOrder, setValue, startWithDepartment]
  );

  return useMemo(() => {
    const isViewMode =
      purchaseOrder && purchaseOrder.poStatus === PoStatus.SUBMITTED;
    return (
      <div
        data-testid={bracketComponentTestId}
        className={
          brackets.length > 0
            ? formStyles.formBlock
            : clsx([formStyles.formBlock, formStyles.noFillField])
        }
      >
        <label htmlFor={name}>
          <span className={formStyles.error}>*</span>
          {label}:
        </label>
        <SelectWrapper
          propValue={itemValue}
          placeholder={ClassificationsInputPlaceholder}
          name={name}
          handleChange={handleChange}
          options={populateSelectByChild(brackets, 'types')}
          disabled={isViewMode || !brackets.length}
          purchaseOrder={purchaseOrder}
        />
      </div>
    );
  }, [brackets, handleChange, itemValue, label, name, purchaseOrder]);
};
