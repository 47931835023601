import { Action } from '../../Action';
import { PoStatus } from '../../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';

export enum SubmittedPosActionType {
  GET_SUBMITTED_POS = 'GET_SUBMITTED_POS',
  GET_SUBMITTED_POS_SUCCESS = 'GET_SUBMITTED_POS_SUCCESS',
  GET_SUBMITTED_POS_ERROR = 'GET_SUBMITTED_POS_ERROR'
}

export interface GetSubmittedPosAction extends Action {
  type: SubmittedPosActionType.GET_SUBMITTED_POS;
  payload: { poStatus: PoStatus };
}

export interface GetSubmittedPosSuccessAction extends Action {
  type: SubmittedPosActionType.GET_SUBMITTED_POS_SUCCESS;
  payload: {
    purchaseOrders: PurchaseOrder[];
  };
}

export interface GetSubmittedPosErrorAction extends Action {
  type: SubmittedPosActionType.GET_SUBMITTED_POS_ERROR;
}

export type SubmittedPosAction =
  | GetSubmittedPosAction
  | GetSubmittedPosSuccessAction
  | GetSubmittedPosErrorAction;

export const getSubmittedPosAction = (): SubmittedPosAction => ({
  type: SubmittedPosActionType.GET_SUBMITTED_POS,
  payload: {
    poStatus: PoStatus.SUBMITTED
  }
});

export const getSubmittedPosSuccessAction = (
  purchaseOrders: PurchaseOrder[]
): GetSubmittedPosSuccessAction => ({
  type: SubmittedPosActionType.GET_SUBMITTED_POS_SUCCESS,
  payload: { purchaseOrders }
});

export const getSubmittedPosErrorAction = (): GetSubmittedPosErrorAction => ({
  type: SubmittedPosActionType.GET_SUBMITTED_POS_ERROR
});
