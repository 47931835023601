import React from 'react';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import styles from './purchase-order-card/PurchaseOrderCard.module.scss';

const useTopSkeletonStyles = makeStyles({
  root: {
    height: '30px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  }
});

const useBottomSkeletonStyles = makeStyles({
  root: {
    height: '30px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px'
  }
});

export const PurchaseOrderSkeletonCard = () => {
  const topSkeletonClasses = useTopSkeletonStyles();
  const bottomSkeletonClasses = useBottomSkeletonStyles();

  return (
    <div
      className={styles.cardContainer}
      data-testid="PurchaseOrderSkeletonCardComponent"
    >
      <div className={styles.card}>
        <Skeleton
          className={topSkeletonClasses.root}
          variant="rect"
          width={'100%'}
          animation={'wave'}
        />
        <div className={styles.cardContentPO}>
          <div className={styles.cardContentPOHeader}>
            <h2 className={clsx(styles.value, styles.brand)}>
              <Skeleton width={80} animation={'wave'} />
            </h2>
            <h2 className={clsx(styles.description, styles.description)}>
              <Skeleton width={80} animation={'wave'} />
            </h2>
          </div>
          <hr className={styles.cardSeparator} />
          <div className={styles.cardContentPOInner}>
            <Grid container spacing={2}>
              <Grid item lg={6} xl={4}>
                <h6>
                  <span className={styles.type}>
                    <Skeleton width={60} animation={'wave'} />
                  </span>
                  <span className={styles.value}>
                    <Skeleton width={120} animation={'wave'} />
                  </span>
                </h6>
                <h6>
                  <span className={styles.type}>
                    <Skeleton width={60} animation={'wave'} />
                  </span>
                  <span className={styles.value}>
                    <Skeleton width={120} animation={'wave'} />
                  </span>
                </h6>
                <div className={styles.divideMobile}>
                  <h6>
                    <span className={styles.type}>
                      <Skeleton width={60} animation={'wave'} />
                    </span>
                    <span className={styles.value}>
                      <Skeleton width={120} animation={'wave'} />
                    </span>
                  </h6>
                  <h6>
                    <span className={styles.type}>
                      <Skeleton width={60} animation={'wave'} />
                    </span>
                    <span className={styles.value}>
                      <Skeleton width={120} animation={'wave'} />
                    </span>
                  </h6>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Skeleton
          className={bottomSkeletonClasses.root}
          width={'100%'}
          animation={'wave'}
          variant="rect"
        />
      </div>
    </div>
  );
};
