import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './PleaseWaitBox.module.scss';

export const pleaseWaitBoxTestId = 'PleaseWaitBoxComponent';

export const PleaseWaitBox = () => (
  <div className={styles.boxContainer} data-testid={pleaseWaitBoxTestId}>
    <div className={styles.headerPart}>
      <h2>Please be patient...</h2>
      <CircularProgress />
    </div>
    <hr />
    <p>We are carefully processing your purchase order.</p>
    <hr />
  </div>
);
