import { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { PurchaseOrdersRoute } from '../../../app/routes/PurchaseOrdersRoute';
import { PoStatus } from '../../../../domain/PurchaseOrder/PoStatus';
import { useGlobalContext } from '../../../../context/global/GlobalContext';
import { PoFormField } from './po-form-config';
import { PurchaseOrderFormValues } from './PurchaseOrderFormValues';
import { openSnackbarAction } from '../../../../context/global/snackbar/snackbar-actions';
import { createGenericMessage } from '../../../layout/snackbar/messsages/generic-message/CreateGenericMessage';
import { useHistory } from 'react-router-dom';

interface DuplicatePoState {
  readonly duplicatePO?: boolean;
  readonly previousValues?: PurchaseOrderFormValues;
  readonly poId?: string;
}

interface DuplicatePoMethods {
  getValues: UseFormMethods['getValues'];
  setValue: UseFormMethods['setValue'];
  reset: UseFormMethods['reset'];
  trigger: UseFormMethods['trigger'];
}

export function useDuplicatePo(poId: string, methods: DuplicatePoMethods) {
  const { getValues, setValue, reset, trigger } = methods;
  const [duplicatedPoId, setDuplicatedPoId] = useState('');
  const globalContext = useGlobalContext();
  const history = useHistory<DuplicatePoState>();
  const {
    location: { state: locationState }
  } = history;

  const handleDuplicate = () => {
    history.push(PurchaseOrdersRoute.Create, {
      duplicatePO: true,
      previousValues: getValues() as PurchaseOrderFormValues,
      poId: poId
    });
  };

  useEffect(
    function resetForm() {
      if (locationState && locationState.duplicatePO) {
        const { previousValues, poId } = locationState;

        reset(
          {
            ...previousValues,
            [PoFormField.ReferenceNumber]: '',
            [PoFormField.PoStatus]: PoStatus.DRAFT,
            [PoFormField.Supplier]: `${previousValues?.supplier}-x` // workaround
          },
          { isValid: true }
        );
        history.push(PurchaseOrdersRoute.Create, {
          duplicatePO: false,
          previousValues,
          poId
        });
      }
    },
    [locationState, reset, setValue, history]
  );

  useEffect(
    function workaroundForDirtyFormAfterReset() {
      if (locationState && !locationState.duplicatePO) {
        const { previousValues, poId } = locationState;

        //  correct value instead of workaround so that the form is always dirty after Duplicate
        setValue(PoFormField.Supplier, previousValues?.supplier, {
          shouldDirty: true
        });

        trigger([PoFormField.ReferenceNumber]);

        if (poId) {
          setDuplicatedPoId(poId);
        }
      }
    },
    [locationState, setValue, history, trigger, setDuplicatedPoId]
  );

  useEffect(
    function showSuccessMessage() {
      if (duplicatedPoId) {
        globalContext.dispatch(
          openSnackbarAction(
            createGenericMessage(
              `PO #${duplicatedPoId} has been successfully duplicated`
            )
          )
        );
        setDuplicatedPoId('');
      }
    },
    [globalContext, setDuplicatedPoId, duplicatedPoId]
  );

  return { handleDuplicate };
}
