import React from 'react';

import { helpComponentTestId } from '../Text';

import helpStyles from '../HelpText.module.scss';

export const AllocationsText = () => {
  return (
    <div data-testid={helpComponentTestId}>
      <p>
        Allocating the inventory in your Manual Purchase Order makes sure it’s
        assigned to the right store. This is important for accounting purposes.
      </p>
      <ol className={helpStyles.list}>
        <li>
          Using the drop down menus, select the categories that most accurately
          describe the item.
          <span className={helpStyles.listTitle}>Tips:</span>
          <ul>
            <li>
              The system allows you to allocate all of your items to a single
              store or you can allocate the items among any number of stores.
            </li>
            <li>
              To allocate some items to one store and some to another store, you
              can add another pair of &quot;Quantity&quot; and &quot;Store&quot;
              and adjust the number of items sent to each of the stores present
              on the page.
            </li>
          </ul>
        </li>
        <li>
          To save your Manual Purchase Order and finish it later, click Save as
          Draft.Your Manual Purchase Order will appear in Draft POs where it can
          be further edited.A Purchase Order Number will be assigned.You may
          change allocations as often as you like while in Draft state.
        </li>
        <li>
          When all required information for your Manual Purchase Order has been
          input, Submit PO will be enabled.
        </li>
        <li>
          Once complete, click Submit PO to submit your PO to SIMS.Your Manual
          Purchase Order details will appear in Submitted POs.Once your PO is in
          SIMS and has been received, an agreement can be made.Once you Submit
          your PO, it cannot be changed.
        </li>
      </ol>
    </div>
  );
};
