import React from 'react';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { Link } from 'react-router-dom';

import { PurchaseOrdersRoute } from '../../../app/routes/PurchaseOrdersRoute';

import styles from './CreatePoButton.module.scss';

export const CreatePoButton = () => (
  <Link
    data-testid="mainBtn"
    className={styles.link}
    to={PurchaseOrdersRoute.Create}
  >
    <span className={styles.icon}>
      <ControlPointIcon />
    </span>
    <span className={styles.text}>Create PO</span>
  </Link>
);
