import { ClassificationsActionType } from './classifications-actions';
import { GenericObject } from '../../../utils/GenericObject';
import { GlobalContextAction } from '../globalReducer';

export const classificationsReducer = (
  state: GenericObject,
  action: GlobalContextAction
): GenericObject => {
  if (action.type === ClassificationsActionType.SET_CLASSIFICATIONS) {
    return action.payload.classifications;
  }
  return state;
};
