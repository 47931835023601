import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import { PoStatus } from '../../../domain/PurchaseOrder/PoStatus';
import {
  DraftPosAction,
  getDraftPosAction,
  getDraftPosErrorAction,
  getDraftPosSuccessAction
} from './draft-pos-actions';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';
import { draftPOCancelMessage } from '../../../components/screens/draft-purchase-orders/draft-pos-list/DraftPOsList';
import * as purchaseOrderClient from '../../../api/purchase-orders';
import { Store } from '../../../domain/User/Store';

export const getDraftPurchaseOrders = (
  dispatch: Dispatch<DraftPosAction>
) => async (poStores?: Store[], token?: CancelToken): Promise<void> => {
  try {
    dispatch(getDraftPosAction());

    poStores = poStores || [];
    const poStoreNums = poStores.map(store => store.storeNum);

    const purchaseOrders: PurchaseOrder[] = await purchaseOrderClient.getPurchaseOrdersByStatus(
      PoStatus.DRAFT,
      poStoreNums,
      token
    );

    dispatch(getDraftPosSuccessAction(purchaseOrders));
  } catch (e) {
    if (e.message !== draftPOCancelMessage) {
      dispatch(getDraftPosErrorAction());
    }
  }
};
