import React from 'react';

import { SnackbarMessage } from '../messsages/SnackbarMessage';
import { SnackbarMessageType } from '../messsages/SnackbarMessageType';
import { PoSubmitSuccess } from '../messsages/po-submit-success/PoSubmitSuccess';
import { GenericMessage } from '../messsages/generic-message/GenericMessage';

import styles from './MessageBox.module.scss';

export const MessageBox = ({ message }: { message?: SnackbarMessage }) => {
  if (!message) {
    return <div />;
  }

  return (
    <div className={styles.messageBox}>
      {message.type === SnackbarMessageType.PO_SUBMIT_SUCCESS && (
        <PoSubmitSuccess {...message} />
      )}
      {message.type === SnackbarMessageType.GENERIC_MESSAGE && (
        <GenericMessage {...message} />
      )}
    </div>
  );
};
