import React, { useContext, useState, useEffect } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import clsx from 'clsx';
import { TextField, Button } from '@material-ui/core';

import styles from './SearchBox.module.scss';

import { Store } from '../../../../domain/User/Store';
import { getSuccessAction } from '../../../../context/api-actions';
import { getStoresThunk } from '../../../../context/stores/stores-thunks';
import {
  StoresStateContext,
  useStores
} from '../../../../context/stores/StoresProvider';
import { SelectAll } from './select-all/SelectAll';
import useDebounce from '../../../../utils/useDebounce';
import { SearchList } from './search-list/SearchList';
import { intersectionBy, unionBy, xorBy } from 'lodash';
import { generateCancelToken } from '../../../../api/client';
import { CancelTokenSource } from 'axios';
import { noOp } from '../../../../utils/helper-functions';

interface Props {
  readonly isCollapsed: boolean;
  readonly selectedStores: Store[];
  readonly setCollapsed?: (value: boolean) => void;
  readonly setSelectedStores?: (value: Store[]) => void;
  readonly onApply?: () => void;
}

export const SearchBoxTestId = 'SearchBoxTestId';
export const debounceDelay = 500;

export const SearchBox = ({
  isCollapsed,
  selectedStores,
  setCollapsed = noOp,
  setSelectedStores = noOp,
  onApply = noOp
}: Props) => {
  const { stores: initialStores } = useContext(StoresStateContext);
  const [storeList, dispatch] = useStores();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);

  useEffect(
    function fetchStores() {
      const cancelToken: CancelTokenSource = generateCancelToken();

      if (debouncedSearchTerm.length >= 3) {
        getStoresThunk(dispatch, cancelToken.token, debouncedSearchTerm);
      } else if (debouncedSearchTerm.length === 0) {
        dispatch(getSuccessAction(initialStores));
      }

      return cancelToken.cancel;
    },
    [debouncedSearchTerm, dispatch, initialStores]
  );

  const toggleCollapsed = () => {
    setCollapsed(!isCollapsed);
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedStores(unionBy(storeList.stores, selectedStores, 'storeNum'));
    } else {
      setSelectedStores(xorBy(storeList.stores, selectedStores, 'storeNum'));
    }
  };

  const selectedStoresInList = intersectionBy(
    storeList.stores,
    selectedStores,
    'storeNum'
  );

  return (
    <div
      className={clsx({
        [styles.searchBox]: true,
        [styles.collapsed]: isCollapsed
      })}
      data-testid={SearchBoxTestId}
      onClick={() => isCollapsed && setCollapsed(false)}
    >
      {isCollapsed && (
        <>
          <ArrowDropDown className={styles.arrow} onClick={toggleCollapsed} />
          <span className={styles.tooltip}>Search Stores</span>
        </>
      )}

      {!isCollapsed && (
        <>
          <ArrowDropUp className={styles.arrow} onClick={toggleCollapsed} />
          <TextField
            className={styles.input}
            type="text"
            placeholder="Search Stores"
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
            autoFocus
          />

          <SelectAll
            selectedCount={selectedStoresInList.length}
            total={storeList.stores.length}
            onChange={handleSelectAll}
          />

          <SearchList
            storeList={storeList}
            selectedStores={selectedStores}
            onChange={setSelectedStores}
          />

          <Button
            className={styles.searchButton}
            variant="contained"
            color="primary"
            onClick={onApply}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
};
