import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Button } from '../Button';

import styles from './AlertDialog.module.scss';

export interface BtnProps {
  readonly label?: any;
  readonly variant?: 'text' | 'outlined' | 'contained';
  readonly dataCancel?: string;
  readonly dataTest?: string | undefined;
  readonly disabled?: boolean;
}

export interface AlertDialogProps {
  open: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  classes?: any;
  title?: string;
  text?: string;
  note?: string | undefined;
  btnsConfig?: BtnProps[];
  borderColor?: string;
}

const useStyles = makeStyles({
  paper: {
    margin: '0.25rem',
    padding: '0.5625rem',
    borderRadius: 0,
    boxShadow: 'none'
  },
  BackdropPropsRoot: {
    backgroundColor: 'rgba(240, 240, 240, 0.8)',
    backdropFilter: 'blur(2px)'
  }
});

export const testId = 'AlertDialogComponent';
export const AlertDialog = (props: AlertDialogProps) => {
  const classes = useStyles();
  const { open, onClose, title, text, note, borderColor, btnsConfig } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      classes={{ paper: `${classes.paper} ${styles.root}` }}
      BackdropProps={{ classes: { root: classes.BackdropPropsRoot } }}
    >
      <div
        data-testid={testId}
        className={styles.alertInner}
        style={{ borderColor }}
      >
        <div className={`${styles.alertTitle} ${styles.withBorder}`}>
          <Typography id="alert-dialog-title" variant="h3">
            {title}
          </Typography>
        </div>
        <div
          className={`${styles.alertContent} ${styles.withBorder}`}
          id="alert-dialog-description"
        >
          <Typography variant="body1">{text}</Typography>
          <Typography variant="body1">{note}</Typography>
        </div>
        <div
          className={
            btnsConfig && btnsConfig.length === 1
              ? styles['alertActionsSingle']
              : styles['alertActions']
          }
        >
          {btnsConfig &&
            btnsConfig.length > 0 &&
            btnsConfig.map(
              (
                { label, variant, dataCancel, dataTest, disabled }: BtnProps,
                index: number
              ) => (
                <Button
                  key={index}
                  variant={variant}
                  color="primary"
                  type="button"
                  onClick={onClose}
                  data-cancel={dataCancel}
                  data-testid={dataTest}
                  disabled={disabled || false}
                >
                  {label}
                </Button>
              )
            )}
        </div>
      </div>
    </Dialog>
  );
};
