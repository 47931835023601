import { createContext, Dispatch } from 'react';

import {
  submittedPosInitialState,
  SubmittedPosState
} from './submittedPosReducer';
import { SubmittedPosAction } from './submitted-pos-actions';

export interface SubmittedPosContextValue extends SubmittedPosState {
  dispatch: Dispatch<SubmittedPosAction>;
}

export const SubmittedPosContext = createContext<SubmittedPosContextValue>({
  ...submittedPosInitialState,
  dispatch: () => {}
});
