import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { ClassificationsComponentProps } from './context/types';
import { filterById } from '../../../../../utils/filterById';
import { populateSelectByChild } from '../../../../../utils/populateSelectByChild';
import { ClassificationType } from '../../../../../domain/PurchaseOrder/ClassificationType';
import {
  SelectWrapper,
  SelectedFieldOption,
  emptyField
} from '../select-wrapper/SelectWrapper';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import {
  useClassificationsState,
  useClassificationsDispatch
} from './context/Provider';
import {
  PoFormField,
  ClassificationsInputPlaceholder
} from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';

export const classificationsTypeTestId = 'classificationsTypeComponent';

// eslint-disable-next-line
export const ClassificationsType = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue, getValues } = useFormContext();
  const {
    initialTypes,
    types,
    startWithDepartment,
    isBracketSelected
  } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(
    // eslint-disable-next-line
    () => {
      const poItemType = purchaseOrder && purchaseOrder.itemType;
      const optionTypes = types && types.length ? types : initialTypes || [];
      const selectedType: {
        name: string;
        id: string;
        subTypes: [];
      } | null = poItemType ? filterById(optionTypes, poItemType)[0] : null;

      if (types.length === 1) {
        setItemValue({ value: types[0].id, label: types[0].name });
        dispatch({
          type: ClassificationType.SET_SINGLE_SUBTYPE,
          payload: types[0].subTypes
        });
        selectedType &&
          dispatch({
            type: ClassificationType.SET_SINGLE_SUBTYPE,
            payload: selectedType.subTypes
          });
      } else {
        if (startWithDepartment) {
          setItemValue(emptyField);
          dispatch({
            type: ClassificationType.CLEAR_SUBTYPES,
            payload: []
          });
        }
      }
      if (poItemType && !startWithDepartment && !isBracketSelected) {
        setItemValue({
          value: (selectedType && selectedType.id) || '',
          label:
            (selectedType && selectedType.name) ||
            ClassificationsInputPlaceholder
        });
      }
      if (startWithDepartment && selectedType && isBracketSelected) {
        dispatch({
          type: ClassificationType.SET_TYPES,
          payload: selectedType.subTypes
        });
      }
    },
    // eslint-disable-next-line
    [types, dispatch, startWithDepartment, initialTypes, isBracketSelected]
  );

  useEffect(
    () => {
      const poItemType = purchaseOrder && purchaseOrder.itemType;
      const poStartClassificationsSelect =
        purchaseOrder && purchaseOrder.startClassificationsSelect;

      // eslint-disable-next-line
      const optionTypes = types && types.length ? types : initialTypes || [];
      const selectedType: {
        name: string;
        id: string;
        subTypes: [];
      } | null = poItemType ? filterById(optionTypes, poItemType)[0] : null;

      if (
        poStartClassificationsSelect === 'itemType' &&
        !startWithDepartment &&
        selectedType
      ) {
        dispatch({
          type: ClassificationType.SET_TYPES_AS_PARENT,
          payload: selectedType
        });
      }
    },
    // eslint-disable-next-line
    [dispatch, types, startWithDepartment, initialTypes]
  );

  const startClassificationsSelect =
    getValues() && getValues().startClassificationsSelect;

  const handleChange = useCallback(
    // eslint-disable-next-line
    (selected: SelectedFieldOption) => {
      const { value, label, subTypes } = selected;

      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      startClassificationsSelect !== PoFormField.Department &&
        setValue(PoFormField.StartClassificationsSelect, PoFormField.ItemType);

      if (!startWithDepartment) {
        dispatch({
          type: ClassificationType.SET_TYPES_AS_PARENT,
          payload: selected
        });
        if (purchaseOrder && purchaseOrder.itemType !== value) {
          purchaseOrder.itemSubType = '';
          purchaseOrder.department = '';
          purchaseOrder.subDepartment = '';
          purchaseOrder.bracket = '';
        }

        // eslint-disable-next-line
        let subTypes = selected.subTypes;
        let departments = subTypes[0].departments;
        let subDepartments = departments[0].subDepartments;
        let brackets = subDepartments[0].brackets;

        if (subTypes && subTypes.length === 1) {
          setValue(PoFormField.ItemSubType, subTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }

        if (departments && departments.length === 1) {
          setValue(PoFormField.Department, departments[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.Department, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }

        if (subDepartments.length === 1) {
          setValue(PoFormField.SubDepartment, subDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }

        if (brackets && brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      }

      if (startWithDepartment) {
        dispatch({
          type: ClassificationType.SET_TYPES,
          payload: subTypes
        });
        if (subTypes.length === 1) {
          setValue(PoFormField.ItemSubType, subTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
      }
    },
    [
      dispatch,
      startClassificationsSelect,
      name,
      purchaseOrder,
      setValue,
      startWithDepartment
    ]
  );

  const typeOptions = useCallback(() => {
    return types.length > 0
      ? populateSelectByChild(types, 'subTypes')
      : populateSelectByChild(initialTypes, 'subTypes');
  }, [initialTypes, types]);

  const isViewMode =
    purchaseOrder && purchaseOrder.poStatus === PoStatus.SUBMITTED;

  return useMemo(
    () => (
      <div
        data-testid={classificationsTypeTestId}
        className={
          !startWithDepartment || types.length
            ? formStyles.formBlock
            : clsx([formStyles.formBlock, formStyles.noFillField])
        }
      >
        <label htmlFor={name}>
          <span className={formStyles.error}>*</span>
          {label}:
        </label>

        <SelectWrapper
          propValue={itemValue}
          placeholder={ClassificationsInputPlaceholder}
          name={name}
          handleChange={handleChange}
          options={typeOptions()}
          disabled={isViewMode || (startWithDepartment && !types.length)}
          purchaseOrder={purchaseOrder}
        />
      </div>
    ),
    [
      handleChange,
      isViewMode,
      itemValue,
      label,
      name,
      purchaseOrder,
      startWithDepartment,
      typeOptions,
      types.length
    ]
  );
};
