import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWithTooltip } from '../../../../common/field-with-tooltip/FieldWithTooltip';
import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { PoFormLabel, PoFormField } from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const costPerItemTestId = 'CostPerItemComponent';

interface Props {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly disabled: boolean;
}

export const CostPerItem = (props: Props) => {
  const { purchaseOrder, disabled } = props;
  const { errors } = useFormContext();

  const errorType = errors.costPerItem?.type;

  return useMemo(
    () => (
      <div className={formStyles.iconField} data-testid={costPerItemTestId}>
        <FieldWithTooltip
          info="Please add cost per item value between 1 and 9,999.00"
          id="costPerItem"
          title={`${PoFormLabel.CostPerItem}:`}
          required={true}
          currency={true}
        >
          <Controller
            as={AutoNumeric}
            name={PoFormField.CostPerItem}
            defaultValue={purchaseOrder?.costPerItem || ''}
            placeholder="--"
            options={{
              minimumValue: 0,
              maximumValue: 9999.0,
              decimalPlaces: 2,
              restrictZeroFirstChar: true
            }}
            disabled={disabled}
            rules={{ required: true, min: 1 }}
            hasError={!!errorType}
            changeOnBlureOnly
          />
        </FieldWithTooltip>
      </div>
    ),
    [purchaseOrder, disabled, errorType]
  );
};
