import React, { useEffect } from 'react';

import { useGlobalContext } from '../../../../../context/global/GlobalContext';
import { useClassificationsDispatch } from './context/Provider';
import { ClassificationType } from '../../../../../domain/PurchaseOrder/ClassificationType';

export const InitialClassificationsSetter = () => {
  const dispatch = useClassificationsDispatch();

  const {
    classifications: { classificationsByDepts, classificationsByTypes }
  } = useGlobalContext();

  useEffect(() => {
    dispatch({
      type: ClassificationType.SET_INITIAL_DATA,
      payload: {
        departments: classificationsByDepts,
        types: classificationsByTypes
      }
    });
  }, [classificationsByDepts, classificationsByTypes, dispatch]);
  return <></>;
};
