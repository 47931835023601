import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './SearchPO.module.scss';

import { SearchBox } from './search-box/SearchBox';
import { SearchSelection } from './search-selection/SearchSelection';
import { Store } from '../../../domain/User/Store';
import { selectPOStoreAction } from '../../../context/global/po-store/po-store-actions';
import { useGlobalContext } from '../../../context/global/GlobalContext';

export const SearchPOTestId = 'SearchPOTestId';

export const SearchPO = () => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);

  const { poStores, dispatch } = useGlobalContext();

  const applySearch = () => {
    dispatch(selectPOStoreAction(selectedStores));
    setCollapsed(true);
  };

  const handleSelectionChange = (stores: Store[]) => {
    setSelectedStores(stores);
    if (isCollapsed) {
      dispatch(selectPOStoreAction(stores));
    }
  };

  useEffect(
    function initializeSelectedStores() {
      setSelectedStores(poStores || []);
    },
    [poStores]
  );

  return (
    <div className={styles.searchPO} data-testid={SearchPOTestId}>
      <label>
        <span className={styles.requiredIndicator}>*</span>
        Select Stores
      </label>

      <div
        className={clsx({
          [styles.searchWrap]: true,
          [styles.collapsed]: isCollapsed
        })}
      >
        <SearchBox
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          selectedStores={selectedStores}
          setSelectedStores={setSelectedStores}
          onApply={applySearch}
        />
        <SearchSelection
          isCollapsed={isCollapsed}
          selectedStores={selectedStores}
          onChange={handleSelectionChange}
        />
      </div>
    </div>
  );
};
