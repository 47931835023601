import { GenericObject } from '../../utils/GenericObject';
import { classificationsReducer } from './classifications/classificationsReducer';
import {
  snackBarInitialState,
  snackbarReducer,
  SnackbarState
} from './snackbar/snackbarReducer';
import { poStoreReducer } from './po-store/poStoreReducer';
import { SetClassificationsAction } from './classifications/classifications-actions';
import { SelectPOStoreAction } from './po-store/po-store-actions';
import { SnackbarAction } from './snackbar/snackbar-actions';
import { SelectFieldOption } from '../../components/common/select-field/SelectField';
import { SetPoSubTypesAction } from './po-sub-types/po-sub-types-actions';
import { poSubTypesReducer } from './po-sub-types/poSubTypeReducer';
import { Store } from '../../domain/User/Store';

export interface GlobalState {
  readonly classifications: GenericObject;
  readonly poSubTypes: SelectFieldOption[];
  readonly snackbar: SnackbarState;
  readonly poStores?: Store[];
}

export type GlobalContextAction =
  | SetClassificationsAction
  | SetPoSubTypesAction
  | SelectPOStoreAction
  | SnackbarAction;

export const initialState: GlobalState = {
  classifications: {},
  poSubTypes: [],
  snackbar: snackBarInitialState
};

export const globalReducer = (
  state: GlobalState,
  action: GlobalContextAction
): GlobalState => {
  const { classifications, poSubTypes, snackbar, poStores } = state;

  return {
    classifications: classificationsReducer(classifications, action),
    poSubTypes: poSubTypesReducer(poSubTypes, action),
    snackbar: snackbarReducer(snackbar, action),
    poStores: poStoreReducer(poStores, action)
  };
};
