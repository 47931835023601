import { GenericObject } from '../../../utils/GenericObject';
import { Action } from '../../Action';

export enum ClassificationsActionType {
  SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS'
}

export interface SetClassificationsAction extends Action {
  type: ClassificationsActionType.SET_CLASSIFICATIONS;
  payload: {
    classifications: GenericObject;
  };
}

export const setClassificationsAction = (
  classifications: GenericObject
): SetClassificationsAction => ({
  type: ClassificationsActionType.SET_CLASSIFICATIONS,
  payload: { classifications }
});
