import React from 'react';

import styles from './GenericMessage.module.scss';

export interface GenericMessageInterface {
  message: string;
}

export const genericMessageTestId = 'GenericMessageComponent';

export const GenericMessage = ({ message }: GenericMessageInterface) => (
  <div data-testid={genericMessageTestId}>
    <p className={styles.text}>{message}</p>
  </div>
);
