import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  SelectField,
  SelectFieldOption
} from '../../../../common/select-field/SelectField';
import { PoFormField, PoFormLabel } from '../po-form-config';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

import formStyles from '../PurchaseOrderForm.module.scss';
import { useUserPermissions } from '../../../../../context/user/PermissionsProvider';
import { NONE_OPTIONS } from '../../../../../constants/constants';

interface Props {
  readonly disabled: boolean;
  readonly subTypes: SelectFieldOption[];
}

export const poSubTypeTestId = 'poSubTypeTestId';

export const poSubTypePlaceholder = 'Please Select';

const poSubTypeSelectFieldName = `${PoFormField.PurchaseOrderSubType}Select`;

export const POSubType = (props: Props) => {
  const { subTypes, disabled } = props;

  const { register, setValue, watch } = useFormContext<
    PurchaseOrderFormValues
  >();
  const { poSubtypeRequired } = useUserPermissions();

  const poSubType = watch(PoFormField.PurchaseOrderSubType);
  const selectedPoSubType = subTypes.find(
    (o: SelectFieldOption) => o.value === poSubType
  );

  const handleChange = (selected: SelectFieldOption) => {
    setValue(PoFormField.PurchaseOrderSubType, selected.value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  return (
    <div data-testid={poSubTypeTestId}>
      <SelectField
        name={poSubTypeSelectFieldName}
        placeholder={poSubTypePlaceholder}
        options={poSubtypeRequired ? subTypes : [NONE_OPTIONS, ...subTypes]}
        disabled={disabled}
        onChange={handleChange}
        value={selectedPoSubType}
      >
        <label htmlFor={poSubTypeSelectFieldName}>
          <>
            {poSubtypeRequired && <span className={formStyles.error}>*</span>}
            {PoFormLabel.PurchaseOrderSubType}:
          </>
        </label>
      </SelectField>
      <input
        type="hidden"
        name={PoFormField.PurchaseOrderSubType}
        ref={register({ required: poSubtypeRequired })}
      />
    </div>
  );
};
