import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory, History } from 'history';

import { App } from './components/app/App';

import './index.scss';

window.renderManualPO = (containerId: string, history: History) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.render(<App history={history} />, elem);
  }
};

window.unmountManualPO = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    console.log('Public url', process.env.PUBLIC_URL);
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL
    });
    console.log('Public', defaultHistory,window);

    window.renderManualPO('root', defaultHistory);
  });
}
