import React, { forwardRef, Ref } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Button } from '../../../common/Button';
import { StaticContent } from '../../../../config/static-content';

export const headerTextTestId = 'headerTextComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      margin: `0 0 ${theme.typography.pxToRem(13)} ${theme.typography.pxToRem(
        -13
      )}`,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(29),
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginLeft: 0
      }
    },
    title: {
      marginLeft: theme.typography.pxToRem(5),
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0,
        marginRight: theme.typography.pxToRem(5)
      }
    }
  })
);

interface Props {
  title: string;
  isLgUp: boolean;
  handleDrawerClose: () => void;
}

const HeaderText = forwardRef((props: Props, ref: Ref<HTMLElement>) => {
  const { title, isLgUp, handleDrawerClose } = props;

  const classes = useStyles();

  return (
    <h3
      className={clsx({
        [classes.header]: true
      })}
      data-testid={headerTextTestId}
    >
      <Button square="50" onClick={handleDrawerClose}>
        {isLgUp ? <ArrowForwardIcon /> : <ArrowBackIcon />}
      </Button>
      <span ref={ref} className={classes.title}>
        {StaticContent.Help}: {title}
      </span>
    </h3>
  );
});

HeaderText.displayName = 'HeaderText';

export { HeaderText };
