import React, { ReactNode } from 'react';
import ClearIcon from '@material-ui/icons/Clear';

import styles from './SearchTag.module.scss';
import clsx from 'clsx';

interface Props {
  readonly className?: string;
  readonly removable?: boolean;
  readonly children?: ReactNode;
  readonly onRemove?: (event: any) => void;
}

export const removeTagBtnClass = 'remove-tag-btn';

export const SearchTag = ({
  children,
  removable = true,
  onRemove,
  className
}: Props) => {
  const handleRemoveClick = (event: any) => {
    onRemove && onRemove(event);
  };

  return (
    <div
      className={clsx(
        {
          [styles.searchTag]: true,
          [styles.removable]: removable
        },
        className
      )}
    >
      {children}
      {removable && (
        <span
          className={clsx([removeTagBtnClass, styles.closeBtn])}
          onClick={handleRemoveClick}
        >
          <ClearIcon fontSize="inherit" />
        </span>
      )}
    </div>
  );
};
