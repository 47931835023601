interface Element {
  name: string;
  id: string;
  [key: string]: any;
}

export const populateSelectByChild = (data: any[], child?: string) => {
  return (
    data &&
    data.map((element: Element) => {
      const { name, id } = element;

      return child
        ? {
            value: id,
            label: name || id,
            [child]: element[child]
          }
        : {
            value: id,
            label: name || id
          };
    })
  );
};
