import React from 'react';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';

import { useGlobalContext } from '../../../../../context/global/GlobalContext';
import { AutonumericInputWrapper } from '../AutonumericInputWrapper';
import { DeleteDraftPO } from '../../../purchase-order/purchase-order-form/delete-draft-po/DeleteDraftPo';
import { PurchaseOrdersRoute } from '../../../../app/routes/PurchaseOrdersRoute';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../../../../domain/PurchaseOrder/PurchaseOrder';
import {
  PoCanceller,
  CancelButtonProps,
  buttonTestId
} from '../../../purchase-order/purchase-order-form/PoCanceller';
import { Button } from '../../../../common/Button';

import styles from './PurchaseOrderCard.module.scss';

interface OwnProps {
  key: number;
  purchaseOrder: PurchaseOrder;
}

type Props = OwnProps & RouteComponentProps;

const PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL = 'Unspecified';
const PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE = 'N/A';

export const purchaseOrderCardTestId = 'purchaseOrderCardComponent';

const getDataById = (data: [], parentId: string): any[] | undefined =>
  data && data.filter(({ id }: { id: string }) => parentId === id);

// eslint-disable-next-line
export const PurchaseOrderCard = withRouter((props: Props) => {
  const { purchaseOrder, history } = props;

  const {
    classifications: { classificationsByDepts }
  } = useGlobalContext();

  const getDepartmentById: any = () =>
    getDataById(classificationsByDepts, purchaseOrder.department || '');

  const getSubDepartmentById: any = () => {
    if (getDepartmentById() && getDepartmentById().length > 0) {
      return getDataById(
        getDepartmentById()[0].subDepartments,
        purchaseOrder.subDepartment || ''
      );
    }
  };

  const getBracketById: any = () => {
    if (getSubDepartmentById() && getSubDepartmentById().length > 0) {
      return getDataById(
        getSubDepartmentById()[0].brackets,
        purchaseOrder.bracket || ''
      );
    }
  };

  const onClickPOCard = (event: any, draftId: string) => {
    let hasDialogClass = false;

    event.target.classList.forEach((className: string) => {
      if (className.toLowerCase().includes('dialog')) {
        hasDialogClass = true;
      }
    });

    if (!hasDialogClass) {
      history.push(`${PurchaseOrdersRoute.Index}/${draftId}`);
    } else {
      return null;
    }
  };

  const storeNum = get(purchaseOrder, 'allocations[0].storeNum');
  const isDraft = purchaseOrder.poStatus === PoStatus.DRAFT;
  const isSubmitted = purchaseOrder.poStatus === PoStatus.SUBMITTED;

  return (
    <div
      className={styles.cardContainer}
      data-testid={purchaseOrderCardTestId}
      onClick={e => {
        if (purchaseOrder.id) {
          onClickPOCard(e, purchaseOrder.id);
        }
      }}
    >
      <div className={styles.card}>
        <div className={styles.cardTitlePO}>
          {purchaseOrder.id && <span>{`PO #${purchaseOrder.id}`}</span>}
          {purchaseOrder.id && isDraft && (
            <DeleteDraftPO draftId={purchaseOrder.id} />
          )}
          {purchaseOrder.id && isSubmitted && (
            <PoCanceller
              poId={purchaseOrder.id}
              redirectPath={PurchaseOrdersRoute.Submitted}
              cancelButton={({ onClick }: CancelButtonProps) => (
                <Button
                  data-testid={buttonTestId}
                  variant="text"
                  color="inherit"
                  kind="link"
                  disabled={purchaseOrder.isCanceled}
                  onClick={onClick}
                >
                  {purchaseOrder.isCanceled ? 'Cancelled PO' : 'Cancel PO'}
                </Button>
              )}
            />
          )}
        </div>
        <div className={styles.cardContentPO}>
          <div className={styles.cardContentPOHeader}>
            <h2
              className={clsx(
                purchaseOrder.brand ? styles.value : styles.noValue,
                styles.brand
              )}
            >
              {purchaseOrder.brand
                ? purchaseOrder.brand
                : 'No Brand Provided Yet'}
            </h2>
            <p
              className={
                purchaseOrder.description
                  ? clsx(styles.value, styles.description)
                  : clsx(styles.noValue, styles.description)
              }
            >
              {purchaseOrder.description
                ? purchaseOrder.description
                : 'No Description Provided Yet'}
            </p>
          </div>
          <hr className={styles.cardSeparator} />
          <div className={styles.cardContentPOInner}>
            <Grid container spacing={2}>
              <Grid item lg={6} xl={4}>
                <h6>
                  <span className={styles.type}>Supplier:</span>
                  <Hidden lgUp>
                    <span
                      className={
                        purchaseOrder.supplier ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.supplier ? purchaseOrder.supplier : '--'}
                    </span>
                  </Hidden>
                  <Hidden mdDown>
                    <span
                      className={
                        purchaseOrder.supplier ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.supplier
                        ? purchaseOrder.supplier
                        : PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}
                    </span>
                  </Hidden>
                </h6>
                <Hidden mdDown>
                  <h6>
                    <span className={styles.type}>Condition:</span>
                    <span
                      className={
                        purchaseOrder.itemCondition
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.itemCondition
                        ? purchaseOrder.itemCondition
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`}
                    </span>
                  </h6>
                </Hidden>
                <h6>
                  <span className={styles.type}>Model:</span>

                  <Hidden lgUp>
                    <span
                      className={
                        purchaseOrder.model ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.model ? purchaseOrder.model : '--'}
                    </span>
                  </Hidden>
                  <Hidden mdDown>
                    <span
                      className={
                        purchaseOrder.model ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.model
                        ? purchaseOrder.model
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`}
                    </span>
                  </Hidden>
                </h6>
                <div className={styles.divideMobile}>
                  <Hidden lgUp>
                    <h6>
                      <span className={styles.type}>Cost per item:</span>

                      <Hidden lgUp>
                        <span
                          className={
                            purchaseOrder.costPerItem
                              ? styles.value
                              : styles.noValue
                          }
                        >
                          ${' '}
                          {purchaseOrder.costPerItem ? (
                            <AutonumericInputWrapper
                              defaultValue={`${purchaseOrder.costPerItem}`}
                              name="costPerItem"
                              value={`${purchaseOrder.costPerItem}`}
                              readOnly={true}
                              disabled={true}
                            />
                          ) : (
                            '--'
                          )}
                        </span>
                      </Hidden>
                      <Hidden mdDown>
                        <span
                          className={
                            purchaseOrder.costPerItem
                              ? styles.value
                              : styles.noValue
                          }
                        >
                          ${' '}
                          {purchaseOrder.costPerItem ? (
                            <AutonumericInputWrapper
                              defaultValue={`${purchaseOrder.costPerItem}`}
                              name="costPerItem"
                              value={`${purchaseOrder.costPerItem}`}
                              readOnly={true}
                              disabled={true}
                            />
                          ) : (
                            ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`
                          )}
                        </span>
                      </Hidden>
                    </h6>
                  </Hidden>

                  <h6>
                    <span className={clsx(styles.type, styles.qty)}>
                      <Hidden lgUp>Qty:</Hidden>
                      <Hidden mdDown>Quantity:</Hidden>
                    </span>
                    <span
                      className={
                        purchaseOrder.totalQuantity
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.totalQuantity ? (
                        <AutonumericInputWrapper
                          defaultValue={`${purchaseOrder.totalQuantity}`}
                          name="quantity"
                          readOnly={true}
                          disabled={true}
                          decimalPlaces={0}
                        />
                      ) : (
                        <>
                          <Hidden lgUp>{'--'}</Hidden>
                          <Hidden
                            mdDown
                          >{` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`}</Hidden>
                        </>
                      )}
                    </span>
                  </h6>
                </div>
                <Hidden lgUp>
                  {isSubmitted && (
                    <h6>
                      <span className={styles.type}>Store number:</span>
                      <span
                        className={storeNum ? styles.value : styles.noValue}
                      >
                        {storeNum
                          ? storeNum
                          : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`}
                      </span>
                    </h6>
                  )}
                </Hidden>
              </Grid>
              <Hidden mdDown>
                <Grid item lg={6} xl={4}>
                  <h6>
                    <span className={styles.type}>Department:</span>
                    <span
                      className={
                        purchaseOrder && purchaseOrder.department
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.department
                        ? classificationsByDepts &&
                          getDepartmentById().length > 0 &&
                          getDepartmentById()[0].name
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`}
                    </span>
                  </h6>
                  <h6>
                    <span className={styles.type}>Subdepartment:</span>
                    <span
                      className={
                        purchaseOrder && purchaseOrder.subDepartment
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.subDepartment
                        ? getSubDepartmentById() &&
                          getSubDepartmentById().length > 0 &&
                          getSubDepartmentById()[0].name
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`}
                    </span>
                  </h6>

                  <h6>
                    <span className={styles.type}>Bracket:</span>
                    <span
                      className={
                        purchaseOrder.bracket ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.bracket
                        ? getBracketById() &&
                          getBracketById().length > 0 &&
                          getBracketById()[0].name
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}`}
                    </span>
                  </h6>
                  <h6>
                    <span className={styles.type}>Supplier order number:</span>
                    <span
                      className={
                        purchaseOrder.supplierOrderNo
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.supplierOrderNo
                        ? purchaseOrder.supplierOrderNo
                        : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`}
                    </span>
                  </h6>
                </Grid>
                <Grid item lg={6} xl={4}>
                  <h6>
                    <span className={styles.type}>Cost per item:</span>
                    <span
                      className={
                        purchaseOrder.costPerItem
                          ? styles.value
                          : styles.noValue
                      }
                    >
                      {purchaseOrder.costPerItem ? (
                        <AutonumericInputWrapper
                          defaultValue={`${purchaseOrder.costPerItem}`}
                          name="costPerItem"
                          readOnly={true}
                          disabled={true}
                          currencySymbol="$"
                        />
                      ) : (
                        ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`
                      )}
                    </span>
                  </h6>

                  <h6>
                    <span className={styles.type}>Total Cost:</span>
                    <span
                      className={
                        purchaseOrder.totalCost ? styles.value : styles.noValue
                      }
                    >
                      {purchaseOrder.totalCost ? (
                        <AutonumericInputWrapper
                          defaultValue={`${purchaseOrder.totalCost}`}
                          name="totalCost"
                          readOnly={true}
                          disabled={true}
                          currencySymbol="$"
                        />
                      ) : (
                        ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`
                      )}
                    </span>
                  </h6>
                  {isSubmitted && (
                    <h6>
                      <span className={styles.type}>Store number:</span>
                      <span
                        className={storeNum ? styles.value : styles.noValue}
                      >
                        {storeNum
                          ? storeNum
                          : ` ${PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE}`}
                      </span>
                    </h6>
                  )}
                </Grid>
              </Hidden>
            </Grid>
          </div>
        </div>
        <div className={styles.cardFooterPO}>
          <span>{isDraft ? 'Last Modified On:' : 'Submitted On:'} </span>
          <span className={styles.textFooterBold}>
            <strong>
              {new Date(purchaseOrder.updatedAt || '').toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                }
              )}
            </strong>{' '}
            by{' '}
            <strong>{`${purchaseOrder.lastModifiedBy?.firstName ||
              '--'}  ${purchaseOrder.lastModifiedBy?.lastName ||
              '--'} `}</strong>
          </span>
        </div>
      </div>
    </div>
  );
});
