import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import { deleteDraftPurchaseOrder } from '../../../../../context/purchase-orders/single/purchase-order-thunks';
import { AlertDialog } from '../../../../common/alert-dialog/AlertDialog';
import { Button } from '../../../../common/Button';
import { PurchaseOrdersRoute } from '../../../../app/routes/PurchaseOrdersRoute';
import useRefresh from '../../../../app/routes/useRefresh';

interface OwnProps {
  draftId: string;
  onDeleteConfirmationPopupStatusChange?: (isOpen: boolean) => void;
  onDeleteFinished?: () => void;
  isDisabled?: boolean;
}

type Props = OwnProps & RouteComponentProps;

export const DeleteDraftPO = withRouter((props: Props) => {
  const {
    draftId,
    onDeleteConfirmationPopupStatusChange,
    onDeleteFinished,
    history,
    isDisabled
  } = props;

  const [
    isDeleteConfirmationPopupOpen,
    setDeleteConfirmationPopupStatus
  ] = useState(false);

  const refresh = useRefresh();

  const openDeleteConfirmationPopup = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();

    if (!isDisabled) {
      setDeleteConfirmationPopupStatus(true);

      if (onDeleteConfirmationPopupStatusChange) {
        onDeleteConfirmationPopupStatusChange(true);
      }
    }
  };

  const closeDeleteConfirmationPopup = () => {
    setDeleteConfirmationPopupStatus(false);

    if (onDeleteConfirmationPopupStatusChange) {
      onDeleteConfirmationPopupStatusChange(false);
    }
  };

  const handleDeleteSuccess = () => {
    if (onDeleteFinished) {
      onDeleteFinished();
    }

    closeDeleteConfirmationPopup();
    if (history.location.pathname === PurchaseOrdersRoute.Draft) {
      refresh(PurchaseOrdersRoute.Draft);
    } else {
      history.push(PurchaseOrdersRoute.Draft);
    }
  };

  const { run, isPending } = useAsync({
    deferFn: (draftId: string[]) =>
      deleteDraftPurchaseOrder(draftId.shift() || ''),
    onResolve: handleDeleteSuccess
  });

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const { currentTarget } = event;

    if (currentTarget.dataset.cancel) {
      if (!isPending) {
        run(draftId);
      }
    } else {
      closeDeleteConfirmationPopup();
    }
  };

  return (
    <>
      <Button
        color="inherit"
        size="small"
        square="30"
        onClick={openDeleteConfirmationPopup}
        data-testid="deleteDraftPO"
      >
        <DeleteIcon />
      </Button>
      <AlertDialog
        open={isDeleteConfirmationPopupOpen}
        onClose={handleClose}
        title="Delete PO?"
        text="By deleting the PO you will permanently remove it from the application."
        note="This step cannot be undone."
        borderColor="#ffcd00"
        btnsConfig={[
          { label: 'No', variant: 'outlined' },
          { label: 'Yes, delete', variant: 'contained', dataCancel: 'delete' }
        ]}
      />
    </>
  );
});
