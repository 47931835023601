import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';

import { SelectWrapper } from './select-wrapper/SelectWrapper';
import { PoFormLabel } from './po-form-config';

import formStyles from './PurchaseOrderForm.module.scss';
import { PurchaseOrderFormValues } from './PurchaseOrderFormValues';

export interface InterfaceProps {
  purchaseOrder: PurchaseOrderFormValues | null;
  disabled?: boolean;
  isPurchaseOrderPending?: boolean;
}

export const conditionTestId = 'conditionComponent';

export const Condition = (props: InterfaceProps) => {
  const { purchaseOrder, disabled } = props;
  const { setValue } = useFormContext();
  const [condition, setCondition] = useState({
    value: '',
    label: 'Specify condition'
  });

  useEffect(() => {
    if (purchaseOrder && purchaseOrder.itemCondition) {
      setCondition({
        value: purchaseOrder.itemCondition,
        label: purchaseOrder.itemCondition
      });
    }
  }, [purchaseOrder, setValue]);

  const onConditionChanged = ({
    value,
    label
  }: {
    value: string;
    label: string;
  }) => {
    setCondition({ value, label });
    setValue('itemCondition', value, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  return (
    <div data-testid={conditionTestId}>
      <Box mb="0.5rem">
        <label htmlFor="itemCondition">
          <span className={formStyles.error}>*</span>
          {PoFormLabel.Condition}:
        </label>
        <SelectWrapper
          propValue={condition}
          className="selectItem"
          name="itemCondition"
          placeholder="Specify condition"
          handleChange={onConditionChanged}
          options={[
            { value: 'New', label: 'New' },
            { value: 'Refurbished', label: 'Refurbished' }
          ]}
          disabled={disabled}
          purchaseOrder={purchaseOrder}
        />
      </Box>
    </div>
  );
};
