import React, { useCallback } from 'react';
import { ArrayField, useFieldArray, useWatch } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { createFormAllocation, FormAllocation } from './FormAllocation';
import { AllocationItem } from './allocations-item/AllocationItem';
import { Button } from '../../../../../common/Button';
import { Store, createStoreOptions } from '../../../../../../domain/User/Store';
import { SelectFieldOption } from '../../../../../common/select-field/SelectField';

export const allocationsListTestId = 'allocationsListComponent';

export interface Props {
  stores: Store[];
  disabled: boolean;
  isSearchLoading?: boolean;
  onSearch?: (text: string) => void;
}

export const AllocationsList = (props: Props) => {
  const { stores, disabled, isSearchLoading, onSearch } = props;

  const { fields, append, remove } = useFieldArray<FormAllocation>({
    name: 'allocations'
  });

  const addStoreAllocation = useCallback(() => {
    append(createFormAllocation());
  }, [append]);

  const removeStoreAllocation = useCallback(
    (index: number) => {
      if (index > 0) {
        remove(index);
      }
    },
    [remove]
  );

  const allAllocations = useWatch({ name: 'allocations' });

  const selectStoreOptions = createStoreOptions(stores).filter(
    (fieldOption: SelectFieldOption) =>
      !allAllocations.find(
        (a: FormAllocation) => a.storeNum === fieldOption.value
      )
  );

  const isMultiStoreDisabled = selectStoreOptions.length === 0;

  return (
    <div data-testid={allocationsListTestId}>
      {fields.map(
        (field: Partial<ArrayField<FormAllocation>>, index: number) => (
          <AllocationItem
            key={field.id}
            index={index}
            allocationField={field}
            stores={stores}
            onRemoveButtonClick={removeStoreAllocation}
            disabled={disabled}
            selectStoreOptions={selectStoreOptions}
            isSearchable
            onSearch={onSearch}
            isStoreLoading={isSearchLoading}
          />
        )
      )}

      <Box mb="1.5rem">
        <Button
          isDashed
          type="button"
          variant="outlined"
          color="primary"
          fullWidth={true}
          disabled={disabled || isMultiStoreDisabled}
          onClick={addStoreAllocation}
        >
          ADD STORE
        </Button>
      </Box>
    </div>
  );
};
