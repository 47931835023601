import {
  createFormAllocation,
  FormAllocation
} from './allocations/allocations-list/FormAllocation';
import { Store } from '../../../../domain/User/Store';
import { PurchaseOrderFormValues } from './PurchaseOrderFormValues';
import { createFormPricing } from './pricing/pricing-list/FormPricing';

export const generateFormDefaults = (
  stores: Store[],
  purchaseOrder?: PurchaseOrderFormValues
): Partial<PurchaseOrderFormValues> => {
  const serverAllocations = purchaseOrder?.allocations.map(
    (a: FormAllocation) => createFormAllocation({ ...a, isDefault: true })
  );

  const clientAllocations = [
    stores.length === 1
      ? createFormAllocation({
          storeNum: stores[0].storeNum,
          state: stores[0].state.abbreviation,
          isDefault: true
        })
      : createFormAllocation({ isDefault: true })
  ];

  const pricing =
    purchaseOrder?.pricing ||
    (stores.length === 1
      ? [createFormPricing({ state: stores[0].state.abbreviation })]
      : []);

  return {
    purchaseOrderSubType: purchaseOrder?.purchaseOrderSubType,
    referenceNumber: purchaseOrder?.referenceNumber,
    poStatus: purchaseOrder?.poStatus,
    allocations: serverAllocations || clientAllocations,
    totalQuantity: purchaseOrder?.totalQuantity,
    totalCost: purchaseOrder?.totalCost,
    costPerItem: purchaseOrder?.costPerItem,
    pricing
  };
};
