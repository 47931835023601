import React, { PropsWithChildren } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Colors } from '../../config/style-config';

export const tooltipItemTestId = 'TooltipItemComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: Colors.BLACK_MEDIUM,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(8)}`,
      margin: `${theme.typography.pxToRem(9)} 0`,
      borderRadius: 0
    },
    popperArrow: {
      '&[x-placement*="bottom"] $arrow': {
        margin: `${theme.typography.pxToRem(-4)} 0 0`,
        left: theme.typography.pxToRem(16),
        width: theme.typography.pxToRem(8),
        height: theme.typography.pxToRem(4),
        '&:before': {
          borderWidth: `0 ${theme.typography.pxToRem(
            4
          )} ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)}`
        }
      }
    },
    arrow: {
      color: Colors.BLACK_MEDIUM
    }
  })
);

export const TooltipItem = (props: PropsWithChildren<TooltipProps>) => {
  const classes = useStyles();

  return (
    <Tooltip
      data-testid={tooltipItemTestId}
      classes={{
        popperArrow: classes.popperArrow,
        arrow: classes.arrow,
        tooltip: classes.tooltip
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              fn: (data: {}) => data
            }
          }
        }
      }}
      {...props}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};
