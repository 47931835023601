import { Store } from '../../../domain/User/Store';
import { Action } from '../../Action';

export enum POStoreActionType {
  SELECT_PO_STORE = 'SELECT_PO_STORE'
}

export interface SelectPOStoreAction extends Action {
  readonly type: POStoreActionType.SELECT_PO_STORE;
  readonly payload: {
    readonly poStores: Store[];
  };
}

export const selectPOStoreAction = (
  poStores: Store[]
): SelectPOStoreAction => ({
  type: POStoreActionType.SELECT_PO_STORE,
  payload: { poStores }
});
