import React from 'react';
import { chain } from 'lodash';

import { State } from '../../../../../domain/User/State';
import { LegalEngineResponseItem } from '../../../../../domain/Legal/LegalEngineResponseItem';
import { getStoreByNumber, Store } from '../../../../../domain/User/Store';

import styles from './IllegalValueBox.module.scss';
import { Button } from '@material-ui/core';

export interface IllegalStateInfo {
  readonly state: State;
  readonly adjustedTerm: number;
  readonly totalCostLimit: number;
}

interface IllegalValueBoxProps {
  readonly illegalResponse: LegalEngineResponseItem[];
  readonly stores: Store[];
  readonly onOk: (illegalStatesInfo: IllegalStateInfo[]) => void;
  readonly poNumber?: string;
}

export const illegalValueBoxTestId = 'illegalValueBoxComponent';

export const IllegalValueBox = ({
  illegalResponse,
  stores,
  onOk,
  poNumber
}: IllegalValueBoxProps) => {
  const states = illegalResponse.map(
    (item: LegalEngineResponseItem) =>
      ({
        adjustedTerm: item.adjustedTerm,
        totalCostLimit: item.totalCostLimit,
        state: getStoreByNumber(stores, item.storeNum)?.state
      } as IllegalStateInfo)
  );

  const uniqueStatesWithIllegalValues: IllegalStateInfo[] = chain(states)
    .uniqBy((item: IllegalStateInfo) => item.state.name)
    .value();

  const handleOkPress = () => {
    onOk(uniqueStatesWithIllegalValues);
  };

  return (
    <div className={styles.termUpdatedBox} data-testid={illegalValueBoxTestId}>
      <h2>Weekly Term Updated</h2>

      {uniqueStatesWithIllegalValues.map(
        ({ state, adjustedTerm, totalCostLimit }: IllegalStateInfo) => (
          <p key={state?.name}>
            <div>
              The current pricing is outside of the <b>{state?.name}</b> state
              legal limits.
            </div>
            {totalCostLimit && (
              <div>
                The maximum state limit in {state?.name} for <b>Weekly term</b>{' '}
                is: <b>{adjustedTerm}</b>
              </div>
            )}
          </p>
        )
      )}

      {poNumber ? (
        <p>PO#{poNumber} has been updated.</p>
      ) : (
        <p>Your PO has been updated.</p>
      )}
      <div>
        <Button variant="contained" color="primary" onClick={handleOkPress}>
          Ok
        </Button>
      </div>
    </div>
  );
};
