import React from 'react';
import { CircularProgress } from '@material-ui/core';

import classes from './LoadingIndicator.module.scss';

const loadingDataTestId = 'pricingLoadingTestId';

export const LoadingIndicator = () => {
  return (
    <div className={classes.loadingIndicator} data-testid={loadingDataTestId}>
      <CircularProgress size={22} className={classes.spinner} />
      <div>Fetching prices ...</div>
    </div>
  );
};
