import { SnackbarMessageType } from '../SnackbarMessageType';

export interface GenericMessage {
  readonly type: SnackbarMessageType.GENERIC_MESSAGE;
  readonly message: string;
}

export const createGenericMessage = (message: string): GenericMessage => ({
  type: SnackbarMessageType.GENERIC_MESSAGE,
  message
});
