import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import { getStores } from '../../api/users';
import { Store } from '../../domain/User/Store';
import {
  ApiAction,
  getErrorAction,
  getLoadingAction,
  getSuccessAction
} from '../api-actions';

export const getStoresThunk = async (
  dispatch: Dispatch<ApiAction>,
  cancelToken: CancelToken,
  term?: string
) => {
  try {
    dispatch(getLoadingAction());
    const stores: Store[] = await getStores(term, cancelToken);
    dispatch(getSuccessAction(stores));
  } catch (error) {
    if (!error.__CANCEL__) {
      dispatch(getErrorAction(error));
    }
  }
};
