import React from 'react';

import { PanelTitle } from '../../screens/purchase-order/purchase-order-form/po-form-config';
import { ItemDetailsText } from './texts/ItemDetailsText';
import { InventoryDetailsText } from './texts/InventoryDetailsText';
import { PricingText } from './texts/PricingText';
import { AllocationsText } from './texts/AllocationsText';

export const helpComponentTestId = 'helpComponentComponent';

interface Props {
  title: string;
}

export const Text = (props: Props) => {
  const { title } = props;

  switch (title) {
    case PanelTitle.ItemDetails:
      return <ItemDetailsText />;
    case PanelTitle.InventoryDetails:
      return <InventoryDetailsText />;
    case PanelTitle.Pricing:
      return <PricingText />;
    case PanelTitle.Allocations:
      return <AllocationsText />;
    default:
      return null;
  }
};
