import { Action } from '../../Action';
import { PoStatus } from '../../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';

export enum DraftPosActionType {
  GET_DRAFT_POS = 'GET_DRAFT_POS',
  GET_DRAFT_POS_SUCCESS = 'GET_DRAFT_POS_SUCCESS',
  GET_DRAFT_POS_ERROR = 'GET_DRAFT_POS_ERROR'
}

export interface GetDraftPosAction extends Action {
  type: DraftPosActionType.GET_DRAFT_POS;
  payload: { poStatus: PoStatus };
}

export interface GetDraftPosSuccessAction extends Action {
  type: DraftPosActionType.GET_DRAFT_POS_SUCCESS;
  payload: {
    purchaseOrders: PurchaseOrder[];
  };
}

export interface GetDraftPosErrorAction extends Action {
  type: DraftPosActionType.GET_DRAFT_POS_ERROR;
}

export type DraftPosAction =
  | GetDraftPosAction
  | GetDraftPosSuccessAction
  | GetDraftPosErrorAction;

export const getDraftPosAction = (): DraftPosAction => ({
  type: DraftPosActionType.GET_DRAFT_POS,
  payload: {
    poStatus: PoStatus.DRAFT
  }
});

export const getDraftPosSuccessAction = (
  purchaseOrders: PurchaseOrder[]
): GetDraftPosSuccessAction => ({
  type: DraftPosActionType.GET_DRAFT_POS_SUCCESS,
  payload: { purchaseOrders }
});

export const getDraftPosErrorAction = (): GetDraftPosErrorAction => ({
  type: DraftPosActionType.GET_DRAFT_POS_ERROR
});
