import React, { ReactChild } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import styles from './FieldWithTooltip.module.scss';

export const FieldWithTooltip = (props: {
  title?: string;
  errors?: string;
  info: string;
  children: ReactChild;
  id?: string;
  required?: boolean;
  currency?: boolean;
}) => {
  return (
    <label htmlFor={props.id}>
      <span>
        {props.required && <span className={styles.error}>*</span>}
        {props.title}
      </span>
      <div className={styles.fieldWithTooltipWrapper}>
        {props.currency && <span className={styles.currency}>$</span>}
        {props.children}
        <div className={styles.tooltip}>
          <HelpOutlineIcon />
          <span className={styles.tooltiptext}>{props.info}</span>
        </div>
      </div>
    </label>
  );
};
