import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { PurchaseOrderForm } from './purchase-order-form/PurchaseOrderForm';
import { getPurchaseOrderById } from '../../../api/purchase-orders';
import { PurchaseOrderFormSkeleton } from './PurchaseOrderFormSkeleton';
import { PurchaseOrdersRoute } from '../../app/routes/PurchaseOrdersRoute';
import { PurchaseOrder as PurchaseOrderModel } from '../../../domain/PurchaseOrder/PurchaseOrder';
import { toPurchaseOrderFormValues } from './purchase-order-form/purchase-order-form-mappers';
import { Store } from '../../../domain/User/Store';
import { CancelTokenSource } from 'axios';
import { generateCancelToken } from '../../../api/client';
import { getStores } from '../../../api/users';

export type Props = RouteComponentProps<{ purchaseOrderId: string }, {}>;

// eslint-disable-next-line
export const PurchaseOrder = (props: Props) => {
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModel | null>(
    null
  );
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [purchaseOrderNotFound, setPurchaseOrderNotFound] = useState(false);
  const [apiError, setApiError] = useState<Error | null>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [searchStoreResult, setSearchStoreResult] = useState<Store[]>([]);

  const {
    match: {
      params: { purchaseOrderId }
    },
    location: { pathname }
  } = props;

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        const purchaseOrder = await getPurchaseOrderById(purchaseOrderId);
        purchaseOrder
          ? setPurchaseOrder(purchaseOrder)
          : setPurchaseOrderNotFound(true);
      } catch (error) {
        setIsSaveDraftDisabled(false);
        setIsSubmitDisabled(false);
        setApiError(error);
      }
    };

    if (purchaseOrderId) {
      fetchPurchaseOrder();
    }
  }, [purchaseOrderId]);

  useEffect(() => {
    if (pathname === PurchaseOrdersRoute.Create) {
      setPurchaseOrder(null);
    }
  }, [pathname]);

  useEffect(() => {
    async function fetchStores() {
      try {
        const cancelToken: CancelTokenSource = generateCancelToken();

        if (searchTerm.length >= 3 || searchTerm === '') {
          setIsStoreLoading(true);
          const fetchedStores: Store[] = await getStores(
            searchTerm,
            cancelToken.token
          );
          setSearchStoreResult(fetchedStores as Store[]);
        }
      } catch {
        setSearchStoreResult([]);
      } finally {
        setIsStoreLoading(false);
      }
    }
    fetchStores();
  }, [searchTerm]);

  return (
    <>
      {purchaseOrderNotFound && <Redirect to="/not-found" />}
      {apiError && <Redirect to={PurchaseOrdersRoute.Index} />}
      {!purchaseOrderId || purchaseOrder ? (
        <PurchaseOrderForm
          purchaseOrderId={purchaseOrderId}
          purchaseOrder={
            purchaseOrder && toPurchaseOrderFormValues(purchaseOrder)
          }
          stores={searchStoreResult}
          isSaveDraftDisabled={isSaveDraftDisabled}
          setIsSaveDraftDisabled={setIsSaveDraftDisabled}
          isSubmitDisabled={isSubmitDisabled}
          setIsSubmitDisabled={setIsSubmitDisabled}
          onSearch={setSearchTerm}
          isSearchLoading={isStoreLoading}
          {...props}
        />
      ) : (
        <PurchaseOrderFormSkeleton />
      )}
    </>
  );
};
