import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { StaticContent } from '../../../../config/static-content';

export const staticContentTestId = 'staticContentComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      "&[href^='tel:']": {
        textDecoration: 'none'
      }
    }
  })
);

export const StaticText = () => {
  const classes = useStyles();

  return (
    <p data-testid={staticContentTestId}>
      {StaticContent.Support}
      <a className={classes.link} href={`tel:${StaticContent.Phone}`}>
        {StaticContent.Phone}
      </a>
      .
    </p>
  );
};
