import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { calculateValue } from '../../../../../utils/helper-functions';
import { PricingCalculator } from '../../../../../utils/PricingCalculator';
import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { PoFormLabel, PoFormField } from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';

export const totalCostTestId = 'TotalCostComponent';

export const TotalCost = () => {
  const totalQuantity = useWatch({ name: 'totalQuantity' });
  const costPerItem = useWatch({ name: 'costPerItem' });

  const totalCost =
    parseInt(totalQuantity, 10) >= 1 && parseInt(costPerItem, 10) >= 1
      ? calculateValue(PricingCalculator.totalCost, totalQuantity, costPerItem)
      : '';

  return useMemo(
    () => (
      <div data-testid={totalCostTestId}>
        <label htmlFor="totalCost">{PoFormLabel.TotalCost}:</label>
        <div className={clsx(formStyles.iconField, formStyles.noFillField)}>
          <span>$</span>
          <Controller
            as={AutoNumeric}
            name={PoFormField.TotalCost}
            defaultValue=""
            imperativeMode
            imperativeValue={totalCost}
            options={{
              decimalPlaces: 2
            }}
            placeholder="N/A"
            disabled
            rules={{ required: true }}
          />
        </div>
      </div>
    ),
    [totalCost]
  );
};
