import React, { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { get } from 'lodash';
import { FieldErrors } from 'react-hook-form/dist/types/form';

import { calculateTotalQuantity } from './allocations-list/FormAllocation';
import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { PoFormLabel, PoFormField } from '../po-form-config';
import { TooltipItem } from '../../../../common/TooltipItem';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const totalQuantityTestId = 'TotalQuantityComponent';

export const hasMaxQuantityError = (
  errors: FieldErrors<PurchaseOrderFormValues>
) => get(errors, `${PoFormField.TotalQuantity}.type`) === 'max';

const maxQuantityErrorMessage =
  'The maximum number of items is 9,999. Please adjust your input to not exceed this' +
  ' value';

export const TotalQuantity = () => {
  const { errors } = useFormContext<PurchaseOrderFormValues>();

  const allocations = useWatch({ name: 'allocations' });
  const totalQuantity = calculateTotalQuantity(allocations);

  const hasError = hasMaxQuantityError(errors);

  return useMemo(
    () => (
      <div data-testid={totalQuantityTestId}>
        <TooltipItem
          title={hasError ? maxQuantityErrorMessage : ''}
          arrow
          placement="top-start"
          TransitionProps={{
            exit: false
          }}
        >
          <>
            <label htmlFor={PoFormField.TotalQuantity}>
              {PoFormLabel.TotalQuantity}:
            </label>
            <Controller
              as={AutoNumeric}
              name={PoFormField.TotalQuantity}
              defaultValue=""
              imperativeMode
              imperativeValue={totalQuantity}
              placeholder="N/A"
              options={{
                decimalPlaces: 0
              }}
              hasError={hasError}
              disabled
              rules={{
                required: true,
                max: 9999
              }}
            />
          </>
        </TooltipItem>
      </div>
    ),
    [totalQuantity, hasError]
  );
};
