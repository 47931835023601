import React, { useCallback, useMemo } from 'react';
import { Delete } from '@material-ui/icons';
import { ArrayField, Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';

import {
  SelectField,
  SelectFieldOption
} from '../../../../../../common/select-field/SelectField';
import { FieldWithTooltip } from '../../../../../../common/field-with-tooltip/FieldWithTooltip';
import { TooltipItem } from '../../../../../../common/TooltipItem';
import { Button } from '../../../../../../common/Button';
import {
  createStoreOption,
  getStoreByNumber,
  Store
} from '../../../../../../../domain/User/Store';
import { FormAllocation } from '../FormAllocation';
import { AutoNumeric } from '../../../../../../common/auto-numeric/AutoNumeric';
import { PoFormLabel } from '../../../po-form-config';
import { GenericObject } from '../../../../../../../utils/GenericObject';

import formStyles from '../../../PurchaseOrderForm.module.scss';
import style from './AllocationItem.module.scss';

export const allocationItemTestId = 'AllocationItemComponet';

interface Props {
  readonly index: number;
  readonly allocationField: Partial<ArrayField<FormAllocation>>;
  readonly onRemoveButtonClick: (index: number) => void;
  readonly disabled: boolean;
  readonly stores: Store[];
  readonly selectStoreOptions: SelectFieldOption[];
  readonly isSearchable?: boolean;
  readonly isStoreLoading?: boolean;
  readonly onSearch?: (text: string) => void;
}

const HiddenField = (props: GenericObject) => <input {...props} />;

export const AllocationItem = (props: Props) => {
  const {
    index,
    disabled,
    onRemoveButtonClick,
    allocationField,
    stores,
    selectStoreOptions,
    onSearch,
    isSearchable,
    isStoreLoading
  } = props;

  const { setValue, errors } = useFormContext();

  let selectedStore: SelectFieldOption | undefined;

  if (allocationField.storeNum) {
    const foundStore = getStoreByNumber(stores, allocationField.storeNum);

    if (foundStore) {
      selectedStore = createStoreOption(foundStore);
    }
  }

  const setStoreState = useCallback(
    (storeNum: string) => {
      const store: Store | undefined = getStoreByNumber(stores, storeNum);

      if (store) {
        setValue(`allocations[${index}].state`, store.state.abbreviation, {
          shouldValidate: true,
          shouldDirty: true
        });
      }
    },
    [index, stores, setValue]
  );

  const setStoreNumber = useCallback(
    (storeNum: string) => {
      setValue(`allocations[${index}].storeNum`, storeNum, {
        shouldValidate: true,
        shouldDirty: true
      });
    },
    [index, setValue]
  );

  const quantityErrorType = get(errors, `allocations[${index}].quantity.type`);
  const storeNumErrorType = get(errors, `allocations[${index}].storeNum.type`);

  return useMemo(() => {
    return (
      <div className={style.wrapper} data-testid={allocationItemTestId}>
        <FieldWithTooltip
          info="Please add quantity value between 1 and 9,999"
          id={`allocations[${index}].quantity`}
          title={`${PoFormLabel.Quantity}:`}
          required={true}
        >
          <Controller
            as={AutoNumeric}
            name={`allocations[${index}].quantity`}
            defaultValue={allocationField.quantity}
            placeholder="Enter quantity"
            options={{
              minimumValue: 0,
              maximumValue: 9999,
              decimalPlaces: 0,
              restrictZeroFirstChar: true
            }}
            disabled={disabled}
            rules={{ required: true, min: 1 }}
            hasError={!!quantityErrorType}
          />
        </FieldWithTooltip>

        <TooltipItem
          title={
            storeNumErrorType
              ? 'Please select at least a store to continue'
              : ''
          }
          arrow
          placement="top-start"
          TransitionProps={{
            exit: false
          }}
        >
          <SelectField
            name={`allocations[${index}].storeNumVisible`}
            placeholder="Please Select"
            isSearchable={isSearchable}
            onSearch={onSearch}
            options={selectStoreOptions}
            disabled={disabled}
            value={selectedStore}
            hasError={!!storeNumErrorType}
            isLoading={!!isStoreLoading}
            onChange={(selected: SelectFieldOption) => {
              setStoreState(selected.value);
              setStoreNumber(selected.value);
            }}
          >
            <label htmlFor={`allocations[${index}].storeNumVisible`}>
              <>
                <span className={formStyles.error}>*</span>
                {PoFormLabel.Store}:
              </>
            </label>
          </SelectField>
        </TooltipItem>

        <Controller
          as={HiddenField}
          type="hidden"
          name={`allocations[${index}].storeNum`}
          defaultValue={allocationField.storeNum}
          rules={{ required: true }}
        />

        <Controller
          as={HiddenField}
          type="hidden"
          name={`allocations[${index}].state`}
          defaultValue={allocationField.state}
          rules={{ required: true }}
        />

        <Button
          color="primary"
          square="40"
          disabled={disabled || index === 0}
          onClick={() => onRemoveButtonClick(index)}
        >
          <Delete fontSize="small" />
        </Button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectStoreOptions,
    allocationField,
    disabled,
    quantityErrorType,
    storeNumErrorType,
    index,
    onRemoveButtonClick,
    selectedStore,
    setStoreNumber,
    setStoreState
  ]);
};
