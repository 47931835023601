import React, { Dispatch, SetStateAction } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AlertDialog } from '../../../common/alert-dialog/AlertDialog';
import { PurchaseOrdersRoute } from '../../../app/routes/PurchaseOrdersRoute';

interface OwnProps {
  purchaseOrderId: string | undefined;
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  resetForm: () => void;
  isCreateMode: boolean;
  isEditMode: boolean;
}

export type CancelPurchaseOrderDialogProps = OwnProps & RouteComponentProps;

export const buttonNoCancelPopup = 'buttonNoCancelPopup';
export const buttonYesCancelPopup = 'buttonYesCancelPopup';

export const CancelPurchaseOrderDialog = withRouter(
  (props: CancelPurchaseOrderDialogProps) => {
    const {
      purchaseOrderId,
      openAlert,
      setOpenAlert,
      resetForm,
      isCreateMode,
      isEditMode,
      history
    } = props;

    const handleCloseAlert = ({
      currentTarget
    }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (currentTarget.dataset.cancel) {
        setOpenAlert(false);

        resetForm();

        if (isCreateMode) {
          history.push(PurchaseOrdersRoute.Submitted);
        }

        if (isEditMode) {
          history.push(PurchaseOrdersRoute.Draft);
        }
      } else {
        setOpenAlert(false);
      }
    };

    return (
      <AlertDialog
        open={openAlert}
        onClose={handleCloseAlert}
        title={purchaseOrderId ? 'Cancel Edit PO?' : 'Cancel Create a PO?'}
        text={
          purchaseOrderId
            ? 'By cancelling you will lose all data entered in this edit session.'
            : 'By cancelling you will lose all data present in the form.'
        }
        borderColor="#ffcd00"
        btnsConfig={[
          {
            label: 'No',
            variant: 'outlined',
            dataTest: buttonNoCancelPopup
          },
          {
            label: 'Yes, cancel',
            variant: 'contained',
            dataCancel: 'cancel',
            dataTest: buttonYesCancelPopup
          }
        ]}
      />
    );
  }
);
