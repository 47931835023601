import React from 'react';

import { helpComponentTestId } from '../Text';

import helpStyles from '../HelpText.module.scss';

export const ItemDetailsText = () => {
  return (
    <div data-testid={helpComponentTestId}>
      <ol className={helpStyles.list}>
        <li>
          Enter details about your items in your Manual Purchase Order.
          <span className={helpStyles.listTitle}>Tips:</span>
          <ul>
            <li>
              All items in your Manual Purchase Order should have the same item
              details.If they have different details, create a separate Purchase
              Order.
            </li>
            <li>
              The details you enter will eventually be on the customer’s
              agreement.
            </li>
          </ul>
        </li>
        <li>
          To save your Manual Purchase Order and finish it later, click Save as
          Draft. Your Manual Purchase Order will appear in Draft POs where it
          can be further edited.A Purchase Order Number will be assigned.You can
          change Item Details as often as you like in Draft state.
        </li>
        <li>
          When all required information for your Manual Purchase Order has been
          input, Submit PO will be enabled.
        </li>
        <li>
          Once complete, click Submit PO to submit your PO to SIMS.Your Manual
          Purchase Order details will appear in Submitted POs.Once your PO is in
          SIMS an agreement can be made.Once you Submit your PO, it cannot be
          changed.
        </li>
      </ol>
    </div>
  );
};
