import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { first } from 'lodash';

import {
  AlertDialog,
  BtnProps
} from '../../../common/alert-dialog/AlertDialog';
import { createGenericMessage } from '../../../layout/snackbar/messsages/generic-message/CreateGenericMessage';
import { useGlobalContext } from '../../../../context/global/GlobalContext';
import { openSnackbarAction } from '../../../../context/global/snackbar/snackbar-actions';
import { cancelPurchaseOrder } from '../../../../api/purchase-orders';
import { Either, left, right } from '../../../../utils/Either';
import { APIError } from '../../../../domain/APIError';
import useRefresh from '../../../app/routes/useRefresh';

export interface CancelButtonProps {
  readonly onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

interface Props {
  readonly poId: string;
  readonly cancelButton: FC<CancelButtonProps>;
  readonly redirectPath: string;
}

export const cancelPO = async (
  poId: string
): Promise<Either<void, APIError>> => {
  try {
    return left(await cancelPurchaseOrder(poId));
  } catch (e) {
    return right(e);
  }
};

export const buttonTestId = 'cancelPoButton';
export const yesButtonTestId = 'cancelPoYesButton';
export const noButtonTestId = 'cancelPoNoButton';

export const PoCanceller = (props: Props) => {
  const { poId, cancelButton: CancelButton, redirectPath } = props;

  const [isDialogOpen, openDialog] = useState(false);
  const [areButtonsDisabled, disableButtons] = useState(false);

  const btnsConfig: BtnProps[] = [
    {
      label: 'No',
      variant: 'outlined',
      dataTest: noButtonTestId,
      disabled: areButtonsDisabled
    },
    {
      label: 'Yes, cancel',
      variant: 'contained',
      dataCancel: 'delete',
      dataTest: yesButtonTestId,
      disabled: areButtonsDisabled
    }
  ];

  const history = useHistory();
  const refresh = useRefresh();

  const globalContext = useGlobalContext();

  const handleButtonClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();

    openDialog(true);
  };

  const handleErrors = (value: APIError) => {
    const errorMessage = first(value.errors)?.message;

    if (errorMessage) {
      globalContext.dispatch(
        openSnackbarAction(createGenericMessage(errorMessage))
      );
    }
    openDialog(false);
  };

  const handleSuccess = () => {
    if (history.location.pathname === redirectPath) {
      refresh(redirectPath);
    } else {
      history.push(redirectPath);
    }
  };

  const handleClose = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (e.currentTarget.dataset.cancel) {
      disableButtons(true);

      const either: Either<void, APIError> = await cancelPO(poId);

      disableButtons(false);

      if (either.isLeft()) {
        handleSuccess();
      } else {
        handleErrors(either.value);
      }
    } else {
      openDialog(false);
    }
  };

  return (
    <>
      <CancelButton onClick={handleButtonClick} />
      <AlertDialog
        open={isDialogOpen}
        onClose={handleClose}
        title="Cancel PO?"
        text="You are about to cancel this Purchase Order"
        note="Do you confirm this decision?"
        borderColor="#ffcd00"
        btnsConfig={btnsConfig}
      />
    </>
  );
};
