import React, { FC, PropsWithChildren } from 'react';

import styles from './Nav.module.scss';

export const navTestId = 'NavComponent';

export const Nav: FC = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.nav} data-testid={navTestId}>
    {children}
  </div>
);
