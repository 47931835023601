import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './SearchSelection.module.scss';

import { SearchTag } from './search-tag/SearchTag';
import { Store, getStoreSearchName } from '../../../../domain/User/Store';
import { noOp } from '../../../../utils/helper-functions';

interface Props {
  readonly isCollapsed: boolean;
  readonly selectedStores: Store[];
  readonly onChange?: (value: Store[]) => void;
}

export const noSelectionMessage = 'No stores selected';

export const SearchSelection = ({
  isCollapsed,
  selectedStores,
  onChange = noOp
}: Props) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(
    function calculateHidenCount() {
      if (!isCollapsed || !wrapper.current) {
        return;
      }

      if (wrapper.current.clientHeight === wrapper.current.scrollHeight) {
        setHiddenCount(0);
        return;
      }

      const wrapperWidth = wrapper.current?.clientWidth || Number.MAX_VALUE;
      const tagsEl = wrapper.current?.children || [];

      let count = 0;
      let widthAccumulator = 0;

      for (count = 0; count < tagsEl.length; count++) {
        widthAccumulator += tagsEl[count].clientWidth;

        if (widthAccumulator >= wrapperWidth) {
          break;
        }
      }

      setHiddenCount(tagsEl.length - count);
    },
    [isCollapsed, selectedStores.length]
  );

  const removeStore = (storeIndex: number) => {
    const stores = [...selectedStores];
    stores.splice(storeIndex, 1);

    onChange(stores);
  };

  const clearStores = () => {
    onChange([]);
  };

  return (
    <div
      className={clsx({
        [styles.selectionBox]: true,
        [styles.collapsed]: isCollapsed
      })}
    >
      <div className={styles.entriesWrap}>
        {!isCollapsed && <label>Selected Stores</label>}
        <div className={styles.entries} ref={wrapper}>
          {selectedStores.length === 0 && (
            <div className={styles.emptyMessage}>{noSelectionMessage}</div>
          )}
          {selectedStores.map((store, index) => (
            <SearchTag key={store.storeNum} onRemove={() => removeStore(index)}>
              {getStoreSearchName(store)}
            </SearchTag>
          ))}
        </div>
      </div>

      {isCollapsed && hiddenCount !== 0 && (
        <div className={styles.hiddenCount}>
          &hellip;{' '}
          <SearchTag className={styles.count} onRemove={() => clearStores()}>
            {hiddenCount}
          </SearchTag>
        </div>
      )}
    </div>
  );
};
