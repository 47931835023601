import { createMuiTheme } from '@material-ui/core';

export enum Colors {
  /* New design
     https://marvelapp.com/prototype/5ah6fij/screen/70740746
  */
  PRIMARY_01 = '#0c7aab',
  PRIMARY_02 = '#095B80',
  PRIMARY_03 = '#063D55',
  PRIMARY_04 = '#0F99D6',
  PRIMARY_05 = '#B1DEF1',
  PRIMARY_06 = '#F2FAFC',

  SECONDARY_01 = '#0C8C8C',
  SECONDARY_02 = '#006767',
  SECONDARY_03 = '#004D4D',
  SECONDARY_04 = '#00B7A7',

  NEUTRAL_01 = '#252A31',
  NEUTRAL_02 = '#414A57',
  NEUTRAL_03 = '#5B6C7A',
  NEUTRAL_04 = '#B3C0CB',
  NEUTRAL_05 = '#D2DCE4',
  NEUTRAL_06 = '#E6EBF0',
  NEUTRAL_07 = '#F6F9FB',

  RED_BUTTON = '#C02F48',
  /* End New design */

  PRIMARY_MAIN_COLOR = PRIMARY_04,
  PRIMARY_DARK_COLOR = '#034694',
  SUCCESS_COLOR = '#8DC63F',
  ERROR_MAIN_COLOR = '#FF0000',
  SWITCH_LIGHT_BLUE = '#CFEBF7',
  SWITCH_LIGHT_GREY = '#B0B0B0',
  SWITCH_DISABLED_GREY = '#EFEFEF',
  SWITCH_DARK_GREY = '#505050',
  BLACK_MEDIUM = 'rgba(0, 0, 0, 0.8)',
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  CARD_BORDER = '#F0F0F0'
}

export enum Fonts {
  FONT_FAMILY = '"Open Sans", "Helvetica", "Arial", sans-serif'
}
export enum FontWeight {
  MEDIUM = 600
}

export enum Breakpoints {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200
}

export const theme = createMuiTheme({
  typography: {
    fontFamily: Fonts.FONT_FAMILY,
    fontWeightMedium: FontWeight.MEDIUM,
    body2: {
      fontSize: '0.75rem'
    }
  },
  palette: {
    primary: {
      main: Colors.PRIMARY_01,
      dark: Colors.PRIMARY_DARK_COLOR
    },
    secondary: {
      main: Colors.SUCCESS_COLOR
    },
    error: {
      main: Colors.ERROR_MAIN_COLOR
    }
  },
  breakpoints: {
    values: {
      xs: Breakpoints.XS,
      sm: Breakpoints.SM,
      md: Breakpoints.MD,
      lg: Breakpoints.LG,
      xl: Breakpoints.XL
    }
  }
});
