import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { ClassificationsComponentProps } from './context/types';
import { filterById } from '../../../../../utils/filterById';
import { populateSelectByChild } from '../../../../../utils/populateSelectByChild';
import { ClassificationType } from '../../../../../domain/PurchaseOrder/ClassificationType';
import {
  SelectWrapper,
  SelectedFieldOption,
  emptyField
} from '../select-wrapper/SelectWrapper';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import {
  useClassificationsState,
  useClassificationsDispatch
} from './context/Provider';
import {
  PoFormField,
  ClassificationsInputPlaceholder
} from '../po-form-config';

import formStyles from '../PurchaseOrderForm.module.scss';

export const subTypeTestId = 'subTypeComponent';

// eslint-disable-next-line
export const SubType = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { subTypes, startWithTypes } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  // eslint-disable-next-line
  useEffect(() => {
    const poItemSubType = purchaseOrder && purchaseOrder.itemSubType;

    const selectedSubType: {
      name: string;
      id: string;
      subTypes: [];
    } | null = poItemSubType ? filterById(subTypes, poItemSubType)[0] : null;

    if (subTypes.length === 1) {
      setItemValue({ value: subTypes[0].id, label: subTypes[0].name });

      if (startWithTypes) {
        dispatch({
          type: ClassificationType.SET_SINGLE_DEPARTMENT,
          payload: subTypes[0].departments
        });
      }
    } else {
      setItemValue({
        value: (selectedSubType && selectedSubType.id) || '',
        label:
          (selectedSubType && selectedSubType.name) ||
          ClassificationsInputPlaceholder
      });

      if (startWithTypes) {
        dispatch({
          type: ClassificationType.CLEAR_DEPARTMENTS
        });
        if (selectedSubType) {
          dispatch({
            type: ClassificationType.SET_DEPARTMENTS,
            payload: filterById(subTypes, selectedSubType.id)[0].departments
          });
        }
      }

      !startWithTypes &&
        selectedSubType &&
        dispatch({
          type: ClassificationType.SET_SUBTYPES,
          payload: selectedSubType.subTypes
        });
    }
    // eslint-disable-next-line
  }, [subTypes, dispatch, startWithTypes]);

  const handleChange = useCallback(
    (selected: SelectedFieldOption) => {
      const { value, label } = selected;
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      if (startWithTypes) {
        const filterdDepartments = filterById(subTypes, value)[0].departments;
        let singleSubDepartment = filterdDepartments[0].subDepartments;
        let singleBracket = singleSubDepartment[0].brackets;

        if (purchaseOrder && purchaseOrder.itemSubType !== value) {
          purchaseOrder.department = '';
          purchaseOrder.subDepartment = '';
          purchaseOrder.bracket = '';
        }
        dispatch({
          type: ClassificationType.SET_DEPARTMENTS,
          payload: filterdDepartments
        });
        if (filterdDepartments.length === 1) {
          setValue(PoFormField.Department, filterdDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          setValue(PoFormField.Department, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }

        if (singleSubDepartment.length === 1) {
          setValue(PoFormField.SubDepartment, singleSubDepartment[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          singleSubDepartment = [];
          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
        if (singleBracket.length === 1) {
          setValue(PoFormField.Bracket, singleBracket[0].id, {
            shouldValidate: true,
            shouldDirty: true
          });
        } else {
          singleBracket = [];
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true
          });
        }
        dispatch({
          type: ClassificationType.SET_SUBTYPES,
          payload: filterById(subTypes, value)[0].departments
        });
      }
    },
    [dispatch, purchaseOrder, name, setValue, startWithTypes, subTypes]
  );

  const isViewMode =
    purchaseOrder && purchaseOrder.poStatus === PoStatus.SUBMITTED;

  return useMemo(
    () => (
      <div
        data-testid={subTypeTestId}
        className={clsx(formStyles.formBlock, {
          [formStyles.noFillField]: subTypes.length <= 0
        })}
      >
        <label htmlFor={name}>
          <span className={formStyles.error}>*</span>
          {label}:
        </label>
        <SelectWrapper
          propValue={itemValue}
          placeholder={ClassificationsInputPlaceholder}
          name={name}
          handleChange={handleChange}
          options={populateSelectByChild(subTypes)}
          disabled={isViewMode || !subTypes.length}
          purchaseOrder={purchaseOrder}
        />
      </div>
    ),
    [handleChange, isViewMode, itemValue, label, name, purchaseOrder, subTypes]
  );
};
