import React, { useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { CancelTokenSource } from 'axios';

import { PurchaseOrderSkeletonCard } from '../../submitted-purchase-orders/submitted-pos-list/PurchaseOrderSkeletonCard';
import {
  ScrollableContent,
  defaultSubtractFromHeight,
  SubtractFromHeight
} from '../../../common/scrollable-content/ScrollableContent';
import { GenericError } from '../../../common/GenericError';
import { DraftPosContext } from '../../../../context/purchase-orders/draft/DraftPosContext';
import { PurchaseOrderList } from '../../submitted-purchase-orders/submitted-pos-list/PurchaseOrderList';
import { getDraftPurchaseOrders } from '../../../../context/purchase-orders/draft/draft-pos-thunks';
import { generateCancelToken } from '../../../../api/client';

import styles from './DraftPOsList.module.scss';
import { Store } from '../../../../domain/User/Store';

interface Props {
  poStores?: Store[];
}

export const draftPOsListTestId = 'draft-purchase-orders';

export const draftPOCancelMessage = 'draftPOCancelMessage';

export const DraftPOsList = ({ poStores }: Props) => {
  const { purchaseOrders, isPending, hasError, dispatch } = useContext(
    DraftPosContext
  );

  const { isRenderedByContainer } = window;
  const customFormHeightOffset = {
    tiny: isRenderedByContainer ? '391' : '250',
    xs: isRenderedByContainer ? '351' : '251',
    lg: isRenderedByContainer ? '393' : '293'
  };

  const subtractFromHeight: SubtractFromHeight = poStores
    ? customFormHeightOffset
    : defaultSubtractFromHeight;

  useEffect(() => {
    const source: CancelTokenSource = generateCancelToken();

    getDraftPurchaseOrders(dispatch)(poStores, source.token);

    return source.cancel;
  }, [dispatch, poStores]);

  return useMemo(
    () => (
      <ScrollableContent subtractFromHeight={subtractFromHeight}>
        <div
          className={clsx({
            [styles.draftPurchaseOrders]: true,
            [styles.draftPurchaseOrders_withPOSore]: !poStores
          })}
          data-testid={draftPOsListTestId}
        >
          {hasError ? (
            <GenericError />
          ) : isPending ? (
            <PurchaseOrderSkeletonCard />
          ) : (
            purchaseOrders && (
              <PurchaseOrderList purchaseOrders={purchaseOrders} />
            )
          )}
        </div>
      </ScrollableContent>
    ),
    [poStores, hasError, isPending, purchaseOrders, subtractFromHeight]
  );
};
