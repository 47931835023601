import { useEffect } from 'react';

import { PurchaseOrdersRoute } from './PurchaseOrdersRoute';
import { useHistory } from 'react-router-dom';

export default function useRefresh() {
  const history = useHistory();

  let handler: any;

  const refresh = (path: string) => {
    history.push(PurchaseOrdersRoute.Index);

    handler = setTimeout(() => history.push(path), 10);
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
}
