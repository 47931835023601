import { Action } from '../../Action';
import { SnackbarMessage } from '../../../components/layout/snackbar/messsages/SnackbarMessage';

export enum SnackbarActionType {
  OPEN_SNACKBAR = 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
}

export interface OpenSnackbarAction extends Action {
  readonly type: SnackbarActionType.OPEN_SNACKBAR;
  readonly payload: {
    readonly message: SnackbarMessage;
  };
}

export const openSnackbarAction = (
  message: SnackbarMessage
): OpenSnackbarAction => ({
  type: SnackbarActionType.OPEN_SNACKBAR,
  payload: { message }
});

export interface CloseSnackbarAction extends Action {
  readonly type: SnackbarActionType.CLOSE_SNACKBAR;
}

export const closeSnackbarAction = (): CloseSnackbarAction => ({
  type: SnackbarActionType.CLOSE_SNACKBAR
});

export type SnackbarAction = OpenSnackbarAction | CloseSnackbarAction;
