import React, { FC, useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { CancelTokenSource } from 'axios';

import { SubmittedPosContext } from '../../../../context/purchase-orders/submitted/SubmittedPosContext';
import { getSubmittedPurchaseOrders } from '../../../../context/purchase-orders/submitted/submitted-pos-thunks';
import {
  ScrollableContent,
  defaultSubtractFromHeight,
  SubtractFromHeight
} from '../../../common/scrollable-content/ScrollableContent';
import { GenericError } from '../../../common/GenericError';
import { PurchaseOrderSkeletonCard } from './PurchaseOrderSkeletonCard';
import { PurchaseOrderList } from './PurchaseOrderList';
import { generateCancelToken } from '../../../../api/client';

import styles from './SubmittedPOsList.module.scss';
import { Store } from '../../../../domain/User/Store';

interface Props {
  poStores?: Store[];
}

export const submittedPOsListTestId = 'submitted-purchase-orders';

export const submittedPOCancelMessage = 'submittedPOCancelMessage';

export const SubmittedPOsList: FC<Props> = ({ poStores }: Props) => {
  const { purchaseOrders, isPending, hasError, dispatch } = useContext(
    SubmittedPosContext
  );

  const { isRenderedByContainer } = window;
  const customFormHeightOffset = {
    tiny: isRenderedByContainer ? '391' : '250',
    xs: isRenderedByContainer ? '351' : '251',
    lg: isRenderedByContainer ? '374' : '274'
  };

  const subtractFromHeight: SubtractFromHeight = poStores
    ? customFormHeightOffset
    : defaultSubtractFromHeight;

  useEffect(() => {
    const source: CancelTokenSource = generateCancelToken();

    getSubmittedPurchaseOrders(dispatch)(poStores, source.token);

    return source.cancel;
  }, [dispatch, poStores]);

  return useMemo(
    () => (
      <ScrollableContent subtractFromHeight={subtractFromHeight}>
        <div
          className={clsx({
            [styles.submittedPurchaseOrders]: true,
            [styles.submittedPurchaseOrders_withPOSore]: !poStores
          })}
          data-testid={submittedPOsListTestId}
        >
          {hasError ? (
            <GenericError />
          ) : isPending ? (
            <PurchaseOrderSkeletonCard />
          ) : (
            purchaseOrders && (
              <PurchaseOrderList purchaseOrders={purchaseOrders} />
            )
          )}
        </div>
      </ScrollableContent>
    ),
    [hasError, isPending, poStores, purchaseOrders, subtractFromHeight]
  );
};
