import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { chain } from 'lodash';

import { FormAllocation } from '../allocations/allocations-list/FormAllocation';
import { PricingList } from './pricing-list/PricingList';
import { AutoPricingProvider } from './context/AutoPricingProvider';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const pricingTestId = 'pricingComponent';

export interface PricingListProps {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
}

export const Pricing = ({ purchaseOrder }: PricingListProps) => {
  const allocations = useWatch({
    name: 'allocations'
  });

  const uniqueStates: string[] = chain(allocations)
    .filter((a: FormAllocation) => !!a.state)
    .uniqBy((a: FormAllocation) => a.state)
    .map('state')
    .value();

  return useMemo(
    () => (
      <div data-testid={pricingTestId}>
        <AutoPricingProvider
          uniqueStates={uniqueStates}
          purchaseOrder={purchaseOrder}
        >
          <PricingList
            uniqueStates={uniqueStates}
            allocations={allocations}
            purchaseOrder={purchaseOrder}
          />
        </AutoPricingProvider>
      </div>
    ),
    [uniqueStates, allocations, purchaseOrder]
  );
};
