import {
  FormPricingConfig,
  FormPricingKeys
} from './pricing/pricing-list/FormPricing';

export enum PanelTitle {
  ItemDetails = 'Item details',
  InventoryDetails = 'Inventory details',
  Pricing = 'Pricing',
  Allocations = 'Allocations'
}

export enum PoFormLabel {
  PurchaseOrderSubType = 'PO Type',
  ReferenceNumber = 'Purchase Reference Number',
  Supplier = 'Supplier',
  Brand = 'Brand',
  Model = 'Model',
  Condition = 'Condition',
  SupplierOrderNo = 'Supplier order number',
  Description = 'Description',
  Department = 'Department',
  SubDepartment = 'Sub-department',
  Bracket = 'Bracket',
  Type = 'Type',
  SubType = 'Sub-Type',
  TotalQuantity = 'Total quantity',
  CostPerItem = 'Cost per item',
  TotalCost = 'Total cost',
  Quantity = 'Quantity',
  Store = 'Store',
  BiWeeklyRate = 'Bi-Weekly rate',
  BiWeeklyTerm = 'Bi-Weekly term',
  MonthlyRate = 'Monthly rate',
  MonthlyTerm = 'Monthly term',
  SemiMonthlyRate = 'Semi-Monthly rate',
  SemiMonthlyTerm = 'Semi-Monthly term',
  TotalRTOPrice = 'Total RTO price',
  WeeklyRate = 'Weekly rate',
  WeeklyTerm = 'Weekly term',
  CostOfAlignment = 'Cost of Alignment'
}

export enum PoFormField {
  Id = 'id',
  PurchaseOrderSubType = 'purchaseOrderSubType',
  ReferenceNumber = 'referenceNumber',
  PoStatus = 'poStatus',
  Supplier = 'supplier',
  Brand = 'brand',
  Model = 'model',
  SupplierOrderNo = 'supplierOrderNo',
  Description = 'description',
  CostPerItem = 'costPerItem',
  TotalCost = 'totalCost',
  TotalQuantity = 'totalQuantity',
  StartClassificationsSelect = 'startClassificationsSelect',
  Department = 'department',
  SubDepartment = 'subDepartment',
  Bracket = 'bracket',
  ItemType = 'itemType',
  ItemSubType = 'itemSubType',
  CostOfAlignment = 'costOfAlignment'
}

export const ClassificationsInputPlaceholder = 'Please Select';

export const orderedPricingFields: Array<FormPricingKeys> = [
  FormPricingKeys.WeeklyRent,
  FormPricingKeys.WeeklyTerm,
  FormPricingKeys.TotalRtoPrice,
  FormPricingKeys.BiWeeklyRent,
  FormPricingKeys.BiWeeklyTerm,
  FormPricingKeys.SemiMonthlyRent,
  FormPricingKeys.SemiMonthlyTerm,
  FormPricingKeys.MonthlyRent,
  FormPricingKeys.MonthlyTerm
];

export const pricingConfig: FormPricingConfig = {
  weeklyRent: {
    label: PoFormLabel.WeeklyRate,
    validations: { min: 1, max: 900 }
  },
  weeklyTerm: {
    label: PoFormLabel.WeeklyTerm,
    validations: { min: 1, max: 200 }
  },
  totalRtoPrice: { label: PoFormLabel.TotalRTOPrice, validations: { min: 1 } },
  biWeeklyRent: { label: PoFormLabel.BiWeeklyRate, validations: { min: 1 } },
  biWeeklyTerm: { label: PoFormLabel.BiWeeklyTerm, validations: { min: 1 } },
  semiMonthlyRent: {
    label: PoFormLabel.SemiMonthlyRate,
    validations: { min: 1 }
  },
  semiMonthlyTerm: {
    label: PoFormLabel.SemiMonthlyTerm,
    validations: { min: 1 }
  },
  monthlyRent: { label: PoFormLabel.MonthlyRate, validations: { min: 1 } },
  monthlyTerm: { label: PoFormLabel.MonthlyTerm, validations: { min: 1 } }
};
