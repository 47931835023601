import { PoFormLabel } from '../../po-form-config';

export interface FormPricing {
  readonly state: string;
  readonly weeklyRent: string;
  readonly weeklyTerm: string;
  readonly biWeeklyRent: string;
  readonly biWeeklyTerm: string;
  readonly semiMonthlyRent: string;
  readonly semiMonthlyTerm: string;
  readonly monthlyRent: string;
  readonly monthlyTerm: string;
  readonly totalRtoPrice: string;
  [key: string]: any;
}

export enum FormPricingKeys {
  WeeklyRent = 'weeklyRent',
  WeeklyTerm = 'weeklyTerm',
  TotalRtoPrice = 'totalRtoPrice',
  BiWeeklyRent = 'biWeeklyRent',
  BiWeeklyTerm = 'biWeeklyTerm',
  SemiMonthlyRent = 'semiMonthlyRent',
  SemiMonthlyTerm = 'semiMonthlyTerm',
  MonthlyRent = 'monthlyRent',
  MonthlyTerm = 'monthlyTerm'
}

export interface FormPricingItemConfig {
  label: PoFormLabel;
  validations: { min?: number; max?: number };
}

export type FormPricingConfig = {
  [key in FormPricingKeys]: FormPricingItemConfig;
};

export const createFormPricing = (
  partial: Partial<FormPricing> = {}
): FormPricing => ({
  state: '',
  weeklyRent: '',
  weeklyTerm: '',
  biWeeklyRent: '',
  biWeeklyTerm: '',
  semiMonthlyRent: '',
  semiMonthlyTerm: '',
  monthlyRent: '',
  monthlyTerm: '',
  totalRtoPrice: '',
  ...partial
});
