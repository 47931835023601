import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { FormCard } from '../form-card/FormCard';
import { FormCardContent } from '../form-card/FormCardContent';
import { ReferenceNumber } from './ReferenceNumber';
import { POSubType } from './POSubType';
import { useGlobalContext } from '../../../../../context/global/GlobalContext';

interface Props {
  readonly isViewMode: boolean;
}

export const General = (props: Props) => {
  const { isViewMode } = props;

  const { poSubTypes } = useGlobalContext();

  return useMemo(
    () => (
      <Grid container spacing={2}>
        {poSubTypes.length > 0 && (
          <Grid item xs={12} lg={6} xl={3}>
            <FormCard>
              <FormCardContent>
                <POSubType subTypes={poSubTypes} disabled={isViewMode} />
              </FormCardContent>
            </FormCard>
          </Grid>
        )}
        <Grid item xs={12} lg={6} xl={3}>
          <FormCard>
            <FormCardContent>
              <ReferenceNumber disabled={isViewMode} />
            </FormCardContent>
          </FormCard>
        </Grid>
      </Grid>
    ),
    [isViewMode, poSubTypes]
  );
};
