import { RateAndTermResponse } from '../api/purchase-orders';

export const initialAutoPricingState: RateAndTermResponse = {
  weeklyRate: null,
  weeklyTerm: null,
  totalCost: null,
  biWeeklyRate: null,
  biWeeklyTerm: null,
  semiMonthlyRate: null,
  semiMonthlyTerm: null,
  monthlyRate: null,
  monthlyTerm: null
};

export const NONE_OPTIONS = {
  value: '',
  label: 'None'
};
