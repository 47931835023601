import React, { useMemo } from 'react';

import { Department } from './Department';
import { ClassificationsType } from './ClassificationsType';
import { SubType } from './SubType';
import { SubDepartment } from './SubDepartment';
import { Bracket } from './Bracket';
import { PoFormLabel } from '../po-form-config';
import { PoFormField } from '../po-form-config';
import { Provider } from './context/Provider';
import { InitialClassificationsSetter } from './InitialClassificationsSetter';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const ClassificationsComponentTestId = 'ClassificationsComponent';

interface Props {
  purchaseOrder: PurchaseOrderFormValues | null;
}

export const Classifications = (props: Props) => {
  const { purchaseOrder } = props;

  return useMemo(
    () => (
      <div data-testid={ClassificationsComponentTestId}>
        <Provider>
          <InitialClassificationsSetter />
          <Department
            name={PoFormField.Department}
            label={PoFormLabel.Department}
            purchaseOrder={purchaseOrder}
          />

          <SubDepartment
            name={PoFormField.SubDepartment}
            label={PoFormLabel.SubDepartment}
            purchaseOrder={purchaseOrder}
          />

          <Bracket
            name={PoFormField.Bracket}
            label={PoFormLabel.Bracket}
            purchaseOrder={purchaseOrder}
          />

          <ClassificationsType
            name={PoFormField.ItemType}
            label={PoFormLabel.Type}
            purchaseOrder={purchaseOrder}
          />

          <SubType
            name={PoFormField.ItemSubType}
            label={PoFormLabel.SubType}
            purchaseOrder={purchaseOrder}
          />
        </Provider>
      </div>
    ),
    [purchaseOrder]
  );
};
