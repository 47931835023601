import React from 'react';

import { PurchaseOrderCard } from './purchase-order-card/PurchaseOrderCard';
import { PurchaseOrderEmptyCard } from './purchase-order-empty-card/PurchaseOrderEmptyCard';
import { PurchaseOrder } from '../../../../domain/PurchaseOrder/PurchaseOrder';

export const PurchaseOrderList = (props: {
  purchaseOrders: PurchaseOrder[];
}) => {
  const { purchaseOrders } = props;
  const arePurchaseOrdersAvailable =
    purchaseOrders.length && purchaseOrders.length > 0;

  return (
    <>
      {arePurchaseOrdersAvailable ? (
        purchaseOrders.map((submitted, key) => {
          return <PurchaseOrderCard key={key} purchaseOrder={submitted} />;
        })
      ) : (
        <PurchaseOrderEmptyCard
          cardEmptyContent="You do not have any PO's yet."
          initialMessage=" Create your first PO using the button bellow or Go to Draft PO's and submit one."
        />
      )}
    </>
  );
};
