import { ClassificationType } from '../../../../../../domain/PurchaseOrder/ClassificationType';
import { StateProps, ActionProps } from './types';

export const initialState: StateProps = {
  startWithDepartment: false,
  startWithTypes: false,

  isDepartmentSelected: false,
  isSubDepartementSelected: false,
  isBracketSelected: false,
  isTypeSelected: false,
  isSubTypeSelected: false,

  initialDepartments: [],
  initialTypes: [],

  department: { id: '', name: '', subDepartments: [] },
  parentType: [],
  departments: [],
  subDepartments: [],
  brackets: [],
  types: [],
  subTypes: []
};

// eslint-disable-next-line
export const reducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case ClassificationType.SET_INITIAL_DATA: {
      return {
        ...state,
        initialDepartments: action.payload.departments,
        initialTypes: action.payload.types
      };
    }
    case ClassificationType.SET_DEPARTMENT:
      return {
        ...state,
        startWithDepartment: !state.startWithTypes,
        startWithTypes: state.startWithTypes,
        department: action.payload,
        subDepartments: action?.payload?.subDepartments,
        isDepartmentSelected: true,
        isSubDepartementSelected:
          action?.payload?.subDepartments.length === 1 ? true : false,
        isBracketSelected: false,
        isTypeSelected: false,
        isSubTypeSelected: false
      };

    case ClassificationType.SET_SUBDEPARTMENTS_FROM_DEPARTMENT: {
      return {
        ...state,
        isSubDepartementSelected: true,
        subDepartments: action.payload,
        isSubTypeSelected: false,
        isDepartmentSelected: true
      };
    }
    case ClassificationType.SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        isTypeSelected: false,
        isSubTypeSelected: true,
        isDepartmentSelected:
          action.payload && action.payload.length === 1 ? true : false,
        isSubDepartementSelected: false
      };
    case ClassificationType.SET_SINGLE_DEPARTMENT:
      return {
        ...state,
        isDepartmentSelected: true,
        departments: action.payload,
        isSubTypeSelected: action.payload.length === 1 ? true : false,
        isSubDepartementSelected: action.payload.length === 1 ? true : false,
        isBracketSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.CLEAR_DEPARTMENTS:
      return {
        ...state,
        isDepartmentSelected: false,
        isSubDepartementSelected: false,
        isBracketSelected: false,
        departments: []
      };
    case ClassificationType.SET_SINGLE_SUBDEPARTMENT:
      return {
        ...state,
        isDepartementSelected: true,
        isSubDepartementSelected: false,
        subDepartments: action.payload,
        isBracketSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.CLEAR_SUBDEPARTMENTS:
      return {
        ...state,
        isSubDepartementSelected: false,
        isBracketSelected: false,
        subDepartments: []
      };
    case ClassificationType.SET_SUBDEPARTMENTS:
      return {
        ...state,
        brackets: action.payload,
        isDepartmentSelected: false,
        isSubDepartementSelected: true,
        isBracketSelected: action.payload.length === 1 ? true : false,
        isTypeSelected: false,
        isSubTypeSelected: false
      };

    case ClassificationType.SET_SINGLE_BRACKET:
      return {
        ...state,
        brackets: action.payload,
        isSubDepartementSelected:
          state.subDepartments.length === 1 ? true : false,
        isBracketSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.SET_BRACKETS:
      return {
        ...state,
        types: action.payload,
        isDepartmentSelected: false,
        isSubDepartementSelected: false,
        isBracketSelected: true,
        isTypeSelected: action.payload.length === 1 ? true : false,
        isSubTypeSelected: false
      };
    case ClassificationType.SET_BRACKET_END:
      return {
        ...state,
        isTypeSelected: false,
        isSubTypeSelected: false,
        isDepartementSelected: false,
        isSubDepartementSelected: false,
        isBracketSelected: true
      };
    case ClassificationType.CLEAR_BRACKETS:
      return {
        ...state,
        brackets: []
      };

    case ClassificationType.SET_TYPES_AS_PARENT:
      return {
        ...state,
        startWithDepartment: false,
        startWithTypes: true,
        parentType: [action.payload],
        subTypes: action.payload.subTypes,
        isTypeSelected: true,
        isSubTypeSelected: action.payload.subTypes.length === 1 ? true : false,
        isDepartmentSelected: false,
        isSubDepartementSelected: false
      };
    case ClassificationType.SET_SINGLE_TYPE:
      return {
        ...state,
        types: action.payload,
        isTypeSelected: action.payload.length === 1 ? true : false,
        isSubTypeSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.CLEAR_TYPES:
      return {
        ...state,
        types: []
      };
    case ClassificationType.SET_TYPES:
      return {
        ...state,
        subTypes: action.payload,
        isDepartmentSelected: false,
        isSubDepartementSelected: false,
        isBracketSelected: false,
        isTypeSelected: true,
        isSubTypeSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.SET_SINGLE_SUBTYPE:
      return {
        ...state,
        subTypes: action.payload,
        isSubTypeSelected: action.payload.length === 1 ? true : false
      };
    case ClassificationType.CLEAR_SUBTYPES:
      return {
        ...state,
        subTypes: []
      };
    case ClassificationType.SET_SUBTYPES:
      return {
        ...state,
        isSubDepartementSelected: false,
        isBracketSelected: false,
        isTypeSelected: false,
        isSubTypeSelected: true,
        departments: action.payload,
        isDepartmentSelected:
          action && action.payload && action.payload.length === 1 ? true : false
      };

    default:
      return state;
  }
};
