import React, { PropsWithChildren } from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Colors } from '../../config/style-config';

export const buttonTestId = 'ButtonComponent';

interface OwnProps {
  // properties from HTMLAttributes
  'data-testid'?: string;
  className?: string;
  type?: string;

  square?: string;
  isDashed?: boolean;
  customColor?: 'red';
  kind?: 'link'; // maybe this should be in className
}

type Props = OwnProps & ButtonProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.typography.pxToRem(45),
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
        24
      )}`,
      minWidth: 0,
      borderRadius: theme.typography.pxToRem(5),
      textTransform: 'none'
    },
    outlined: {
      margin: theme.typography.pxToRem(1),
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,
      fontWeight: 700,
      '&$disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${Colors.SWITCH_LIGHT_GREY}`
      }
    },
    outlinedPrimary: {
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },
    outlinedSizeLarge: {
      fontSize: theme.typography.pxToRem(14),
      minWidth: theme.typography.pxToRem(120)
    },
    contained: {
      boxShadow: 'none',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      },
      '&$disabled': {
        color: theme.palette.background.paper,
        backgroundColor: Colors.SWITCH_LIGHT_GREY
      }
    },
    text: {
      '&:hover': {
        color: Colors.PRIMARY_05,
        backgroundColor: 'transparent'
      },
      '&$disabled': {
        color: Colors.NEUTRAL_06
      }
    },
    containedSizeLarge: {
      fontSize: theme.typography.pxToRem(14),
      minWidth: theme.typography.pxToRem(120)
    },
    disabled: {},
    square50: {
      width: theme.typography.pxToRem(50),
      height: theme.typography.pxToRem(50)
    },
    square40: {
      width: theme.typography.pxToRem(40),
      height: theme.typography.pxToRem(40)
    },
    square30: {
      padding: theme.typography.pxToRem(3),
      width: theme.typography.pxToRem(30),
      height: theme.typography.pxToRem(30)
    },
    dashed: {
      borderStyle: 'dashed',
      '&:hover': {
        borderStyle: 'dashed'
      }
    },
    link: {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    red: {
      backgroundColor: Colors.RED_BUTTON,
      color: Colors.WHITE,
      '&:hover': {
        backgroundColor: Colors.RED_BUTTON
      }
    }
  })
);

export const Button = (props: PropsWithChildren<Props>) => {
  const {
    children,
    square,
    className = '',
    type = 'button',
    isDashed,
    customColor,
    kind,
    ...rest
  } = props;

  const classes = useStyles();

  const classNames = clsx(
    square === '50' && classes.square50,
    square === '40' && classes.square40,
    square === '30' && classes.square30,
    isDashed && classes.dashed,
    !!customColor && classes[customColor],
    !!kind && classes[kind],
    className
  );

  return (
    <MuiButton
      data-testid={buttonTestId}
      className={classNames}
      type={type}
      classes={{
        root: classes.root,
        outlined: classes.outlined,
        outlinedPrimary: classes.outlinedPrimary,
        outlinedSizeLarge: classes.outlinedSizeLarge,
        contained: classes.contained,
        containedSizeLarge: classes.containedSizeLarge,
        text: classes.text,
        disabled: classes.disabled
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};
