import { uniqBy, orderBy } from 'lodash';

export interface FormAllocation {
  readonly quantity: string;
  readonly storeNum: string;
  readonly state: string;
  readonly isDefault?: boolean;
}

export const createFormAllocation = (
  partial: Partial<FormAllocation> = {}
): FormAllocation => ({
  storeNum: '',
  state: '',
  quantity: '',
  ...partial
});

export const calculateTotalQuantity = (
  allocations: FormAllocation[]
): string => {
  if (!allocations) return '';

  const result: number = allocations.reduce(
    (acc: number, allocation: FormAllocation) => {
      const { quantity } = allocation;
      const value = (quantity && parseInt(quantity.replace(/,/g, ''), 10)) || 0;

      return acc + value;
    },
    0
  );

  return result ? `${result}` : '';
};

export const filterEmptyAllocations = (
  allocations: FormAllocation[]
): FormAllocation[] => allocations.filter((a: FormAllocation) => a.state);

export const getAllocationsWithUniqueStates = (
  allocations: FormAllocation[]
): FormAllocation[] =>
  uniqBy(allocations, (a: FormAllocation) => {
    return a.state;
  });

export const getAllocationsOrderedByState = (
  allocations: FormAllocation[]
): FormAllocation[] =>
  orderBy(allocations, (a: FormAllocation) => a.state.toLowerCase(), ['asc']);

export const getNumberOfAllocationsWithTheSameState = (
  allocations: FormAllocation[],
  state: string
): number =>
  allocations.reduce((acc: number, a: FormAllocation) => {
    if (a.state === state) {
      return acc + 1;
    }
    return acc;
  }, 0);

export const firstStoreNumInState = (
  allocations: FormAllocation[],
  state: string
): string | undefined => {
  return allocations.find((a: FormAllocation) => a.state === state)?.storeNum;
};
