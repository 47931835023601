import React, { CSSProperties } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { ActionMeta, components, StylesConfig } from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';

import styles from './SelectWrapper.module.scss';
import { Colors } from '../../../../../config/style-config';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

export const selectWrapperTestId = 'selectWrapperComponent';

export const emptyField = {
  value: '',
  label: 'Please Select'
};

export interface SelectFieldOption {
  label: string;
  value: string;
}

export interface SelectedFieldOption extends SelectFieldOption {
  departments?: any[];
  subDepartments?: any[];
  brackets?: any[];
  types?: any[];
  subTypes: any[];
}

export interface SelectWrapperProps {
  options: SelectFieldOption[];
  name: string;
  className?: string;
  handleChange?: (value: any, action: ActionMeta) => void;
  placeholder?: string;
  propValue: SelectFieldOption;
  disabled?: boolean;
  hideSelectedOptions?: boolean;
  purchaseOrder?: PurchaseOrderFormValues | null;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
      </components.DropdownIndicator>
    )
  );
};

const customStyles: StylesConfig = {
  container: (provided: CSSProperties, { isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#efefef' : '#fff',
    border: `1px solid ${
      isDisabled ? Colors.SWITCH_DARK_GREY : Colors.PRIMARY_MAIN_COLOR
    }`
  }),
  singleValue: (provided: CSSProperties) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 600,
    color: Colors.BLACK_MEDIUM
  }),
  option: (provided: CSSProperties, { isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? 'rgba(15, 153, 214, 0.2);' : Colors.WHITE,
    color: '#000',
    fontSize: '14px',
    padding: '10px',
    position: 'relative',
    '&:hover': {
      backgroundColor: isSelected
        ? 'rgba(15, 153, 214, 0.2)'
        : 'rgba(15, 153, 214, 0.1)',
      '&:before': {
        content: 'none'
      }
    },
    '&:before': {
      content: isSelected ? 'none' : "''",
      position: 'absolute',
      top: 0,
      right: 0,
      height: '1px',
      width: 'calc(100% - 10px)',
      backgroundColor: Colors.CARD_BORDER
    },
    '&:first-of-type': {
      '&:before': {
        content: 'none'
      }
    }
  }),
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  placeholder: (provided: CSSProperties) => ({
    ...provided,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#000',
    fontSize: '14px'
  }),
  dropdownIndicator: (provided: CSSProperties, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? Colors.BLACK : Colors.PRIMARY_MAIN_COLOR,
    '&:hover': {
      color: Colors.PRIMARY_MAIN_COLOR
    }
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    margin: '0',
    borderRadius: 0,
    border: `1px solid ${Colors.PRIMARY_MAIN_COLOR}`,
    boxShadow: 'none',
    backgroundColor: '#fff',
    width: 'calc(100% + 2px)',
    left: '-1px'
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    padding: 0
  })
};

export const SelectWrapper = (props: SelectWrapperProps) => {
  const {
    options,
    name,
    className,
    handleChange,
    placeholder,
    propValue,
    disabled,
    purchaseOrder,
    ...rest
  } = props;
  const {
    register,
    formState: { isDirty }
  } = useFormContext();
  const customClasses = className ? className : '';
  const { value } = propValue;

  return (
    <div data-testid={selectWrapperTestId}>
      <input
        name={name}
        type="hidden"
        ref={register && register({ required: true })}
        value={
          isDirty ? value : (purchaseOrder && purchaseOrder[name]) || value
        }
      />
      <Select
        value={propValue}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        className={clsx(
          'selectItem',
          styles.selectItemComponent,
          customClasses
        )}
        classNamePrefix="select"
        name={name}
        options={options}
        onChange={handleChange}
        isSearchable={false}
        styles={customStyles}
        IndicatorSeparator={false}
        placeholder={placeholder}
        isDisabled={disabled}
        menuShouldScrollIntoView={true}
        {...rest}
      />
    </div>
  );
};
