import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PurchaseOrdersRoute } from './PurchaseOrdersRoute';
import { PermissionsProvider } from '../../../context/user/PermissionsProvider';
import { StoresProvider } from '../../../context/stores/StoresProvider';
import { SubmittedPosProvider } from '../../../context/purchase-orders/submitted/SubmittedPosProvider';
import { DraftPosProvider } from '../../../context/purchase-orders/draft/DraftPosProvider';
import { SubmittedPurchaseOrders } from '../../screens/submitted-purchase-orders/SubmittedPurchaseOrders';
import { DraftPurchaseOrders } from '../../screens/draft-purchase-orders/DraftPurchaseOrders';
import { PurchaseOrder } from '../../screens/purchase-order/PurchaseOrder';
import { GlobalContextProvider } from '../../../context/global/GlobalContextProvider';
import { ClassificationsFetcher } from '../ClassificationsFetcher';
import { Snackbar } from '../../layout/snackbar/Snackbar';
import { PoSubTypesFetcher } from '../PoSubTypesFetcher';
import { StoresFetcher } from '../StoresFetcher';

export const routesTestId = 'RoutesComponent';

export const Routes = () => (
  <div data-testid={routesTestId}>
    <PermissionsProvider>
      <StoresProvider>
        <StoresFetcher />
        <GlobalContextProvider>
          <ClassificationsFetcher />
          <PoSubTypesFetcher />
          <Snackbar />
          <Switch>
            <Route exact path={PurchaseOrdersRoute.Index}>
              <Redirect to={PurchaseOrdersRoute.Submitted} />
            </Route>
            <Route exact path={PurchaseOrdersRoute.Submitted}>
              <SubmittedPosProvider>
                <SubmittedPurchaseOrders />
              </SubmittedPosProvider>
            </Route>
            <Route exact path={PurchaseOrdersRoute.Draft}>
              <DraftPosProvider>
                <DraftPurchaseOrders />
              </DraftPosProvider>
            </Route>
            <Route
              exact
              path={PurchaseOrdersRoute.Create}
              component={PurchaseOrder}
            />
            <Route
              exact
              path={PurchaseOrdersRoute.View}
              component={PurchaseOrder}
            />
            <Route>
              <Redirect to={PurchaseOrdersRoute.Index} />
            </Route>
          </Switch>
        </GlobalContextProvider>
      </StoresProvider>
    </PermissionsProvider>
  </div>
);
