import React, { ReactNode } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ExpansionPanel as ExpansionPanelMui,
  ExpansionPanelProps
} from '@material-ui/core';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Colors } from '../../config/style-config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    panelRoot: {
      boxShadow: 'none',
      marginBottom: theme.typography.pxToRem(8),
      '&$panelExpanded': {
        margin: 0
      },
      '&:before': {
        content: 'none'
      }
    },
    panelExpanded: {
      margin: 0
    },
    panelSummaryRoot: {
      backgroundColor: '#F0F0F0',
      minHeight: 0,
      padding: `${theme.typography.pxToRem(7)} ${theme.typography.pxToRem(
        20
      )} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
      '&$panelSummaryExpanded': {
        minHeight: 0
      }
    },
    expandIcon: {
      padding: 0
    },
    panelSummaryContent: {
      margin: 0,
      '&$panelSummaryExpanded': {
        margin: 0
      }
    },
    panelSummaryExpanded: {
      marginTop: 0,
      marginBottom: 0
    },
    iconRoot: {
      color: theme.palette.primary.dark
    },
    dark: { color: Colors.SWITCH_DARK_GREY },
    title: {
      margin: 0,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(25),
      fontWeight: 700,
      color: Colors.SWITCH_DARK_GREY
    },
    content: { padding: `${theme.typography.pxToRem(16)} 0 0 0` }
  })
);

export const expansionPanelTestId = 'ExpansionPanelComponent';

interface OwnProps {
  index?: number;
  title: string;
  withSmallPanel?: boolean;
  children: ReactNode;
}

type Props = OwnProps & ExpansionPanelProps;

export const ExpansionPanel = (props: Props) => {
  const { title, children, index, ...rest } = props;
  const classes = useStyles(props);

  return (
    <ExpansionPanelMui
      data-testid={expansionPanelTestId}
      square={true}
      classes={{ root: classes.panelRoot, expanded: classes.panelExpanded }}
      {...rest}
    >
      <ExpansionPanelSummary
        expandIcon={
          <ArrowDropDownIcon
            className={classes.dark}
            classes={{ root: classes.iconRoot }}
          />
        }
        aria-controls={`panel${index}d-content`}
        id={`panel${index}d-header`}
        classes={{
          root: classes.panelSummaryRoot,
          content: classes.panelSummaryContent,
          expanded: classes.panelSummaryExpanded,
          expandIcon: classes.expandIcon
        }}
      >
        <p className={classes.title}>{title}</p>
      </ExpansionPanelSummary>
      <div className={classes.content}>{children}</div>
    </ExpansionPanelMui>
  );
};
