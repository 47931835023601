import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { FieldWithTooltip } from '../../../../common/field-with-tooltip/FieldWithTooltip';
import { CommonProps } from './pricing-list/PricingItem';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';
import { PoFormLabel, pricingConfig } from '../po-form-config';
import { useUserPermissions } from '../../../../../context/user/PermissionsProvider';
import { FormPricingKeys } from './pricing-list/FormPricing';
import { useAutoPricingState } from './context/AutoPricingProvider';
import { initialAutoPricingState } from '../../../../../constants/constants';

const { min, max } = pricingConfig[FormPricingKeys.WeeklyRent].validations;

export const weeklyRentTestId = 'WeeklyRentComponent';

export const WeeklyRent = (props: CommonProps) => {
  const { index, field, purchaseOrder, state } = props;
  const { hasAutoPricing, hasEditablePricing } = useUserPermissions();
  const { autoPricings, isChanged } = useAutoPricingState();
  const { errors, setValue } = useFormContext();
  const { weeklyRate } = autoPricings[state] || initialAutoPricingState;
  const fieldName = `pricing[${index}].weeklyRent`;
  const errorType = get(errors, `${fieldName}.type`);
  const weeklyRentWatched = useWatch({ name: `pricing[${index}].weeklyRent` });
  const isDraft = purchaseOrder?.poStatus === PoStatus.DRAFT;
  const isSubmitted = purchaseOrder?.poStatus === PoStatus.SUBMITTED;
  const [disabled, setDisabled] = useState(
    hasAutoPricing ? isSubmitted || !isDraft : isSubmitted
  );

  useEffect(() => {
    if (weeklyRentWatched) {
      setValue(fieldName, weeklyRentWatched, {
        shouldDirty: field.weeklyRent === '',
        shouldValidate: field.weeklyRent === ''
      });

      if (disabled && hasAutoPricing) {
        setDisabled(purchaseOrder?.poStatus === PoStatus.SUBMITTED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyRentWatched]);

  return useMemo(
    () => (
      <div data-testid={weeklyRentTestId}>
        <FieldWithTooltip
          info={`Please add weekly rate value between ${min} and ${max}`}
          id={fieldName}
          title={`${PoFormLabel.WeeklyRate}:`}
          required={true}
          currency={true}
        >
          <Controller
            as={AutoNumeric}
            name={fieldName}
            defaultValue={field.weeklyRent}
            placeholder="--"
            options={{
              minimumValue: 0,
              decimalPlaces: 2,
              restrictZeroFirstChar: false,
              maximumValue: max
            }}
            disabled={
              (hasAutoPricing && !hasEditablePricing) || disabled || isChanged
            }
            rules={{ required: true, min, max }}
            hasError={!!errorType}
            {...((!!weeklyRate || isChanged) && {
              imperativeValue: isChanged ? '' : weeklyRate?.toString(),
              imperativeMode: true
            })}
          />
        </FieldWithTooltip>
      </div>
    ),
    [
      fieldName,
      field.weeklyRent,
      hasAutoPricing,
      hasEditablePricing,
      disabled,
      isChanged,
      errorType,
      weeklyRate
    ]
  );
};
