import { State } from './State';
import { SelectFieldOption } from '../../components/common/select-field/SelectField';

export interface Store {
  readonly storeId: number;
  readonly storeNum: string;
  readonly storeName: string;
  readonly franchiseeName: string;
  readonly franchiseeStore: boolean;
  readonly state: State;
  readonly simsStore: boolean;
  readonly startDate: string;
  readonly endDate: string;
}

export const getStoreByNumber = (
  stores: Store[],
  storeNum: string
): Store | undefined => stores.find((s: Store) => s.storeNum === storeNum);

export const createStoreOption = (store: Store): SelectFieldOption => ({
  label: `${store.storeNum} - ${store.storeName}`,
  value: store.storeNum
});

export const createStoreOptions = (stores: Store[]) =>
  stores.map(createStoreOption);

export const getStoreSearchName = (store: Store): string => {
  return `${store.storeNum} - ${store.storeName}, ${store.state.abbreviation}`;
};
