import React, { useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { PoFormLabel } from '../po-form-config';
import { FieldWithTooltip } from '../../../../common/field-with-tooltip/FieldWithTooltip';
import { CharactersLeft } from './CharactersLeft';
import { isNonEmptyValue } from '../../../../../utils/isNonEmptyValue';
import { PoStatus } from '../../../../../domain/PurchaseOrder/PoStatus';

import styles from './Description.module.scss';
import { PurchaseOrderFormValues } from '../PurchaseOrderFormValues';

interface Props {
  purchaseOrder: PurchaseOrderFormValues | null;
  disabled: boolean;
}

export const descriptionTestId = 'descriptionTestId';

export const Description = (props: Props) => {
  const { purchaseOrder, disabled } = props;

  const { register, errors } = useFormContext();

  const [description, setDescription] = useState('');
  // DOC: this is necessary as a fix for IE11
  // without this, IE11 triggers validation on loading the component (reason unknown)
  const [hasBeenFocusedAtLeastOnce, setHasBeenFocusedAtLeastOnce] = useState(
    false
  );

  const isEditMode = !!(
    purchaseOrder && purchaseOrder.poStatus === PoStatus.DRAFT
  );

  return (
    <>
      <FieldWithTooltip
        info="Please provide description"
        id="description-area"
        title={`${PoFormLabel.Description}:`}
        required={true}
      >
        <textarea
          id="description-area"
          data-testid={descriptionTestId}
          name="description"
          autoFocus={false}
          className={clsx({
            [styles.description]: true,
            [styles.invalid]: get(errors, 'description.type')
          })}
          onInput={(event: any) => {
            setDescription(event.target.value);
          }}
          onFocus={() => {
            if (!hasBeenFocusedAtLeastOnce) {
              setHasBeenFocusedAtLeastOnce(true);
            }
          }}
          maxLength={255}
          ref={register({
            // DOC: this is necessary as a fix for IE11
            required: hasBeenFocusedAtLeastOnce || isEditMode,
            minLength: 1,
            maxLength: 255,
            validate: (value: string) => isNonEmptyValue(value)
          })}
          placeholder="Enter a description"
          defaultValue={
            purchaseOrder && purchaseOrder.description
              ? purchaseOrder.description
              : ''
          }
          disabled={disabled}
        />
      </FieldWithTooltip>
      <CharactersLeft maxLength={255} currentLength={description?.length} />
    </>
  );
};
