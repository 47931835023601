import React, { PropsWithChildren, useRef } from 'react';
import MaterialUiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import classes from './Modal.module.scss';
import clsx from 'clsx';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => {};
  color?: 'primary' | 'danger';
}

export const modalTestId = 'ModalComponent';

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const { isOpen, children, color = 'primary' } = props;
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <div data-testid={modalTestId} ref={rootRef}>
      <MaterialUiModal
        className={classes.modal}
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ classes: { root: classes.BackdropPropsRoot } }}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <div
            className={clsx(classes.paperContent, {
              [classes.primary]: color === 'primary',
              [classes.danger]: color === 'danger'
            })}
          >
            {children}
          </div>
        </div>
      </MaterialUiModal>
    </div>
  );
};
