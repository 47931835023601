import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext
} from 'react';
import { initialState, reducer } from './reducer';
import { StateProps, ActionProps } from './types';

export const StateContext = createContext<StateProps>(initialState);
export const DispatchContext = createContext<Dispatch<ActionProps>>(() => {});

export const Provider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useClassificationsState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'useClassificationsState must be used within a ClassificationsProvider'
    );
  }
  return context;
};

export const useClassificationsDispatch = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useClassificationsDispatch must be used within a ClassificationsProvider'
    );
  }
  return context;
};
