import React, { useReducer, ReactNode } from 'react';

import {
  submittedPosInitialState,
  submittedPosReducer
} from './submittedPosReducer';
import { SubmittedPosContext } from './SubmittedPosContext';

export const SubmittedPosProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    submittedPosReducer,
    submittedPosInitialState
  );

  return (
    <SubmittedPosContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SubmittedPosContext.Provider>
  );
};
