import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { FieldWithTooltip } from '../../../../common/field-with-tooltip/FieldWithTooltip';
import { PoFormField, PoFormLabel } from '../po-form-config';
import styles from './AlignmentBox.module.scss';

export interface AlignBoxProps {
  onConfirm: () => void;
}

export const dataTestId = 'alignmentBox';

export const AlignmentBox = ({ onConfirm }: AlignBoxProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const { watch } = useFormContext();

  const alignmentValue = watch(PoFormField.CostOfAlignment);

  const onToggleCheckbox = (value: boolean) => {
    setIsChecked(value);
  };

  return (
    <div className={styles.box} data-testid={dataTestId}>
      <h2>Alignment</h2>
      <div className={styles.content}>
        <FormControlLabel
          classes={{ label: styles.selectLabel }}
          control={
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={event => {
                onToggleCheckbox(event.target.checked);
              }}
            />
          }
          label="I would like to add alignment to this PO"
        />
        <div className={styles.inputWrapper}>
          <FieldWithTooltip
            info="Please add value between 1 and 500"
            id="alignemnt"
            title={`${PoFormLabel.CostOfAlignment}:`}
            required={isChecked}
          >
            {isChecked ? (
              <Controller
                key="1"
                as={AutoNumeric}
                name={PoFormField.CostOfAlignment}
                placeholder="Enter alignment cost"
                options={{
                  minimumValue: 1,
                  maximumValue: 500,
                  decimalPlaces: 0,
                  restrictZeroFirstChar: true
                }}
                disabled={false}
              />
            ) : (
              <Controller
                key="2"
                as={AutoNumeric}
                name="placeholder"
                placeholder="Enter alignment cost"
                disabled={true}
                options={{
                  minimumValue: 1,
                  maximumValue: 500,
                  decimalPlaces: 0,
                  restrictZeroFirstChar: true
                }}
              />
            )}
          </FieldWithTooltip>
        </div>
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          disabled={isChecked && !alignmentValue}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
