import React, { useEffect, useRef, RefObject } from 'react';

import { AutonumericInit } from './AutonumericInit';

export const autonumericInputWrapperTestId =
  'autonumericInputWrapperComponnent';

export interface InterfaceProps {
  register?: any;
  ref?: any;
  value?: string;
  name?: string;
  minimumValue?: number;
  maximumValue?: number;
  decimalPlaces?: number;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number
  ) => void;
  onKeyUp?: (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: number
  ) => void;
  outputFormat?: any;
  preDefined?: object;
  eventName?: any | undefined;
  disabled?: boolean;
  emptyInputBehavior?: string;
  restrictZeroFirstChar?: boolean;
  modifyValueOnWheel?: boolean;
  className?: string;
  required?: boolean;
  id?: string;
  currencySymbol?: string;
  defaultValue?: string;
  ariaLabelledby?: string;
  currencySymbolPlacement?: string;
}

export const AutonumericInputWrapper = (props: InterfaceProps) => {
  const { register, value, required, restrictZeroFirstChar = true } = props;
  const forRef: RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (forRef && forRef.current) {
      register &&
        register(forRef.current, {
          required: required !== undefined ? required : true
        });
    }
  }, [register, forRef, required]);

  return (
    <AutonumericInit
      value={value}
      type="text"
      outputFormat="number"
      preDefined={{}}
      className="inputNumber"
      forRef={forRef}
      restrictZeroFirstChar={restrictZeroFirstChar}
      modifyValueOnWheel={false}
      emptyInputBehavior="null"
      {...props}
    />
  );
};
