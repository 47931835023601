import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { PanelTitle } from '../../../screens/purchase-order/purchase-order-form/po-form-config';

export const menuListTestId = 'menuListComponent';

interface Props {
  title: string;
  handleClick: (action: string) => () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      listStyleType: 'none',
      paddingLeft: 0
    },
    button: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
    active: {
      fontWeight: 700
    },
    btnSquare: {
      backgroundColor: 'transparent',
      border: 'none',
      width: theme.typography.pxToRem(50),
      height: theme.typography.pxToRem(50),
      marginLeft: theme.typography.pxToRem(-13),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none'
      }
    }
  })
);

export const MenuListText = (props: Props) => {
  const { title, handleClick } = props;

  const classes = useStyles();

  return (
    <ul className={classes.menuList} data-testid={menuListTestId}>
      {Object.values(PanelTitle).map((action: string, index: number) => (
        <li key={index}>
          <button
            type="button"
            onClick={handleClick(action)}
            className={clsx({
              [classes.button]: true,
              [classes.active]: title === action
            })}
          >
            <span className={classes.btnSquare}>
              <ReceiptIcon color="primary" />
            </span>
            <span>{action}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};
