import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Colors } from '../../../../../config/style-config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputInfo: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      color: Colors.SWITCH_LIGHT_GREY
    }
  })
);

export const charactersLeftTestId = 'charactersLeftTestId';

export const CharactersLeft = (props: {
  maxLength: number;
  currentLength: number;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.inputInfo} data-testid={charactersLeftTestId}>
      {props.maxLength - props.currentLength} of {props.maxLength} characters
      left{' '}
    </div>
  );
};
