import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ArrayField, useFieldArray, useFormContext } from 'react-hook-form';

import { createFormPricing, FormPricing } from './FormPricing';
import { FormAllocation } from '../../allocations/allocations-list/FormAllocation';
import { PricingItem } from './PricingItem';
import { PurchaseOrderFormValues } from '../../PurchaseOrderFormValues';

interface Props {
  uniqueStates: string[];
  allocations: FormAllocation[];
  purchaseOrder: PurchaseOrderFormValues | null;
}

export const PricingList = (props: Props) => {
  const { uniqueStates, allocations, purchaseOrder } = props;

  const { trigger } = useFormContext<PurchaseOrderFormValues>();

  const { fields, append, remove } = useFieldArray<FormPricing>({
    name: 'pricing'
  });

  const [expanded, setExpanded] = useState<string | false>('panel0');

  const [stateToRemove, setStateToRemove] = useState<string[]>([]);

  const handleChange = useCallback(
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    [setExpanded]
  );

  const openStorePanel = useCallback(
    (isChanged: boolean, index: number, isStateAdded = true) => {
      setExpanded((isChanged || isStateAdded) && `panel${index}`);
    },
    []
  );

  useEffect(() => {
    if (allocations.every((f: FormAllocation) => f.isDefault)) {
      return;
    }

    const existingStates: string[] = fields
      .map((f: Partial<ArrayField<FormPricing>>) => f.state)
      .filter((f: string | undefined): f is string => !!f);

    const stateToAdd: string | undefined = uniqueStates.find(
      (state: string) => !existingStates.includes(state)
    );

    const stateToRemove: string | undefined = existingStates.find(
      (state: string) => !uniqueStates.includes(state)
    );

    if (stateToAdd) {
      const formPricing: FormPricing = createFormPricing({
        state: stateToAdd
      });

      append(formPricing);
    }

    if (stateToRemove) {
      setStateToRemove([stateToRemove]);
    }
  }, [allocations, append, fields, uniqueStates]);

  useEffect(() => {
    const [toRemove] = stateToRemove;

    if (toRemove) {
      const existingStates: string[] = fields
        .map((f: Partial<ArrayField<FormPricing>>) => f.state)
        .filter((f: string | undefined): f is string => !!f);

      const index = existingStates.indexOf(toRemove);

      remove(index);

      setStateToRemove([]);

      trigger();
    }
  }, [stateToRemove, fields, remove, trigger]);

  return useMemo(
    () => (
      <>
        {fields.map(
          (field: Partial<ArrayField<FormPricing>>, index: number) => (
            <PricingItem
              key={field.id}
              field={field}
              index={index}
              allocations={allocations}
              purchaseOrder={purchaseOrder}
              expanded={expanded}
              handleChange={handleChange}
              openStorePanel={openStorePanel}
              state=""
            />
          )
        )}
      </>
    ),
    [allocations, expanded, fields, handleChange, openStorePanel, purchaseOrder]
  );
};
