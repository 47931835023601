import { POStoreActionType } from './po-store-actions';
import { GlobalContextAction } from '../globalReducer';
import { Store } from '../../../domain/User/Store';

export const poStoreReducer = (
  state: Store[] | undefined,
  action: GlobalContextAction
): Store[] | undefined =>
  action.type === POStoreActionType.SELECT_PO_STORE
    ? action.payload.poStores
    : state;
