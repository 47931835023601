import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import styles from './ScrollableContent.module.scss';

export interface SubtractFromHeight {
  tiny: string;
  xs: string;
  lg: string;
}

interface Props {
  subtractFromHeight: SubtractFromHeight;
}

export const defaultSubtractFromHeight: SubtractFromHeight = {
  tiny: '305',
  xs: '233',
  lg: '270'
};

const makeHeightStyle = (toSubtract: SubtractFromHeight) =>
  makeStyles((theme: Theme) =>
    createStyles({
      height: {
        [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
          height: `calc(100vh - ${toSubtract.xs}px)`
        },
        [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
          height: `calc(100vh - ${toSubtract.lg}px)`
        },
        [theme.breakpoints.down(406)]: {
          height: `calc(100vh - ${toSubtract.tiny}px)`
        }
      }
    })
  );

export const ScrollableContent = (props: PropsWithChildren<Props>) => {
  const heightStyle = makeHeightStyle(props.subtractFromHeight)();

  return (
    <div className={clsx(styles.scrollableContent, heightStyle.height)}>
      {props.children}
    </div>
  );
};
