import React from 'react';

import { helpComponentTestId } from '../Text';

import helpStyles from '../HelpText.module.scss';

export const InventoryDetailsText = () => {
  return (
    <div data-testid={helpComponentTestId}>
      <p>
        Inventory details help us categorize your inventory properly in SIMS for
        accounting purposes.
      </p>
      <ol className={helpStyles.list}>
        <li>
          Using the drop down menu, select the store where you want your item to
          be rented.Tips:
          <ul>
            <li>
              Start with either Department or Type. The next selections will be
              enabled with related choices.
            </li>
            <li>All selections are required fields.</li>
          </ul>
        </li>
        <li>
          To save your Manual Purchase Order and finish it later, click Save as
          Draft.Your Manual Purchase Order will appear in Draft POs where it can
          be further edited.A Purchase Order Number will be assigned.You can
          change Inventory Details as often as you like in Draft state.
        </li>
        <li>
          When all required information for your Manual Purchase Order has been
          input, Submit PO will be enabled.4. Once complete, click Submit PO to
          submit your PO to SIMS.Your Manual Purchase Order details will appear
          in Submitted POs.Once your PO is in SIMS an agreement can be made.Once
          you Submit your PO, it cannot be changed.
        </li>
      </ol>
    </div>
  );
};
