import { CancelToken } from 'axios';

import { client, mapError } from './client';
import { PoStatus } from '../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../domain/PurchaseOrder/PurchaseOrder';
import { SubmittedPurchaseOrderResponse } from '../domain/PurchaseOrder/SubmittedPurchaseOrderResponse';
import { SelectFieldOption } from '../components/common/select-field/SelectField';

const basePath = 'purchase-orders';

function getPurchaseOrdersByStatus(
  poStatus: PoStatus,
  poStoreNumbers?: string[],
  cancelToken?: CancelToken
): Promise<PurchaseOrder[]> {
  return client(`${basePath}`, {
    method: 'GET',
    params: {
      status: poStatus,
      storeNum: poStoreNumbers && poStoreNumbers.join(',')
    },
    cancelToken
  });
}

function getPurchaseOrderById(
  draftId: string
): Promise<PurchaseOrder | undefined> {
  return client(`${basePath}/${draftId}`, {
    method: 'GET'
  });
}

function createDraftPurchaseOrder(
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder> {
  return client(`${basePath}/draft`, {
    method: 'POST',
    body: purchaseOrder
  });
}

function createSubmittedPurchaseOrder(
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder[]> {
  return client(`${basePath}/submitted`, {
    method: 'POST',
    body: purchaseOrder
  }).then((res: SubmittedPurchaseOrderResponse): PurchaseOrder[] => res.items);
}

function updateDraftPurchaseOrder(
  purchaseOrderId: string,
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder> {
  return client(`${basePath}/draft/${purchaseOrderId}`, {
    method: 'PUT',
    body: purchaseOrder
  });
}

function updateSubmittedPurchaseOrder(
  purchaseOrderId: string,
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder[]> {
  return client(`${basePath}/submitted/${purchaseOrderId}`, {
    method: 'PUT',
    body: purchaseOrder
  }).then((res: SubmittedPurchaseOrderResponse): PurchaseOrder[] => res.items);
}

function deleteDraftPurchaseOrder(draftId: string): Promise<void> {
  return client(`${basePath}/${draftId}`, { method: 'DELETE' });
}

const cancelPurchaseOrder = (purchaseOrderId: string): Promise<void> =>
  client(`${basePath}/${purchaseOrderId}/cancel`, {
    method: 'PUT'
  }).catch(mapError);

const getPoSubTypes = (): Promise<SelectFieldOption[]> =>
  client(`misc/po-sub-types`, { method: 'GET' });

export interface FixedParams {
  readonly department: string;
  readonly subDepartment: string;
  readonly bracket: string;
  readonly itemType: string;
  readonly itemSubType: string;
  readonly costPerItem: string;
}

export interface RateAndTermParams extends FixedParams {
  readonly storeNum: string;
}

export interface RateAndTermResponse {
  readonly weeklyRate?: number | null;
  readonly weeklyTerm?: number | null;
  readonly totalCost?: number | null;
  readonly biWeeklyRate?: number | null;
  readonly biWeeklyTerm?: number | null;
  readonly semiMonthlyRate?: number | null;
  readonly semiMonthlyTerm?: number | null;
  readonly monthlyRate?: number | null;
  readonly monthlyTerm?: number | null;
  // does not come on the response, it is set in AoutPricing, in order to create an action payload
  readonly state?: string;
}

const getRateAndTerm = async (
  params: RateAndTermParams
): Promise<RateAndTermResponse> => {
  return client(`${basePath}/suggest-pricing`, {
    method: 'POST',
    body: params
  })
    .then(response => {
      let fieldName = '';
      let fieldValue = undefined;
      let limit = undefined;

      if (response.weeklyTerm > 200) {
        fieldName = 'term';
        fieldValue = response.weeklyTerm;
        limit = 200;
      } else if (response.weeklyRate > 900) {
        fieldName = 'rate';
        fieldValue = response.weeklyRate;
        limit = 900;
      }

      if (fieldName) {
        return Promise.reject({
          response: {
            data: {
              errors: [
                {
                  code: 500,
                  message: `Calculated ${fieldName} (${fieldValue}) is above limit. ${limit}`
                }
              ]
            }
          }
        });
      }
      return response;
    })
    .catch(mapError);
};

export {
  getPurchaseOrdersByStatus,
  getPurchaseOrderById,
  createDraftPurchaseOrder,
  createSubmittedPurchaseOrder,
  updateSubmittedPurchaseOrder,
  updateDraftPurchaseOrder,
  deleteDraftPurchaseOrder,
  cancelPurchaseOrder,
  getPoSubTypes,
  getRateAndTerm
};
