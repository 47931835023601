import React, { useEffect, useReducer, useRef } from 'react';
import AutoNumeric from 'autonumeric';
import isEqual from 'lodash/isEqual';

import { GenericObject } from '../../../../utils/GenericObject';

export interface AutonumericInitProps {
  ref?: any;
  register?: any;
  value?: string;
  name?: string;
  minimumValue?: number;
  maximumValue?: number;
  decimalPlaces?: number;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number
  ) => void;
  onKeyUp?: (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: number
  ) => void;
  outputFormat?: any;
  preDefined?: object;
  [eventName: string]: any;
  restrictZeroFirstChar?: boolean;
  modifyValueOnWheel?: boolean;
}

export interface AutonumericInitState {
  autonumericInstance: any;
}

export interface AutonumericInitAction {
  type: string;
  payload: any;
}

export const autonumericInitTestId = 'autonumericComponent';

const initialState: AutonumericInitState = {
  autonumericInstance: null
};

const reducer = (state = initialState, action: AutonumericInitAction) => {
  // eslint-disable-next-line
  switch (action.type) {
    case 'setAutonumericinstance':
      return {
        ...state,
        autonumericInstance: action.payload
      };
    default:
      return state;
  }
};

export const AutonumericInit = (props: AutonumericInitProps) => {
  const { forRef } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { autonumericInstance } = state;
  // eslint-disable-next-line
  let prevProps: AutonumericInitProps = useRef(props);

  useEffect(() => {
    dispatch({
      type: 'setAutonumericinstance',
      payload: new AutoNumeric(forRef.current, props.value, {
        ...props.preDefined,
        ...props,
        onChange: undefined,
        onFocus: undefined,
        onBlur: undefined,
        onKeyPress: undefined,
        onKeyUp: undefined,
        onKeyDown: undefined,
        watchExternalChanges: false
      })
    });

    return () => {
      if (!autonumericInstance) return;
      autonumericInstance.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValue = () => {
    if (!autonumericInstance) return;
    const valueMapper: GenericObject = {
      string: (numeric: GenericObject) => numeric.getNumericString(),
      number: (numeric: GenericObject) => numeric.getNumber()
    };

    return valueMapper[props.outputFormat](autonumericInstance);
  };

  useEffect(() => {
    const isOptionsChanged = isEqual(
      { ...props, value: undefined },
      { ...prevProps.current, value: undefined }
    );
    // eslint-disable-next-line
    const isValueChanged =
      prevProps.current.value !== props.value && getValue() !== props.value;
    if (isValueChanged) {
      autonumericInstance.set(props.value);
    }

    if (!autonumericInstance) return;
    if (isOptionsChanged) {
      autonumericInstance.update({
        ...props.preDefined,
        ...props,
        onChange: undefined,
        onFocus: undefined,
        onBlur: undefined,
        onKeyPress: undefined,
        onKeyUp: undefined,
        onKeyDown: undefined,
        watchExternalChanges: false
      });
    }

    if (props.restrictZeroFirstChar) {
      autonumericInstance.settings.minimumValue = '0';

      // eslint-disable-next-line
      if (
        autonumericInstance &&
        autonumericInstance.settings.minimumValue === '0'
      ) {
        if (
          autonumericInstance.rawValue === '0' ||
          autonumericInstance.rawValue === ''
        ) {
          autonumericInstance.global.set('');
          autonumericInstance.settings.minimumValue = '1';
        }
      }
    }

    prevProps.current = props;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, prevProps, autonumericInstance]);

  const callEventHandler = (event: any, eventName: string) => {
    if (!props[eventName]) return;
    // workaround to fix autonumeric cmd+x issue
    // https://github.com/autoNumeric/autoNumeric/issues/576
    if (event.key === 'x' || event.ctrlKey) {
      autonumericInstance.set(null);
    }
    props[eventName](event, getValue());
  };

  const inputProps:
    | {
        id: string;
        className: string;
        style: string;
        disabled: boolean;
        type: string;
        name: string;
        tabIndex: string;
        unselectable: boolean;
        placeholder: string;
        defaultValue: string;
      }
    | any = {};
  [
    'id',
    'className',
    'style',
    'disabled',
    'type',
    'name',
    'tabIndex',
    'unselectable',
    'size',
    'autoFocus',
    'placeholder',
    'defaultValue'
  ].forEach(prop => (inputProps[prop] = props[prop]));

  return (
    <input
      data-testid={autonumericInitTestId}
      ref={forRef}
      onChange={e => callEventHandler(e, 'onChange')}
      onFocus={e => callEventHandler(e, 'onFocus')}
      onBlur={e => callEventHandler(e, 'onBlur')}
      onKeyPress={e => callEventHandler(e, 'onKeyPress')}
      onKeyUp={e => callEventHandler(e, 'onKeyUp')}
      onKeyDown={e => callEventHandler(e, 'onKeyDown')}
      pattern="[0-9]*"
      inputMode="decimal"
      autoComplete="off"
      {...inputProps}
    />
  );
};

export const predefinedOptions = AutoNumeric.getPredefinedOptions();
