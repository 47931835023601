import React, { FC } from 'react';
import {
  Snackbar as MaterialSnackbar,
  SnackbarOrigin
} from '@material-ui/core';

import {
  GlobalContextValue,
  useGlobalContext
} from '../../../context/global/GlobalContext';
import { closeSnackbarAction } from '../../../context/global/snackbar/snackbar-actions';
import { MessageBox } from './message-box/MessageBox';

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
};

export const snackbarTestId = 'SnackbarComponent';

export const Snackbar: FC = () => {
  const {
    snackbar: { isOpen, message },
    dispatch
  }: GlobalContextValue = useGlobalContext();

  const closeSnackbar = () => {
    dispatch(closeSnackbarAction());
  };

  return (
    <MaterialSnackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={5000}
      open={isOpen}
      onClose={closeSnackbar}
      onClick={closeSnackbar}
      data-testid={snackbarTestId}
    >
      <MessageBox message={message} />
    </MaterialSnackbar>
  );
};
