import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import clsx from 'clsx';

import { AutoNumeric } from '../../../../common/auto-numeric/AutoNumeric';
import { CommonProps } from './pricing-list/PricingItem';
import { calculateValue } from '../../../../../utils/helper-functions';
import { PricingCalculator } from '../../../../../utils/PricingCalculator';
import { PoFormLabel, pricingConfig } from '../po-form-config';
import { useUserPermissions } from '../../../../../context/user/PermissionsProvider';
import { FormPricingKeys } from './pricing-list/FormPricing';

import formStyles from '../PurchaseOrderForm.module.scss';
import { useAutoPricingState } from './context/AutoPricingProvider';
import { initialAutoPricingState } from '../../../../../constants/constants';

const { min } = pricingConfig[FormPricingKeys.MonthlyRent].validations;

export const monthlyRentTestId = 'MonthlyRentComponent';

export const MonthlyRent = (props: CommonProps) => {
  const { index, field, state } = props;
  const { hasAutoPricing } = useUserPermissions();
  const { autoPricings } = useAutoPricingState();
  const [value, setValue] = useState('');
  const { monthlyRate: apiValue, weeklyRate, weeklyTerm } =
    autoPricings[state] || initialAutoPricingState;
  const [isDefaultFromPO, setIsDefaultFromPO] = useState(
    field.monthlyRent !== ''
  );

  const weeklyTermWatched = useWatch({ name: `pricing[${index}].weeklyTerm` });
  const weeklyRentWatched = useWatch({ name: `pricing[${index}].weeklyRent` });

  const monthlyRent = () =>
    weeklyRentWatched &&
    calculateValue(
      PricingCalculator.monthlyRent,
      weeklyRentWatched,
      `${PricingCalculator.monthlyMultiplier()}`
    );

  const fieldName = `pricing[${index}].monthlyRent`;

  useEffect(() => {
    if (
      weeklyTerm === null &&
      weeklyRate === null &&
      apiValue === null &&
      !!weeklyTermWatched &&
      !!weeklyRentWatched &&
      !isDefaultFromPO
    ) {
      //* Value to be calculated from input change
      const rent =
        !!weeklyTermWatched && !!weeklyRentWatched && monthlyRent() >= '1'
          ? monthlyRent()
          : '';
      setValue(rent);
    } else {
      setValue('');
      setIsDefaultFromPO(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyTermWatched, weeklyRentWatched]);

  const imperativeValueCheck =
    !!value ||
    !!apiValue ||
    weeklyRentWatched === '' ||
    weeklyTermWatched === '';
  const imperativeValue =
    value !== '' ? value : apiValue ? apiValue.toString() : '';

  return useMemo(
    () => (
      <div data-testid={monthlyRentTestId}>
        <label htmlFor={fieldName}>{PoFormLabel.MonthlyRate}:</label>
        <div className={clsx(formStyles.iconField, formStyles.noFillField)}>
          <span>$</span>
          <Controller
            as={AutoNumeric}
            name={fieldName}
            defaultValue={field.monthlyRent}
            {...(imperativeValueCheck && {
              imperativeValue: imperativeValue?.toString(),
              imperativeMode: true
            })}
            options={{
              minimumValue: 0,
              decimalPlaces: 2,
              ...(!hasAutoPricing && {
                maximumValue: Number.MAX_SAFE_INTEGER
              })
            }}
            placeholder="--"
            disabled
            rules={{ required: true, min }}
          />
        </div>
      </div>
    ),
    [
      fieldName,
      field.monthlyRent,
      imperativeValueCheck,
      imperativeValue,
      hasAutoPricing
    ]
  );
};
