import * as purchaseOrderClient from '../../../api/purchase-orders';
import * as legalEngineClient from '../../../api/legal-engine';
import { openSnackbarAction } from '../../global/snackbar/snackbar-actions';
import { createGenericMessage } from '../../../components/layout/snackbar/messsages/generic-message/CreateGenericMessage';
import { LegalEngineRequest } from '../../../domain/Legal/LegalEngineRequest';
import { PoStatus } from '../../../domain/PurchaseOrder/PoStatus';
import { toPurchaseOrder } from '../../../components/screens/purchase-order/purchase-order-form/purchase-order-form-mappers';
import { PurchaseOrder } from '../../../domain/PurchaseOrder/PurchaseOrder';
import { GlobalContextValue } from '../../global/GlobalContext';
import { PurchaseOrderFormValues } from '../../../components/screens/purchase-order/purchase-order-form/PurchaseOrderFormValues';
import { LegalEngineResponseItem } from '../../../domain/Legal/LegalEngineResponseItem';
import { Either, left, right } from '../../../utils/Either';
import { APIError } from '../../../domain/APIError';
import { mapError } from '../../../api/client';

export const SUBMIT_ERROR_MESSAGE =
  'Due to unforeseen errors PO submission failed and was saved as a Draft PO. Head over to Draft PO’s tab and try again later.';

/**
 * @function saveAsDraft Function that saves a po as draft and displays an error message.
 * Used when submit po fails and we want to back it up as a draft.
 * @param {PurchaseOrderFormValues} purchaseOrderFormValues Purchase order object to be saved as draft
 * @param { Context } context Global context, used to display snackbar
 * @param { String } id Id of the purchase order , if available.
 * @return {Promise} Returns a Promise that resolves to the saved PO
 */
const saveAsDraft = (
  purchaseOrderFormValues: PurchaseOrderFormValues,
  context: GlobalContextValue,
  id?: string
): Promise<PurchaseOrder> => {
  purchaseOrderFormValues = {
    ...purchaseOrderFormValues,
    poStatus: PoStatus.DRAFT
  };

  const draftActionPromise = id
    ? purchaseOrderClient.updateDraftPurchaseOrder(
        id,
        toPurchaseOrder(purchaseOrderFormValues)
      )
    : purchaseOrderClient.createDraftPurchaseOrder(
        toPurchaseOrder(purchaseOrderFormValues)
      );

  return draftActionPromise.then((purchaseOrder: PurchaseOrder) => {
    context.dispatch(
      openSnackbarAction(createGenericMessage(SUBMIT_ERROR_MESSAGE))
    );

    return purchaseOrder;
  });
};

const createDraftPurchaseOrder = (
  purchaseOrderFormValues: PurchaseOrderFormValues
): Promise<PurchaseOrder> => {
  if (purchaseOrderFormValues && purchaseOrderFormValues.id) {
    const purchaseOrderId = purchaseOrderFormValues.id;

    delete purchaseOrderFormValues.id;

    return purchaseOrderClient.updateDraftPurchaseOrder(
      purchaseOrderId,
      toPurchaseOrder(purchaseOrderFormValues)
    );
  }

  return purchaseOrderClient.createDraftPurchaseOrder(
    toPurchaseOrder(purchaseOrderFormValues)
  );
};

const handledCreateDraftPurchaseOrder = async (
  formValues: PurchaseOrderFormValues
): Promise<Either<PurchaseOrder, APIError>> => {
  try {
    return left(await createDraftPurchaseOrder(formValues).catch(mapError));
  } catch (e) {
    return right(e);
  }
};

const deleteDraftPurchaseOrder = (draftId: string): Promise<void> =>
  purchaseOrderClient.deleteDraftPurchaseOrder(draftId);

const createSubmitPurchaseOrder = (
  purchaseOrderFormValues: PurchaseOrderFormValues,
  globalContext: GlobalContextValue
): Promise<PurchaseOrder | PurchaseOrder[]> => {
  if (purchaseOrderFormValues && purchaseOrderFormValues.id) {
    const purchaseOrderId = purchaseOrderFormValues.id;

    delete purchaseOrderFormValues.id;

    return purchaseOrderClient
      .updateSubmittedPurchaseOrder(
        purchaseOrderId,
        toPurchaseOrder(purchaseOrderFormValues)
      )
      .catch(() =>
        saveAsDraft(purchaseOrderFormValues, globalContext, purchaseOrderId)
      );
  }

  return purchaseOrderClient
    .createSubmittedPurchaseOrder(toPurchaseOrder(purchaseOrderFormValues))
    .catch(() => saveAsDraft(purchaseOrderFormValues, globalContext));
};

const checkLegalLimits = async (
  request: LegalEngineRequest
): Promise<Either<LegalEngineResponseItem[], APIError>> => {
  try {
    return left(await legalEngineClient.checkLegalLimits(request));
  } catch (e) {
    return right(e);
  }
};

export {
  createDraftPurchaseOrder,
  handledCreateDraftPurchaseOrder,
  deleteDraftPurchaseOrder,
  createSubmitPurchaseOrder,
  checkLegalLimits
};
