/**
 * Helper function to roundup a floating number
 * @param {number} number
 * @param {number} [decimals=2]
 * @returns {number}
 */
export const roundUp = (number: number, decimals = 2) => {
  return Number(Math.ceil(Number(number + 'e' + decimals)) + 'e-' + decimals);
};

/**
 * Helper function to calculate inputs value
 * @param {*} calculate
 * @param {...string[]} moreArgs
 * @returns {string}
 */
export const calculateValue = (calculate: any, ...moreArgs: string[]) => {
  const rest = moreArgs.map((value: string) => {
    return parseFloat(value) || 1;
  });

  const output: number = calculate(...rest);

  return roundUp(output).toString();
};

/**
 * Used as default value for a prop that is optional, instead of
 * checking everytime if it is defined before calling it
 */
export const noOp = () => {};
