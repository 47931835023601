import React, { useContext, useMemo } from 'react';

import styles from './DraftPurchaseOrders.module.scss';

import { getStoresLimit } from '../../../api/users';
import { useGlobalContext } from '../../../context/global/GlobalContext';
import { StoresStateContext } from '../../../context/stores/StoresProvider';
import { PurchaseOrdersNav } from '../submitted-purchase-orders/PurchaseOrdersNav';
import { CreatePoButton } from '../submitted-purchase-orders/create-po-button/CreatePoButton';
import { DraftPOsList } from './draft-pos-list/DraftPOsList';
import { Footer } from '../../layout/footer/Footer';
import { SearchPO } from '../../common/search-po/SearchPO';

export const draftPurchaseOrdersTestId = 'draftPurchaseOrdersTestId';

export const DraftPurchaseOrders = () => {
  const { stores: initialStores, loading } = useContext(StoresStateContext);
  const { poStores } = useGlobalContext();

  // TODO: show something when loading
  const canFilterPOsByStore =
    !loading && initialStores.length === getStoresLimit;

  const hasPOs = poStores && poStores.length > 0;

  return useMemo(
    () => (
      <div
        className={styles.sectionWrapper}
        data-testid={draftPurchaseOrdersTestId}
      >
        <PurchaseOrdersNav />
        {loading ? null : canFilterPOsByStore ? (
          <>
            <SearchPO />
            {hasPOs && <DraftPOsList poStores={poStores} />}
          </>
        ) : (
          <DraftPOsList />
        )}
        <Footer>
          <CreatePoButton />
        </Footer>
      </div>
    ),
    [loading, canFilterPOsByStore, hasPOs, poStores]
  );
};
