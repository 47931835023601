import React, { useReducer, ReactNode } from 'react';

import { submittedPosInitialState, draftPosReducer } from './draftPosReducer';
import { DraftPosContext } from './DraftPosContext';

export const DraftPosProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    draftPosReducer,
    submittedPosInitialState
  );

  return (
    <DraftPosContext.Provider value={{ ...state, dispatch }}>
      {children}
    </DraftPosContext.Provider>
  );
};
